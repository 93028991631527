/// <reference path="../appDashboard.ts" />
module controllers {

  import IUsersService = services.IUsersService;
  import IUser = models.IUser;
  import ICommonService = services.ICommonService;

  export class DashboardAdminController implements angular.IController {
    $onInit = () => { };

    private $scope: ng.IScope;
    private $state: ng.ui.IStateService;
    private $q: any;
    private $window: any;
    private usersService: IUsersService;
    private commonService: ICommonService;
    public importObjectList: any[];
    public selected: any;
    public displayGoToButton: boolean;
    public waitingPageObject: any;

    public static $inject = [
      '$scope',
      '$state',
      'UsersService',
      'CommonService',
      '$window',
      '$q',
    ];

    constructor($scope: ng.IScope, $state: ng.ui.IStateService,
                usersService: IUsersService,
                commonService: ICommonService, $window: any, $q: any) {
      this.$scope = $scope;
      this.$state = $state;
      this.$window = $window;
      this.$q = $q;
      this.usersService = usersService;

      this.commonService = commonService;
      this.reset();
    }

    private reset() {
      this.displayGoToButton = false;
      this.waitingPageObject = {
        display: false,
      };
      this.commonService.httpGet('/api/imports/', {}).then(
          function(importObjectList: any) {
            this.importObjectList = importObjectList;
          }.bind(this)
          , function(err) {
            console.error(err);
          },
      );
    }

    public forceImport() {
      this.commonService.httpPost('/initImport', {});
    }

    public setImportInProcessingAsTrue() {
      this.commonService.httpPost('/setImportInProcessingAsTrue', {}).then(
          function(data) {
            this.waitingPageObject.display = true;
            this.waitingPageObject.label = 'The waiting page is enable';
          }.bind(this),
      );

    }

    public setImportInProcessingAsFalse() {
      this.commonService.httpPost('/setImportInProcessingAsFalse', {}).then(
          function(data) {
            this.waitingPageObject.display = true;
            this.waitingPageObject.label = 'The waiting page is disable';
          }.bind(this),
      );
    }

    public logAs(matricule: string) {
      this.$window.location.href = '/#!/seeDashboard/' + matricule;
    }

    public startLoadTest(matricule: string, number: number) {
      var startDate = new Date();
      var endDate;
      var countSuccess = 0;
      var countError = 0;
      var promises = [];
      for (var i = 0; i < number; i++) {
        promises.push(
            this.usersService.getUserPopulatedByMatricule(matricule).then(
                function() {
                  countSuccess++;
                }, function(err) {
                  // catch err
                  console.error(err);
                  countError++;
                },
            ),
        );
      }
      this.$q.all(promises).then(
          function() {
            endDate = new Date();
            console.log('SUCCESS');
            console.log(countSuccess, countError);
            console.log(startDate, endDate);
          },
          function(err) {
            throw err;
          },
      );

    }

  }
}

appDashboard.controller('DashboardAdminController',
    controllers.DashboardAdminController);
