/// <reference path="../appDashboard.ts" />

var managerTeamLeaders = function () {
    return {
        restrict : 'E',
        replace: false,
        scope: {
            promiseUserPopulated: '=',
            promiseCommercialCalendar: '=',
            displayNewTab: '='
        },
        controller : 'DashboardManagerTeamLeadersController as dashboardManagerTeamLeadersController',
        templateUrl : '/templates/manager-team-leaders.html',
    };
};

appDashboard.directive("managerTeamLeaders", [managerTeamLeaders]);
