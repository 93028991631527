/// <reference path="../appDashboard.ts" />7
module models {
    'use strict';
    export interface IPrime{
        periodePrime : ICommercialCalendar;
        idPrime : number;
        labelPrime : string;
        amountWinPrime : number;
        statutPrime : boolean;
        criterePrime : number;
        numberRecruePrime : number;
        numberRecrueValidPrime : number;
        amountRecrueValidPrime : number;
    }
}
