/// <reference path="../appDashboard.ts" />
var commissionBlock = function () {
    return {
        restrict: 'E',
        replace: false,
        scope: {
            promiseUserPopulated: '=',
            promiseCommercialCalendar: '=',
            limitNumber: '='
        },
        templateUrl: '/templates/commission-block.html',
        controller: 'DashboardCommissionBlockController as dashboardCommissionBlockController',

    };
};

appDashboard.directive("commissionBlock", [commissionBlock]);
