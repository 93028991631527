/// <reference path="../appDashboard.ts" />
var managerSection = function () {
    return {
        restrict: 'E',
        replace: false,
        scope: {
            promiseUserPopulated: "=",
            promiseCommercialCalendar: "="
        },
        templateUrl: '/templates/manager-section.html',
        controller: 'DashboardManagerSectionController as DashboardManagerSectionController',
    };
};

appDashboard.directive("managerSection", managerSection);
