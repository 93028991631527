/// <reference path="../appDashboard.ts" />
module controllers {

  import IUsersService              = services.IUsersService;
  import IUserPopulated             = models.IUserPopulated;
  import ICommercialCalendarService = services.ICommercialCalendarsService;
  import ICommercialCalendar        = models.ICommercialCalendar;
  import ITypologyActivity          = models.ITypologyActivity;
  import ITypologyActivityService   = services.ITypologyActivityService;
  import IChartsService             = services.IChartsService;
  import IEventsService             = services.EventsService;
  import IDisplaysArrayService      = services.IDisplaysArrayService;

  export class DashboardManagerActivityStructureController implements angular.IController {
    $onInit = () => { };

    private $scope: ng.IScope;
    private $state: ng.ui.IStateService;
    private usersService: IUsersService;
    private commercialCalendarService: ICommercialCalendarService;
    private $q: ng.IQService;
    private $filter: ng.IFilterService;
    private typologyActivityService: ITypologyActivityService;
    private currentYearName: string;
    private lastYearName: string;
    private chartsService: IChartsService;

    public userPopulated: IUserPopulated;
    public typologyList: string[];
    public profileList: string[];
    public levelList: string[];
    public currentCycle: ICommercialCalendar;
    public typologySelected: string;
    public profileSelected: string;
    public levelSelected: string;
    public activityStructureList: any;
    public activeArrayCurrentYear: any [];
    public recrueArrayCurrentYear: any [];
    public activeArrayLastYear: any [];
    public recrueArrayLastYear: any [];
    public chartIsDisplayed: any;
    public activeChartOptions: any;
    public recrueChartOptions: any;
    public tooltipOption: any;
    public currentCycleNumber: number;
    public globalActivity: any;
    public activeUsers: any;
    public recrutUsers: any;
    public demUsers: any;

    public numberOfItemByPage: number;
    public currentPage: number;
    public numberOfPages: number;
    public display: boolean;
    public tableHeaders: any;
    private eventsService: IEventsService;
    private displaysArrayService: IDisplaysArrayService;

    public static $inject = [
      '$scope',
      '$state',
      'UsersService',
      'CommercialCalendarsService',
      '$q',
      '$filter',
      'TypologyActivityService',
      'ChartsService',
      'EventsService',
      'DisplaysArrayService',
    ];

    constructor(
        $scope: ng.IScope,
        $state: ng.ui.IStateService,
        usersService: IUsersService,
        commercialCalendarService: ICommercialCalendarService,
        $q: ng.IQService,
        $filter: ng.IFilterService,
        typologyActivityService: ITypologyActivityService,
        chartsService: IChartsService,
        eventsService: IEventsService,
        displaysArrayService: IDisplaysArrayService) {
      this.$scope = $scope;
      this.$state = $state;
      this.usersService = usersService;
      this.commercialCalendarService = commercialCalendarService;
      this.eventsService = eventsService;
      this.displaysArrayService = displaysArrayService;
      this.$q = $q;
      this.$filter = $filter;
      this.typologyActivityService = typologyActivityService;
      this.chartsService = chartsService;

      this.chartIsDisplayed = {
        allChart: false,
        recrueChart: true,
        activeChart: false,
      };

      this.typologySelected = null;
      this.profileSelected = null;
      this.levelSelected = null;

      this.numberOfItemByPage = 10;
      this.currentPage = 0;
      this.display = false;

      this.activeUsers = [];
      this.recrutUsers = [];
      this.demUsers = [];

      this.$scope.$on(this.eventsService.getEventBy('goto'), function(event, data) {
        this.setPage(data)
      }.bind(this));
      this.$scope.$on(this.eventsService.getEventBy('filter'), function(event, data) {
        this.setOrderBy(data.sort, data.sens)
      }.bind(this));

      this.$q.all(
          [$scope.promiseUserPopulated, $scope.promiseCommercialCalendar]).
          then(function(data: any) {
            this.init(data[0], data[1]);
          }.bind(this));
    }

    private init(
        userPopulated: IUserPopulated,
        commercialCalendarList: ICommercialCalendar[]) {
      this.userPopulated = userPopulated;

      this.tableHeaders = [
        {label: "SUIVI", key: "follow", sort: false, class: this.displaysArrayService.getClassBy('both')},
        {label: "NOM PRÉNOM", key: "lastName", sort: true, class: this.displaysArrayService.getClassBy('desktop')},
        {label: "NOM PRÉNOM", key: "lastNameMob", sort: false, class: this.displaysArrayService.getClassBy('mobile')},
        {label: "TYP.", key: "type", sort: true, class: this.displaysArrayService.getClassBy('desktop')},
        {label: "NIV", key: "level", sort: true, class: this.displaysArrayService.getClassBy('desktop')},
        {label: "NB CMD DU Période", key: "orders", sort: true, class: this.displaysArrayService.getClassBy('desktop')},
        {label: "DERN. CMD", key: "ordersLast", sort: true, class: this.displaysArrayService.getClassBy('desktop')},
        {label: "DERN. CMD", key: "ordersLastMob", sort: false, class: this.displaysArrayService.getClassBy('mobile')},
        {label: "CA Période", key: "ordersLast", sort: true, class: this.displaysArrayService.getClassBy('desktop')},
        {label: "CA Période", key: "ordersLastMob", sort: false, class: this.displaysArrayService.getClassBy('mobile')},
      ];

      this.typologyList = this.usersService.getTypologyListOfTypologyActivityList(
          userPopulated.typologyActivityList);
      this.profileList = this.usersService.getProfileList(
          userPopulated.profile);
      this.levelList = this.usersService.getLevelList(
          userPopulated.p2RLevel);

      this.fillArray(userPopulated);

      this.currentCycle = this.commercialCalendarService.getCurrentCycleForced(
          commercialCalendarList);

      this.currentCycleNumber = this.commercialCalendarService.getCycleNumber(
          this.currentCycle);
      this.currentYearName = this.commercialCalendarService.getCurrentYearName(
          this.currentCycle);
      this.lastYearName = this.commercialCalendarService.getLastYearName(
          this.currentCycle);

      this.tooltipOption = {
        shared: true,
        borderWidth: 0,
        backgroundColor: 'transparent',
        valueSuffix: '',
        shadow: false,
        useHTML: true,
        style: {
          color: '#48494d',
          fontWeight: '700',
          fontSize: '15px',
          fontFamily: 'Lato',
          right: '-50px',
        },
        headerFormat: '<table>',
        footerFormat: '</table>',
        padding: '0',
        hideDelay: '5000',
        positioner: function(labelWidth: any, labelHeight: any, point: any) {
          return {
            x: this.chart.chartWidth - 200,
            y: this.chart.chartHeight - 200,
          };
        },
      };

      this.setActivityStructureListByTypologyFilters('ALL');
      this.setActivityStructureListByLevelFilters('ALL');
      this.setActivityStructureListByProfileFilters('ALL');

      this.typologyActivityService.getGetGlobalActivity(
          this.currentCycle.periodeName).then(function(data) {
        this.globalActivity = data;
      }.bind(this));

    }

    private fillArray(userPopulated) {
      this.activeUsers = [];
      this.recrutUsers = [];
      this.demUsers = [];
    }

    public setActivityStructureListByLevelFilters(level: any) {
      this.levelSelected = level;
      this.setActivityStructureListByFilters();
    }

    public setActivityStructureListByProfileFilters(profile: any) {
      this.profileSelected = profile;
      this.setActivityStructureListByFilters();
    }

    public setActivityStructureListByTypologyFilters(typology: any) {
      this.typologySelected = typology;
      this.setActivityStructureListByFilters();
    }

    public setActivityStructureListByFilters() {
      this.activityStructureList = this.typologyActivityService.getFilteredByOptions(
          this.userPopulated.typologyActivityList, this.typologySelected,
          this.currentCycle.periodeName);
      this.activityStructureList.turnOverAvg = this.activityStructureList.activeNumber !=
      0 ? this.activityStructureList.turnOver /
          this.activityStructureList.activeNumber : 0;
      this.activityStructureList.percentActive = this.activityStructureList.totalWatched !=
      0 ? (this.activityStructureList.activeNumber /
          this.activityStructureList.totalWatched) * 100 : 0;
      this.computeToDisplayActiveNumberGraph(this.activityStructureList.percentActive)
      this.activeArrayCurrentYear = this.typologyActivityService.getActiveArray(
          this.userPopulated.typologyActivityList, this.currentYearName,
          this.typologySelected);
      this.recrueArrayCurrentYear = this.typologyActivityService.getRecrueArray(
          this.userPopulated.typologyActivityList, this.currentYearName,
          this.typologySelected);

      this.activeArrayLastYear = this.typologyActivityService.getActiveArray(
          this.userPopulated.typologyActivityList, this.lastYearName,
          this.typologySelected);
      this.recrueArrayLastYear = this.typologyActivityService.getRecrueArray(
          this.userPopulated.typologyActivityList, this.lastYearName,
          this.typologySelected);

      this.activeChartOptions = this.loadOptionsCharts(
          this.activeArrayCurrentYear, this.activeArrayLastYear, ' actives',
          'Actives');
      this.recrueChartOptions = this.loadOptionsCharts(
          this.recrueArrayCurrentYear, this.recrueArrayLastYear, ' recrues',
          'Recrues');
    }

    public displayActivityBlock() {
      return this.activityStructureList.activeNumber != 0 ||
          this.activityStructureList.percentActive != 0 ||
          this.activityStructureList.totalDemission != 0 ||
          this.activityStructureList.totalEffectif != 0 ||
          this.activityStructureList.totalWatched != 0 ||
          this.activityStructureList.totalRecrue != 0 ||
          this.activityStructureList.turnOver != 0 ||
          this.activityStructureList.turnOverAvg != 0;
    }

    public computeToDisplayActiveNumberGraph(percentActive: number) {
      var cursor = document.getElementById('cursor-activity');
      var container = document.getElementById('cursor-container');

      if (percentActive < 0 || percentActive > 100) {
        console.error('percentActive must be between 0 and 100');
      }
      //compute
      var width = container.clientWidth;
      var cursorWidth = cursor.clientWidth;
      var height = container.clientHeight;
      var cursorHeight = cursor.clientHeight;
      var alpha = (Math.PI / 2) * ((100 - percentActive) / 50);
      var angle = 90 - 2 * radToDeg(alpha);
      var l = ((width / 2) * (Math.cos(alpha) + 1)) - (cursorWidth / 2);
      var h = ((height) * (1 - Math.sin(alpha))) - (cursorHeight / 2);

      //action
      rotateElement(cursor, angle);
      translateElement(cursor, l, h);

      //function
      function rotateElement(element, deg) {
        element.style.webkitTransform = 'rotate(' + deg + 'deg)';
        element.style.mozTransform = 'rotate(' + deg + 'deg)';
        element.style.msTransform = 'rotate(' + deg + 'deg)';
        element.style.oTransform = 'rotate(' + deg + 'deg)';
        element.style.transform = 'rotate(' + deg + 'deg)';
      }

      function translateElement(element, left, top) {
        element.style.left = left + 'px';
        element.style.top = top + 'px';
      }

      function radToDeg(rad) {
        return rad / Math.PI * 90;
      }
    }

    /**
     * edraw the chart
     * @param arrayCurrentYear
     * @param arrayLastYear
     * @param suffix
     * @param labelTooltip
     * @returns {{data: number[], seriesName: string, fillColor1: (number|string)[], fillColor2: (number|string)[], color: string, valueSuffix: string, colorLabel: string, tooltip: {pointFormat: string}}[]}
     */
    private loadOptionsCharts(
        arrayCurrentYear: number[], arrayLastYear: number[], suffix: string,
        labelTooltip: string) {
      return [
        {
          data: arrayLastYear,
          seriesName: 'L\'année dernière',
          fillColor1: [0, 'rgba(255, 183, 112, 0.1)'],
          fillColor2: [1, 'rgba(255, 183, 112, 0.9)'],
          color: '#ffb266',
          valueSuffix: suffix,
          colorLabel: 'orange',
          tooltip: {
            pointFormat: '<tr><td>Référentiel*: &nbsp&nbsp</td><td style="width: 10px"></td>' +
            '<td style="text-align: right; border: 2px solid {series.color}; padding :5px; background-color: white;"><b>{point.y} </b></td></tr><tr style="height: 10px"></tr>',
          },
        },
        {
          data: arrayCurrentYear,
          seriesName: 'Cette année',
          fillColor1: [0, 'rgba(142, 233, 251, 0.4)'],
          fillColor2: [1, 'rgba(142, 233, 251, 0.9)'],
          color: '#82ddff',
          valueSuffix: suffix,
          colorLabel: 'blue',
          tooltip: {
            pointFormat: '<tr><td>Cette année : &nbsp&nbsp</td><td style="width: 10px"></td>' +
            '<td style="text-align: right; border: 2px solid {series.color}; padding :5px; background-color: white;"><b>{point.y} </b></td></tr><tr style="height: 10px"></tr>',
          },
        },
      ];
    }

    /**
     * To display or hide chart of commission
     * @param label
     */
    public toggleChartDisplaying(label: string) {
      this.chartIsDisplayed = {
        allChart: false,
        recrueChart: false,
        activeChart: false,
      };
      this.chartIsDisplayed[label] = !this.chartIsDisplayed[label];
    }

    /**
     * get if the Chart is displayed
     * @returns {null|boolean}
     */
    public displayHighcharts(): boolean {
      return ((this.activeArrayCurrentYear &&
          this.activeArrayCurrentYear.length != 0) ||
          (this.recrueArrayCurrentYear && this.recrueArrayCurrentYear.length !=
              0));
    }

    /**
     * This function return the delta bewteen the cumputed percent active and the national percent active
     * @param selectedTypology
     * @param percentActive
     */
    public getNationalDelta(selectedTypology, percentActive) {
      if (this.globalActivity) {
        var globalPersent, _selectedTypology;

        if (selectedTypology == 'ALL') {
          _selectedTypology = 'all';
        } else {
          _selectedTypology = angular.copy(selectedTypology);
        }
        if (this.globalActivity[_selectedTypology].totalWatched) {
          globalPersent = this.globalActivity[_selectedTypology].activeNumber /
              this.globalActivity[_selectedTypology].totalWatched;
        } else {
          globalPersent = 0;
        }

        return percentActive - (globalPersent * 100);
      }
    }

    public setOrderBy(orderByLabel, sens) {

    }

    public setPage(index: number) {
      this.currentPage = index;
    }

    public goToDashboard(matricule: string): void {
      var url = this.$state.href('seeDashboard', {matricule: matricule});
      window.open(url,'_blank');
    }
  }
}

appDashboard.controller('DashboardManagerActivityStructureController',
    controllers.DashboardManagerActivityStructureController);
