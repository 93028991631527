/// <reference path="../appDashboard.ts" />
module controllers {

  import IUsersService = services.IUsersService;
  import ICommercialCalendarService = services.ICommercialCalendarsService;
  import ICommercialCalendar = models.ICommercialCalendar;
  import IUserPopulated = models.IUserPopulated;
  import IDisplayService = services.IDisplayService;
  import IPanService = services.IPanService;

  declare var Promise: any;

  export class DashboardMenuBlockController implements angular.IController {

    $onInit = () => { };
    private scope: any;
    private usersService: IUsersService;
    private pansService: IPanService;
    private commercialCalendarService: ICommercialCalendarService;
    private $q: ng.IQService;

    public promiseUserPopulated: any;
    public promiseCommercialCalendar: any;
    public userPopulated: IUserPopulated;
    public currentCycleForced: ICommercialCalendar;
    public nMinus1CycleForced: ICommercialCalendar;
    public consMail: string;
    public consTel: string;
    public typologyColor: string;
    public leaderLevel: string;
    //public messageList: IMessage[];
    //public numberNewMessage: number;
    public limitNumber: number;
    public limitGainNumber: number;
    public increaseEffectif: number;
    public displayService: IDisplayService;
    public initLeaderListLength: number;
    public initLeaderListLengthForLeaderAndAA: number;

    public static $inject = [
      '$scope',
      'UsersService',
      'CommercialCalendarsService',
      'limitNumber',
      'limitGainNumber',
      '$q',
      'DisplayService',
      'PanService',

    ];

    /**
     *
     * @param $scope
     * @param usersService
     * @param commercialCalendarService
     * @param limitNumber
     * @param limitGainNumber
     * @param $q
     * @param displayService
     * @param pansService
     */
    constructor(
        $scope: any,
        usersService: IUsersService,
        commercialCalendarService: ICommercialCalendarService,
        limitNumber: number,
        limitGainNumber: number,
        $q: ng.IQService,
        displayService: IDisplayService,
        pansService: IPanService) {
      this.scope = $scope;
      this.usersService = usersService;
      this.pansService = pansService;
      this.commercialCalendarService = commercialCalendarService;
      this.displayService = displayService;
      this.limitNumber = limitNumber;
      this.limitGainNumber = limitGainNumber;
      this.consTel = '0 811 65 06 91';
      this.consMail = 'allo-conseillere@stanhome.fr';
      this.$q = $q;

      this.$q.all(
          [$scope.promiseUserPopulated, $scope.promiseCommercialCalendar]).
          then(function(data: any) {
            this.init(data[0], data[1]);
          }.bind(this));

    }

    /**
     *
     * @param userPopulated
     * @param commercialCalendarList
     */
    private init(
        userPopulated: IUserPopulated,
        commercialCalendarList: ICommercialCalendar[]): void {
      this.userPopulated = userPopulated;
      this.currentCycleForced = this.commercialCalendarService.getCurrentCycleForced(
          commercialCalendarList);
      if (this.usersService.getProfilType(this.userPopulated) === 'LDS')
        this.leaderLevel = this.userPopulated.p2RLevel;
      else if (this.userPopulated.profile === 'ADS')
        this.leaderLevel = 'Animatrice de secteur';
      else if (this.userPopulated.profile === 'AS')
        this.leaderLevel = 'Animatrice stagiaire';
      else if (this.usersService.getProfilType(this.userPopulated) === 'Ani')
        this.leaderLevel = 'Animatrice';
      else if (this.usersService.getProfilType(this.userPopulated) === 'Coach')
        this.leaderLevel = this.userPopulated.p2RLevel || 'Coach';
      else {
        this.leaderLevel = this.userPopulated.p2RLevel;
      }
      this.typologyColor = this.usersService.getTypologyColor(
          this.userPopulated.typology);
      this.increaseEffectif = this.userPopulated.currentEffectif -
          this.userPopulated.fromStartYearEffectif;

      this.usersService.getLeaderListByGSDRValue(userPopulated.GSDRValue, null,
          1, 10,
          0, null, null, undefined, "").then(
          function(data) {
            this.initLeaderListLength = data.count;
          }.bind(this),
      );
      this.usersService.getSubUserByMatricule(userPopulated.matricule,
          userPopulated.GSDRValue).then(function(subUserListObject) {
        var leaderList = subUserListObject.list.filter(function(item) {
          return (item.p2RLevel != '' || item.lastLevelLeader != '') &&
              item.parentMatricule ==
              userPopulated.matricule;
        });
        this.initLeaderListLengthForLeaderAndAA = leaderList.length;
      }.bind(this));

      // Code message - Refactoriser plus tard
      /*this.messagesService.getMessagesPerMatricule(this.userPopulated.matricule).then(function (messageList : IMessage[]) {
       this.messageList = messageList;
       this.numberNewMessage = 0;
       angular.forEach(messageList, function (current_message) {
       if(current_message.isSeen == false){
       this.numberNewMessage ++;
       }
       }.bind(this));
       }.bind(this), function (err) {
       console.error(err)
       })*/
    }

    public displayArrayLeaderBlock() {
      if (this.userPopulated) {
        if (this.displayService.displayArrayLeaderBlock) {
          if (this.userPopulated.profile == 'DR' ||
              this.userPopulated.profile == 'DD' ||
              this.userPopulated.profile == 'ZM' ||
              this.userPopulated.profile == 'LDS' ||
              this.userPopulated.profile == 'ADS') {
            return this.initLeaderListLength != 0;
          } else if (this.userPopulated.profile == 'AA' ||
              this.userPopulated.profile == 'Leader') {
            return this.initLeaderListLengthForLeaderAndAA != 0;

          }
        }
      } else {
        return false;
      }
    }

    public displayFollowNewBlock() {
      if (this.userPopulated) {
        return this.pansService.havePanData
      } else {
        return false;
      }
    }

    /**
     * Display the profile of the connected user
     */
    public getDisplayedProfil() {
      if (this.userPopulated) {
        switch (this.userPopulated.profile) {
          case 'DR':
            return 'Directeur de Région';
          case 'ZM':
            return 'Zone Manager';
          case 'DD':
            return 'Directrice de Division';
        }
      }
    }

    //used in commented code
    // Code message - Refactoriser plus tard
    /*public clickOnMessage(){
        if(this.numberNewMessage !=0){
            this.messagesService.setMessageToSeen(this.userPopulated.matricule);
            this.numberNewMessage = 0
        }
    }*/

  }
}

appDashboard.controller('DashboardMenuBlockController',
    controllers.DashboardMenuBlockController);
