/// <reference path="../appDashboard.ts" />7
module services {
    'use strict';
    export interface ICommonService {
        httpPost(url:string, data:any) : ng.IPromise<any>;
        httpGet(url:string, data:any):ng.IPromise<any>;
        httpPut(url:string, data:any):ng.IPromise<any>;
        httpDelete(url:string, data:any):ng.IPromise<any>
        uniq(list : any[]) : any[]
    }

    export class CommonService implements ICommonService {

        private $http:ng.IHttpService;
        private $q:ng.IQService;

        public static $inject = [
            '$http',
            '$q'
        ];

        constructor($http:ng.IHttpService, $q:ng.IQService) {
            this.$http = $http;
            this.$q = $q;

        }

        public httpPost(url:string, data:any):ng.IPromise<any> {
            var deferred = this.$q.defer();
            var req = {
                method: 'POST',
                url: url,
                data: data
            };
            this.$http(req).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (err) {
                    console.error(err);
                    deferred.reject(err.data);
                }
            );
            return deferred.promise;
        }

        public httpGet(url:string, data:any):ng.IPromise<any> {
            var deferred = this.$q.defer();
            var req = {
                method: 'GET',
                url: url,
                params: data
            };
            this.$http(req).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (err) {
                    console.error(err);
                    deferred.reject(err.data);
                }
            );
            return deferred.promise;
        }

        public httpPut(url:string, data:any):ng.IPromise<any> {
            var deferred = this.$q.defer();
            var req = {
                method: 'PUT',
                url: url,
                data: data
            };
            this.$http(req).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (err) {
                    console.error(err);
                    deferred.reject(err.data);                }
            );
            return deferred.promise;
        }

        public httpDelete(url:string, data:any):ng.IPromise<any> {
            var deferred = this.$q.defer();
            var req = {
                method: 'DELETE',
                url: url,
                data: data
            };
            this.$http(req).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (err) {
                    console.error(err);
                    deferred.reject(err.data);
                }
            );
            return deferred.promise;
        }

        public uniq(list : any[]) : any[]{
            return list.filter(function(item, pos) {
                return list.indexOf(item) === pos;
            })
        }
    }
}
appDashboard.service("CommonService", services.CommonService);
