/// <reference path="../appDashboard.ts" />

var managerPrimeRecruitment = function () {
    return {
        restrict : 'E',
        replace: false,
        scope: {
            promiseUserPopulated: '=',
            promiseCommercialCalendar: '=',
        },
        controller : 'DashboardManagerPrimeRecruitmentController as primeRecruitment',
        templateUrl : '/templates/manager-prime-recruitment.html',
    };
};

appDashboard.directive("managerPrimeRecruitment", [managerPrimeRecruitment]);
