/// <reference path="../appDashboard.ts" />

var arrayTeamActivityTab = function () {
    return {
        restrict : 'E',
        replace: false,
        scope: {
            promiseUserPopulated: '=',
            promiseCommercialCalendar: '=',
            filters: '=',
        },
        controller : 'DashboardTeamActivityBlockController as dashboardTeamActivityBlockController',
        templateUrl : '/templates/array-team-activity-tab.html',
    };
};

appDashboard.directive("arrayTeamActivityTab", [arrayTeamActivityTab]);

