/// <reference path="../appDashboard.ts" />7
module services {
    'use strict';
    export interface IDisplaysArrayService {
        getDisplayBy(name: string): string
        getClassBy(name: string): string
    }
    export class DisplaysArrayService implements IDisplaysArrayService {
        displays = {};
        classDisplay = {};

        constructor() {
            this.displays = {
                default: "default",
                goto: "gotoDashboard",
                graduate: "graduate",
                average: "average",
                gototxtless: "gotoDashboardWithoutTxt",
                color: "color",
                showmoreparent: "showMoreParent",
                roundcolor: "roundcolor",
                date: "date",
                plain: "plain"
            };

            this.classDisplay = {
                desktop: "desktopOnly",
                mobile: "mobileOnly",
                both: "both"
            };
        }

        /**
         * get display
         * @param name
         * @returns {string}
         */
        public getDisplayBy(name: string): string {
            return this.displays[name];
        }

        /**
         * get class
         * @param name
         * @returns {string}
         */
        public getClassBy(name: string): string {
            return this.classDisplay[name];
        }
    }
}
appDashboard.service("DisplaysArrayService", services.DisplaysArrayService);


