/// <reference path="../appDashboard.ts" />7
module services {
  'use strict';
  import IPromise = angular.IPromise;
  import ICommission = models.ICommission;
  import IActivity = models.IActivity;
  import ICommercialCalendar = models.ICommercialCalendar;

  export interface IActivitiesService {
    getByMatricule(matricule: string): ng.IPromise<any>

    getTurnover(activity: IActivity, level: string, mark: string): number;

    getTurnoverArray(
        activityList: IActivity[], yearName: string, level: string,
        mark: string): any;

    getCurrentActivity(
        activityList: IActivity[],
        currentCycle: ICommercialCalendar): IActivity;

    getOrderNumber(activity: IActivity, level: string): number;

    getSumTurnoverCurrentQuarterly(
        activityList: IActivity[], quarterName: string): number;

    getSumActivityWeekNumberCurrentQuarterly(
        activityList: IActivity[], quarterName: string): number

    getTurnoverSum(turnoverArray: number[]): number;

    getNMinus1Activity(
        activityList: IActivity[],
        nMoins1Cycle: ICommercialCalendar): IActivity;

    getCommissionArray(
        activityList: IActivity[], type: TypeCommissionInActivity,
        yearName: string): any[];

    getSumMultipleCommissionArray(
        activityList: IActivity[], typeArray: TypeCommissionInActivity[],
        yearName: string): any[];

    getMAxTurnoverValue(
        activityList: IActivity[], currentYearName: string,
        lastYearName: string): number;

    getTotalTurnoverReference(activity: IActivity, level: string): number;

    getObjectifNumber(activity: IActivity, level: string): number;

    getLastCycle(currentCycle: ICommercialCalendar): number;

    getTurnoverLeadersByProfile(activity: IActivity, profile: string): number;

    getObjectifArray(
        activityList: IActivity[], yearName: string, level: string,
        mark: string): any[];
  }

  type TypeCommissionInActivity =
      'direct'
      | 'indirectN1P2R'
      | 'indirectN2P2R'
      | 'indirectForced';

  export class ActivitiesService implements IActivitiesService {

    private $http: ng.IHttpService;
    private $q: ng.IQService;
    private commonService: ICommonService;
    private commercialCalendarsService: ICommercialCalendarsService;
    private KIOTIS_NAME = 'kiotis';
    private FAMILY_EXPERT_NAME = 'familyExpert';
    private STANHOME_NAME = 'stanhome';
    private FLORMAR_NAME = 'flormar';
    private RICAUD_NAME = 'ricaud';
    private promise_getByMatricule: any[] = [];
    private base_url: string = '/api/activities';

    public static $inject = [
      '$http',
      '$q',
      'CommonService',
      'CommercialCalendarsService',
    ];

    /**
     *
     * @param $http
     * @param $q
     * @param commonService
     */
    constructor(
        $http: ng.IHttpService, $q: ng.IQService, commonService: ICommonService,
        commercialCalendarsService: ICommercialCalendarsService) {
      this.commonService = commonService;
      this.commercialCalendarsService = commercialCalendarsService;
    }

    /**
     * Get user activity
     * @param matricule
     * @returns {ng.IPromise<any>}
     */
    public getByMatricule(matricule: string): ng.IPromise<any> {
      var promiseString = matricule;
      if (!this.promise_getByMatricule[promiseString]) {
        this.promise_getByMatricule[promiseString] = this.commonService.httpGet(this.base_url +
            '/getByMatricule/' + matricule, {});
      }
      return this.promise_getByMatricule[promiseString];
    };

    /**
     * Get the correct turnover field from actvity according to level and mark
     *
     * @param activity
     * @param level
     * @param mark
     * @returns {number}
     */
    public getTurnover(
        activity: IActivity, level: string, mark: string): number {
      switch (true) {
          //coach
        case level == 'n0' && !mark:
          return activity.personalTotalTurnover;
        case level == 'n0' && mark == this.KIOTIS_NAME:
          return activity.personalKiotisTurnover;
        case level == 'n0' && mark == this.STANHOME_NAME:
          return activity.personalStanhomeTurnover;
        case level == 'n0' && mark == this.FAMILY_EXPERT_NAME:
          return activity.personalFamilyExpertTurnover;
        case level == 'n0' && mark == this.FLORMAR_NAME:
          return activity.personalFlormarTurnover;
        case level == 'n0' && mark == this.RICAUD_NAME:
          return activity.personalRicaudTurnover;
        case level == 'n1' && !mark:
          return activity.n1TotalTurnover;
        case level == 'n1' && mark == this.KIOTIS_NAME:
          return activity.n1KiotisTurnover;
        case level == 'n1' && mark == this.STANHOME_NAME:
          return activity.n1StanhomeTurnover;
        case level == 'n1' && mark == this.FAMILY_EXPERT_NAME:
          return activity.n1FamilyExpertTurnover;
        case level == 'n1' && mark == this.FLORMAR_NAME:
          return activity.n1FlormarTurnover;
        case level == 'n1' && mark == this.RICAUD_NAME:
          return activity.n1RicaudTurnover;
        case level == 'n2' && !mark:
          return activity.n2TotalTurnover;
        case level == 'n2' && mark == this.KIOTIS_NAME:
          return activity.n2KiotisTurnover;
        case level == 'n2' && mark == this.STANHOME_NAME:
          return activity.n2StanhomeTurnover;
        case level == 'n2' && mark == this.FAMILY_EXPERT_NAME:
          return activity.n2FamilyExpertTurnover;
        case level == 'n2' && mark == this.FLORMAR_NAME:
          return activity.n2FlormarTurnover;
        case level == 'n2' && mark == this.RICAUD_NAME:
          return activity.n2RicaudTurnover;
        case level == 'n1n2' && !mark:
          return activity.n1TotalTurnover + activity.n2TotalTurnover;
        case level == 'n1n2' && mark == this.KIOTIS_NAME:
          return activity.n1KiotisTurnover + activity.n2KiotisTurnover;
        case level == 'n1n2' && mark == this.STANHOME_NAME:
          return activity.n1StanhomeTurnover + activity.n2StanhomeTurnover;
        case level == 'n1n2' && mark == this.FAMILY_EXPERT_NAME:
          return activity.n1FamilyExpertTurnover + activity.n2FamilyExpertTurnover;
        case level == 'n1n2' && mark == this.FLORMAR_NAME:
          return activity.n1FlormarTurnover + activity.n2FlormarTurnover;
        case level == 'n1n2' && mark == this.RICAUD_NAME:
          return activity.n1RicaudTurnover + activity.n2RicaudTurnover;
        case level == 'n3' && !mark:
          return activity.n3TotalTurnover;
        case level == 'n3' && mark == this.KIOTIS_NAME:
          return activity.n3KiotisTurnover;
        case level == 'n3' && mark == this.STANHOME_NAME:
          return activity.n3StanhomeTurnover;
        case level == 'n3' && mark == this.FAMILY_EXPERT_NAME:
          return activity.n3FamilyExpertTurnover;
        case level == 'n3' && mark == this.FLORMAR_NAME:
          return activity.n3FlormarTurnover;
        case level == 'n3' && mark == this.RICAUD_NAME:
          return activity.n3RicaudTurnover;
          //chef de secteur
        case level == 'team' && !mark:
          return activity.teamTotalTurnover;
        case level == 'team' && mark == this.KIOTIS_NAME:
          return activity.teamKiotisTurnover;
        case level == 'team' && mark == this.STANHOME_NAME:
          return activity.teamStanhomeTurnover;
        case level == 'team' && mark == this.FAMILY_EXPERT_NAME:
          return activity.teamFamilyExpertTurnover;
        case level == 'team' && mark == this.FLORMAR_NAME:
          return activity.teamFlormarTurnover;
        case level == 'team' && mark == this.RICAUD_NAME:
          return activity.teamRicaudTurnover;
        case level == 'sector' && !mark:
          return activity.sectorTotalTurnover;
        case level == 'sector' && mark == this.KIOTIS_NAME:
          return activity.sectorKiotisTurnover;
        case level == 'sector' && mark == this.STANHOME_NAME:
          return activity.sectorStanhomeTurnover;
        case level == 'sector' && mark == this.FAMILY_EXPERT_NAME:
          return activity.sectorFamilyExpertTurnover;
        case level == 'sector' && mark == this.FLORMAR_NAME:
          return activity.sectorFlormarTurnover;
        case level == 'sector' && mark == this.RICAUD_NAME:
          return activity.sectorRicaudTurnover;
          //DD-ZM
        case level == 'division' && !mark:
          return activity.divisionTotalTurnover;
        case level == 'division' && mark == this.KIOTIS_NAME:
          return activity.divisionKiotisTurnover;
        case level == 'division' && mark == this.STANHOME_NAME:
          return activity.divisionStanhomeTurnover;
        case level == 'division' && mark == this.FAMILY_EXPERT_NAME:
          return activity.divisionFamilyExpertTurnover;
        case level == 'division' && mark == this.FLORMAR_NAME:
          return activity.divisionFlormarTurnover;
        case level == 'division' && mark == this.RICAUD_NAME:
          return activity.divisionRicaudTurnover;
          //DR
        case level == 'region' && !mark:
          return activity.regionTotalTurnover;
        case level == 'region' && mark == this.KIOTIS_NAME:
          return activity.regionKiotisTurnover;
        case level == 'region' && mark == this.STANHOME_NAME:
          return activity.regionStanhomeTurnover;
        case level == 'region' && mark == this.FAMILY_EXPERT_NAME:
          return activity.regionFamilyExpertTurnover;
        case level == 'region' && mark == this.FLORMAR_NAME:
          return activity.regionFlormarTurnover;
        case level == 'region' && mark == this.RICAUD_NAME:
          return activity.regionRicaudTurnover;
        default:
          console.error('level unknown or mark unknown', level, mark);
          return 0;
      }
    }

    public getTotalTurnoverReference(
        activity: IActivity, level: string): number {
      switch (true) {
        case level == 'region':
          return activity.regionTotalTurnoverReference;
        case level == 'division':
          return activity.divisionTotalTurnoverReference;
        default:
          console.error('level unknown', level);
          return 0;
      }
    }

    public getObjectifNumber(activity: IActivity, level: string): number {
      switch (true) {
        case level == 'region':
          return activity.regionObjectifNumber;
        case level == 'division':
          return activity.divisionObjectifNumber;
        default:
          console.error('level unknown', level);
          return 0;
      }
    }

    public getLastCycle(currentCycle: ICommercialCalendar): number {
      var cycle = currentCycle.periodeName.substring(1, 3);
      switch (cycle) {
        case '01':
          return 12;
        default :
          return parseInt(cycle) - 1;
      }
    }

    public getTurnoverLeadersByProfile(
        activity: IActivity, profile: string): number {
      switch (true) {
        case profile == 'DR':
          return activity.regionTotalTurnover;
        case profile == 'DD' || profile == 'ZM':
          return activity.divisionTotalTurnover;
        case profile == 'LDS' || profile == 'ADS' || profile == 'CO':
          return activity.sectorTotalTurnover;
        case profile == 'AA' || profile == 'AS':
          return activity.teamTotalTurnover;
        default:
          return activity.personalTotalTurnover;
      }
    }

    /**
     * Get the correct orderNumber from activty according to level
     *
     * @param activity
     * @param level
     * @returns {number}
     */
    public getOrderNumber(activity: IActivity, level: string): number {
        switch (true) {
          case level == 'n0':
            return activity.personalOrderNumber;
          case level == 'n1':
            return activity.n1OrderNumber;
          case level == 'n2':
            return activity.n2OrderNumber;
          case level == 'n1n2':
            return activity.n1OrderNumber + activity.n2OrderNumber;
          case level == 'n3':
            return activity.n3OrderNumber;
          case level == 'team':
            return activity.teamOrderNumber;
          case level == 'sector':
            return activity.sectorOrderNumber;
          case level == 'division':
            return activity.divisionOrderNumber;
          case level == 'region':
            return activity.regionOrderNumber;
          default:
            console.error('level unknown', level);
            return 0;
        }
    }

    /**
     * get the current activity in activityList according to currentCycle
     *
     * @param activityList
     * @param currentCycle
     * @returns {IActivity}
     */
    public getCurrentActivity(
        activityList: IActivity[],
        currentCycle: ICommercialCalendar): IActivity {
      var selectedActivity: IActivity = null;
      angular.forEach(activityList, function(current_activity: IActivity) {
        if (current_activity.periode &&
            current_activity.periode.periodeName == currentCycle.periodeName) {
          selectedActivity = current_activity;
        }
      });
      return selectedActivity;
    }

    /**
     * get the current activity in activityList according to nMoins1Cycle
     *
     * @param activityList
     * @param nMoins1Cycle
     * @returns {IActivity}
     */
    public getNMinus1Activity(
        activityList: IActivity[],
        nMoins1Cycle: ICommercialCalendar): IActivity {
      var selectedActivity: IActivity = null;
      angular.forEach(activityList, function(current_activity: IActivity) {
        if (nMoins1Cycle && current_activity.periode &&
            (current_activity.periode.periodeName == nMoins1Cycle.periodeName)) {
          selectedActivity = current_activity;
        }
      });
      return selectedActivity;
    }

    /**
     * Sum turnover in turnoverArray
     *
     * @param turnoverArray
     * @returns {any}
     */
    public getTurnoverSum(turnoverArray: number[]): number {
      if (turnoverArray) {
        var sum: number = 0;
        angular.forEach(turnoverArray, function(current: number) {
          sum += current;
        });
        return sum;
      } else {
        return null;
      }
    }

    /**
     *
     * @param key
     * @param current_activity
     * @param yearName
     * @returns {boolean}
     */
    private isActivityRelatedToCurrentMonth(
        key: any, current_activity: any, yearName: string) {
      if (current_activity && current_activity.periode) {
        var monthNumberCurrentActivity = parseInt(
            current_activity.periode.periodeName.substring(1, 3));
        return monthNumberCurrentActivity == key &&
            current_activity.periode.yearName == yearName;
      } else {
        console.error('current période not defined correctly', current_activity);
      }
    }

    /**
     *
     * @param list
     * @param yearName
     * @param getterValue
     * @returns {Array}
     */
    private getYearsArray(
        list: any[], yearName: any,
        getterValue: (current: any) => void): any[] {

      if (!list || !yearName || !getterValue) {
        console.error(list, yearName, getterValue);
        throw new Error('Arg missing');
      }

      var turnoverArray: any = {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
      };

      angular.forEach(turnoverArray, function(value: any, key: any) {
        angular.forEach(list, function(current_activity: IActivity) {
          if (this.isActivityRelatedToCurrentMonth(key, current_activity,
                  yearName)) {
            turnoverArray[key] = getterValue(current_activity);
          }
        }.bind(this));
      }.bind(this));

      var sum: number = 0;
      var res: any[];
      res = [];
      angular.forEach(turnoverArray, function(value: any, key: any) {
        sum += value;
        res.push(value);
      });
      if (sum !== 0) {
        return res;
      } else {
        return [];
      }
    }

    /**
     * Get the array of turnover values
     *
     * @param activityList
     * @param yearName
     * @param level
     * @param mark
     * @returns {any[]}
     */
    public getTurnoverArray(
        activityList: IActivity[], yearName: string, level: string,
        mark: string): any[] {
      return this.getYearsArray(activityList, yearName,
          function(current_activity: any) {
            return this.getTurnover(current_activity, level, mark);
          }.bind(this),
      );
    }

    /**
     * Get the array of objectif values
     *
     * @param activityList
     * @param yearName
     * @param level
     * @param mark
     * @returns {any[]}
     */
    public getObjectifArray(
        activityList: IActivity[], yearName: string, level: string,
        mark: string): any[] {
      return this.getYearsArray(activityList, yearName,
          function(current_activity: any) {
            return this.getTurnover(current_activity, level, mark) *
                this.getObjectifNumber(current_activity, level);
          }.bind(this),
      );
    }

    /**
     * get the array of commission values
     *
     * @param activityList
     * @param type
     * @param yearName
     * @returns {any[]}
     */
    public getCommissionArray(
        activityList: IActivity[], type: TypeCommissionInActivity,
        yearName: string): any[] {
      return this.getYearsArray(activityList, yearName,
          function(current_activity: IActivity) {
            return function(type: TypeCommissionInActivity) {
              switch (type) {
                case 'direct':
                  return current_activity.directCommissionAmount;
                case 'indirectN1P2R':
                  return current_activity.indirectCommissionAmountN1P2R;
                case 'indirectN2P2R':
                  return current_activity.indirectCommissionAmountN2P2R;
                case 'indirectForced':
                  return current_activity.indirectCommissionAmountForced;
                default:
                  throw new Error('wrong arg type');
              }
            }(type);
          },
      );
    }

    /**
     *
     *
     * @param activityList
     * @param typeArray
     * @param yearName
     * @returns {Array}
     */
    public getSumMultipleCommissionArray(
        activityList: IActivity[], typeArray: TypeCommissionInActivity[],
        yearName: string): any[] {
      var array: any = {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
      };
      var array_years_tmp;
      angular.forEach(typeArray,
          function(current_type: TypeCommissionInActivity) {
            array_years_tmp = this.getCommissionArray(activityList,
                current_type, yearName);
            angular.forEach(array_years_tmp,
                function(value_month_tmp: any, key_month_tmp: any) {
                  if (value_month_tmp) {
                    array[key_month_tmp + 1] = array[key_month_tmp + 1] +
                        value_month_tmp;
                  }
                });
          }.bind(this));

      var sum: number = 0;
      var res: any[];
      res = [];
      angular.forEach(array, function(value: any, key: any) {
        sum += value;
        res.push(value);
      });
      if (sum !== 0) {
        return res;
      } else {
        return [];
      }

    }

    /**
     * in turnoverArray, sum turnover values matching quarterName
     *
     * @param activityList
     * @param quarterName
     * @returns {number}
     */
    public getSumTurnoverCurrentQuarterly(
        activityList: IActivity[], quarterName: string): number {
      var sum = 0;
      angular.forEach(activityList, function(current_activity: IActivity) {
        if (current_activity.periode &&
            current_activity.periode.quarterName == quarterName) {
          if (current_activity.personalTotalTurnover) {
            sum += current_activity.personalTotalTurnover;
          }
        }
      });
      return sum;
    }

    /**
     * in activityArray, sum activity values matching quarterName
     *
     * @param activityList
     * @param quarterName
     * @returns {number}
     */
    public getSumActivityWeekNumberCurrentQuarterly(
        activityList: IActivity[], quarterName: string): number {
      var sum = 0;
      angular.forEach(activityList, function(current_activity: IActivity) {
        if (current_activity.periode &&
            current_activity.periode.quarterName == quarterName) {
          sum += current_activity.weekActivityNumber;
        }
      });
      return sum;
    }

    /**
     * get max turnover value in activtyList across levels
     *
     * @param activityList
     * @param currentYearName
     * @param lastYearName
     * @returns {number}
     */
    public getMAxTurnoverValue(
        activityList: IActivity[], currentYearName: string,
        lastYearName: string): number {
      var turnoverN0 = Math.max(
          ...this.getTurnoverArray(activityList, currentYearName, 'n0', null),
          ...this.getTurnoverArray(activityList, lastYearName, 'n0', null), 0);
      var turnoverN1 = Math.max(
          ...this.getTurnoverArray(activityList, currentYearName, 'n1', null),
          ...this.getTurnoverArray(activityList, lastYearName, 'n1', null), 0);
      var turnoverN2 = Math.max(
          ...this.getTurnoverArray(activityList, currentYearName, 'n2', null),
          ...this.getTurnoverArray(activityList, lastYearName, 'n2', null), 0);
      return Math.max(turnoverN0, turnoverN1, turnoverN2);
    }

  }
}
appDashboard.service('ActivitiesService', services.ActivitiesService);
