/// <reference path="../appDashboard.ts" />

var arrayLeaderBlock = function () {
    return {
        restrict : 'E',
        replace: false,
        scope: {
            promiseUserPopulated: '=',
            promiseCommercialCalendar: '=',
            displayFilterDivisionName: '=',
        },
        controller : 'DashboardArrayLeaderBlockController as dashboardArrayLeaderBlockController',
        templateUrl : '/templates/array-leader-block.html',
    };
};

appDashboard.directive("arrayLeaderBlock", [arrayLeaderBlock]);

