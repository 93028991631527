/// <reference path="../appDashboard.ts" />
import IActivitiesService = services.IActivitiesService;

declare var Highcharts: any;

let drawChart = function(ChartsService: services.IChartsService) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      series: '=',
      id: '@',
      isDisplayed: '=',
      option: '=',
    },
    templateUrl: '/templates/draw-chart.html',

    link: function(scope: any) {

      let chart;
      // scope.series = [];

      scope.sumArray = function(array: any) {
        if (array) {
          return array.reduce((a: any, b: any) => a + b, 0);
        }
      };

      function reset(series: any, id: any, option: any) {
        Highcharts.setOptions(ChartsService.defaultHighchartsOption);
        // ***** d'espace à la fin, je sais pas pk.
        let cleanId = id.replace(/\s/g, '');
        let optionChart = option ? option : {};
        if (series) {
          chart = Highcharts.chart(cleanId,
              ChartsService.getChart(series, optionChart));
        }
      }

      scope.$watch('isDisplayed', (newValue: any, oldValue: any) => {
        if (scope.isDisplayed) {
          setTimeout(function() {
            reset(scope.series, scope.id, scope.option);
            scope.$apply(function() {
            });
          });
        }
      });

      scope.$watch('series', (newValue: any, oldValue: any) => {
        if (scope.series) {
          setTimeout(function() {
            reset(scope.series, scope.id, scope.option);
            scope.$apply(function() {
            });
          });
        }
      });

    },
  };
};

appDashboard.directive('drawChart', ['ChartsService', drawChart]);
