module services {
    'use strict';
    import IPromise = angular.IPromise;
    import IUserPopulated = models.IUserPopulated;

    export interface IDisplayService {
        displayIndirectCommission(user : IUserPopulated) : boolean;
        displayArrayLeaderBlock(user : IUserPopulated) : boolean;
        displayForcedCommissionsBlock(user : IUserPopulated) : boolean;
        displayPalierBlock(user : IUserPopulated) : boolean;
        displayObjectivesBlock(user : IUserPopulated, subUserListObject) : boolean;
    }
    export class DisplayService implements IDisplayService {

        constructor() {
        }


        private matchProfile(user, profileArray){
            var res = false;
            if(user && profileArray){
                profileArray.forEach(function (profile) {
                    if(!res && profile === user.profile){
                        res = true;
                    }
                })
            }
            return res;
        }


        /**
         * Return true if the user can see indirect commission block
         * @param user
         * @returns {any}
         */
        public displayIndirectCommission(user : IUserPopulated) : boolean{
            return user.p2RLevel && user.isSF
        }

        public displayForcedCommissionsBlock(user : IUserPopulated) : boolean{
            return this.matchProfile(user, ['LDS', 'Coach', 'CO', 'Leader']);
        }

        public displayPalierBlock(user : IUserPopulated) : boolean{
            return this.matchProfile(user, ['LDS', 'Coach', 'CO', 'Leader']);
        }
        public displayObjectivesBlock(user : IUserPopulated, subUserListObject) : boolean{
            return this.matchProfile(user, ['LDS', 'Coach', 'CO', 'Leader']) && user.isSF && subUserListObject.count >1;
        }

        public displayArrayLeaderBlock(user : IUserPopulated) : boolean{
            if(user){
                if(user.profile === 'AA' || user.profile === 'ADS' || user.profile === 'LDS' || user.profile === 'Leader'){
                    return true
                } else{
                    return false;
                }
            } else{
                return false
            }
        }






    }
}
appDashboard.service("DisplayService", services.DisplayService);


