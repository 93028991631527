module controllers {

  import IUsersService = services.IUsersService;
  import IUserPopulated = models.IUserPopulated;
  import ICommercialCalendarService = services.ICommercialCalendarsService;
  import ICommercialCalendar = models.ICommercialCalendar;

  export class DashboardTabViewController implements angular.IController {
    $onInit = () => { };

    private $scope: ng.IScope;
    private $state: ng.ui.IStateService;
    private $locale: ng.ILocaleService;
    private $stateParams: any;
    private usersService: IUsersService;
    private commercialCalendarService: ICommercialCalendarService;

    public objectFilters: {};
    public type: string;
    public promiseUserPopulated: any;
    public promiseCommercialCalendar: any;
    public logAsChild: boolean;

    public static $inject = [
      '$scope',
      '$state',
      '$locale',
      '$stateParams',
      'UsersService',
      'CommercialCalendarsService',
    ];

    /**
     *
     * @param $scope
     * @param $state
     * @param $locale
     * @param $stateParams
     * @param usersService
     * @param commercialCalendarService
     */
    constructor(
        $scope: ng.IScope,
        $state: ng.ui.IStateService,
        $locale: ng.ILocaleService,
        $stateParams: any,
        usersService: IUsersService,
        commercialCalendarService: ICommercialCalendarService,) {
      this.$scope = $scope;
      this.$state = $state;
      this.$locale = $locale;
      this.$stateParams = $stateParams;
      this.usersService = usersService;
      this.commercialCalendarService = commercialCalendarService;

      this.objectFilters = {
        isActive: this.$stateParams.filter[0],
        childLevelForParent: this.$stateParams.filter[1],
        typology: this.$stateParams.filter[2],
        personalPerformanceIndicator: this.$stateParams.filter[3],
      };
      this.type = this.$stateParams.type;

      if ($stateParams && $stateParams.matricule) {
        // TODO delete this
        this.promiseUserPopulated = this.usersService.getUserPopulatedByMatricule(
            $stateParams.matricule);

      } else {
        this.promiseUserPopulated = this.usersService.getMe();
      }
      this.promiseCommercialCalendar = this.commercialCalendarService.getCommercialCalendarList();
      this.$locale.NUMBER_FORMATS.GROUP_SEP = ' ';
      this.$locale.NUMBER_FORMATS.DECIMAL_SEP = ',';

      if (window.location.href.indexOf('child') > -1) {
        this.logAsChild = true;
      }
    }

  }

}

appDashboard.controller('DashboardTabViewController',
    controllers.DashboardTabViewController);