/// <reference path="../appDashboard.ts" />7
module services {
  'use strict';
  import IPromise       = angular.IPromise;
  import ICommonService = services.ICommonService;

  export interface IPanService {
    getByMatricule(matricule: number): IPromise<any>;

    getSubByMatricule(matricule: number, GSDRValue: string, sort: String,
                      sortSens: number, limit: number,
                      offset: number, isActive: boolean, filter: any, exportExcel?: boolean): IPromise<any>;

    havePanData: boolean;
  }

  export class PanService implements IPanService {

    private $q: ng.IQService;
    private base_url: string = '/api/pan';
    private commonService: ICommonService;
    private promise_getByMatricule: any = [];
    private promise_getSubByMatricule: any = [];
    public havePanData: boolean = false;

    public static $inject = [
      '$q',
      'CommonService',
    ];

    /**
     *
     * @param $q
     * @param commonService
     */
    constructor(
        $q: ng.IQService,
        commonService: ICommonService) {
      this.commonService = commonService;
      this.$q = $q;
    }

    public getByMatricule(matricule: number): IPromise<any> {
      let promiseString = matricule;
      if (this.promise_getByMatricule[promiseString]) {
      } else {
        let deferred = this.$q.defer();
        this.commonService.httpGet(this.base_url + '/byMatricule/' + matricule,
            {}).then((data) => {
          if (data && data.length > 0) {
            this.havePanData = true;
          }
          deferred.resolve(data);
        }, (err) => {
          deferred.reject(err);
        });
        this.promise_getByMatricule[promiseString] = deferred.promise;
      }
      return this.promise_getByMatricule[promiseString];
    }

    public getSubByMatricule(
        matricule: number, GSDRValue: string, sort: String, sortSens: number,
        limit: number,
        offset: number, isActive: boolean, filter: any, isExport: boolean): IPromise<any> {
      let promiseString = matricule + '_' + GSDRValue + '_' + sort + '_' +
          sortSens + '_' +
          limit + '_' + offset + '_' + isActive + '_' + isExport + '_' + filter.name;
      if (this.promise_getSubByMatricule[promiseString]) {
      } else {
        let deferred = this.$q.defer();
        this.commonService.httpGet(this.base_url + '/subByMatricule/' +
            matricule,
            {
              'GSDRValue': GSDRValue,
              'sort': sort,
              'sortSens': sortSens,
              'limit': limit,
              'offset': offset,
              'isActive': isActive,
              'isExport': isExport,
              'filter': filter
            }).then((data) => {
          if (data && data.count > 0) {
            this.havePanData = true;
          }
          deferred.resolve(data);
        }, (err) => {
          deferred.reject(err);
        });
        this.promise_getSubByMatricule[promiseString] = deferred.promise;
      }
      return this.promise_getSubByMatricule[promiseString];
    }

  }
}
appDashboard.service('PanService', services.PanService);
