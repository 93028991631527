/// <reference path="../appDashboard.ts" />
var objectivesBlock = function () {
    return {
        restrict: 'E',
        replace: false,
        scope: {
            promiseUserPopulated: "=",
            promiseCommercialCalendar: "="
        },
        templateUrl: '/templates/objectivesBlock.html',
        controller: 'DashboardObjectivesBlockController as objectivesBlockController',
    };
};

appDashboard.directive("objectivesBlock", objectivesBlock);
