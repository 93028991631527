/// <reference path="../appDashboard.ts" />7
module models {
    'use strict';
    export interface ICommercialCalendar{
        startDatePeriode : string
        endDatePeriode : string
        yearName : string
        periodeName : string
        quarterName : string
        isActive : boolean
        numberWeekOfCycle : number
    }
}


