/// <reference path="app.ts" />
var appDashboard = angular.module('appDashboard',
    ['ui.router', '720kb.tooltips']);

appDashboard.value('version', 'v 3.1.0');

appDashboard.value('limitNumber', 0);
appDashboard.value('limitGainNumber', 2);

appDashboard.config(function(
    $stateProvider: angular.ui.IStateProvider,
    $urlRouterProvider: angular.ui.IUrlRouterProvider,
    $locationProvider: angular.ILocationProvider) {

  $urlRouterProvider.otherwise('/');

  $stateProvider.state('index', {
    url: '/',
    templateUrl: '/templates/dashboard-index.html',
    controller: 'DashboardIndexController as dashboardIndexController',
    resolve: {
      seeHeader: function() {
        return false;
      },
    },
  }).state('matricule', {
    url: '/matricule/:matricule',
    templateUrl: '/templates/dashboard-index.html',
    controller: 'DashboardIndexController as dashboardIndexController',
    resolve: {
      seeHeader: function() {
        return false;
      },
    },
  }).state('seeDashboard', {
    url: '/seeDashboard/:matricule',
    templateUrl: '/templates/dashboard-index.html',
    controller: 'DashboardIndexController as dashboardIndexController',
    resolve: {
      seeHeader: function() {
        return true;
      },
    },
  }).state('tab', {
    url: '/tab/:type/:matricule?filter',
    params: {
      'filter': [],
    },
    templateUrl: '/templates/dashboard-tab-view.html',
    controller: 'DashboardTabViewController as dashboardTabViewController',
  });
});

//We already have a limitTo filter built-in to angular,
//let's make a startFrom filter
appDashboard.filter('startFrom', function() {
  return function(input: any[], start: number) {
    if (input && input.length > 0) {
      start = +start; //parse to int
      return input.slice(start);
    }

  };
});

appDashboard.filter('customRound', function() {
  return function(input) {
    return Math.round(input);
  };
});

appDashboard.filter('monthName', [
  function() {
    return function(date_string: string) { //1 = January
      if (date_string) {
        var date: any = new Date(date_string);
        if (date) {
          var monthNumber: number = date.getMonth();
          var monthNames: string[] = [
            'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
            'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];
          return monthNames[monthNumber];
        }
      }
    };
  }]);

appDashboard.filter('daysName', [
  function() {
    return function(date_string: string) { //1 = January
      if (date_string) {
        var date: any = toUTCDate(new Date(date_string));
        if (date) {
          var dayNumber: number = date.getDay();
          var daysName: string[] = [
            'dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi',
            'samedi'];
          return daysName[dayNumber];
        }
      }
    };
  }]);

appDashboard.filter('strReplace', function() {
  return function(input, from, to) {
    input = input || '';
    from = from || '';
    to = to || '';
    return input.replace(new RegExp(from, 'g'), to);
  };
});

appDashboard.filter('capitalize', function() {
  return function(input, scope) {
    if (input != null)
      input = input.toLowerCase();
    return input.substring(0, 1).toUpperCase() + input.substring(1);
  };
});
var toUTCDate = function(date: any) {
  var _utc = new Date(date.getUTCFullYear(), date.getUTCMonth(),
      date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(),
      date.getUTCSeconds());
  return _utc;
};


