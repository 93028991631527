/// <reference path="../appDashboard.ts" />

var teamActivityBlock = function () {
    return {
        restrict : 'E',
        replace: false,
        scope: {
            promiseUserPopulated: '=',
            promiseCommercialCalendar: '=',
            limitNumber: '=',
            sortBy: '='
        },
        controller : 'DashboardTeamActivityBlockController as dashboardTeamActivityBlockController',
        templateUrl : '/templates/team-activity-block.html',
    };
};

appDashboard.directive("teamActivityBlock", [teamActivityBlock]);
