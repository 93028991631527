/// <reference path="../appDashboard.ts" />
module controllers {

    import IUserPopulated  = models.IUserPopulated;
    declare var Promise: any;
    import IRemunerationsService = services.RemunerationsService;
    import IRemuneration = models.IRemuneration;
    import IEventsService = services.EventsService;
    import IExportsService = services.IExportsService;
    import IDisplaysArrayService = services.IDisplaysArrayService;

    export class DashboardRemunerationBlockController implements angular.IController {
        $onInit = () => { };

        private $scope: ng.IScope;
        private $state: ng.ui.IStateService;
        private $q: ng.IQService;

        public currentPage: number;
        public currentCycleNumber: number;
        public numberOfItemByPage: number;
        public numberOfPages: number;
        public tableHeaders: any;
        private structure: string;
        public user: IUserPopulated;
        private remunerationsService: IRemunerationsService;
        private eventsService: IEventsService;
        private exportsService: IExportsService;
        private displaysArrayService: IDisplaysArrayService;
        public initRemListLength: number;
        public remunerationsList: IRemuneration[];
        public filter: any;
        public sortSens: number;
        public sort: string;

        public static $inject = [
            '$scope',
            '$state',
            '$q',
            'EventsService',
            'RemunerationsService',
            'ExportsService',
            'DisplaysArrayService',
        ];

        /**
         *
         * @param $scope
         * @param $state
         * @param $q
         * @param eventsService
         * @param remunerationsService
         * @param exportsService
         * @param displaysArrayService
         */
        constructor(
            $scope: any,
            $state: ng.ui.IStateService,
            $q: ng.IQService,
            eventsService: IEventsService,
            remunerationsService: IRemunerationsService,
            exportsService: IExportsService,
            displaysArrayService: IDisplaysArrayService
        ) {
        this.$scope = $scope;
        this.$state = $state;
        this.tableHeaders = [];
        this.remunerationsService = remunerationsService;
        this.$q = $q;
        this.exportsService = exportsService;
        this.eventsService = eventsService;
        this.displaysArrayService = displaysArrayService;

        this.filter ="";
        this.numberOfItemByPage = 10;
        this.currentPage = 0;
        this.sortSens = 1;
        this.sort = 'periode';

        this.$q.all(
        [$scope.promiseUserPopulated]).
        then(function(data: any) {
            this.user = data[0];
        this.tableHeaders = [
            {label: "Période", key: "periode", sort: true, class: this.displaysArrayService.getClassBy('both')},
            {label: "CA groupe + CA Leader", key: "cagroupeleader", sort: false, class: this.displaysArrayService.getClassBy('both')},
            {label: "RAF taux de com° palier supérieur", key: "caraf", sort: false, class: this.displaysArrayService.getClassBy('both')},
            {label: "Gain Com. Indirect Groupe", key: "gaincagroupe", sort: false, class: this.displaysArrayService.getClassBy('both')},
            {label: "Gain lien Leader", key: "gaincaleader", sort: false, class: this.displaysArrayService.getClassBy('both')},
            {label: "TOTAL gain indirect ", key: "gaintotalindirect", sort: false, class: this.displaysArrayService.getClassBy('both')},
            {label: "Prime création Leader", key: "gaincreationleader", sort: false, class: this.displaysArrayService.getClassBy('both')},
            {label: "Total prime recrut", key: "totalprimrecrut", sort: false, class: this.displaysArrayService.getClassBy('both')},
            {label: "TOTAL gain", key: "gaincatotal", sort: false, class: this.displaysArrayService.getClassBy('both')},
        ];

        if(this.user.profile === 'LDS' || this.user.profile === 'Leader') {
                this.tableHeaders.unshift(
                    {label: "SUIVI", key: "follow", sort: false, class: this.displaysArrayService.getClassBy('both')},
                    {label: "Nom AA", key: "lastName", sort: true, class: this.displaysArrayService.getClassBy('both')},
                );
        }else if(this.user.profile === 'ZM' ||
                this.user.profile === 'DD' ||
                this.user.profile === 'DR') {
                this.tableHeaders.unshift(
                    {label: "SUIVI", key: "follow", sort: false, class: this.displaysArrayService.getClassBy('both')},
                    {label: "Secteur", key: "secteur", sort: false, class: this.displaysArrayService.getClassBy('both')},
                    {label: "Nom AA", key: "lastName", sort: true, class: this.displaysArrayService.getClassBy('both')},
                );
        }
        this.initVariable(this.user);
         }.bind(this));

        }

        private initVariable(user) {
            this.remunerationsService.getByMatricule(user.matricule, this.sort, this.sortSens, this.numberOfItemByPage,this.currentPage * this.numberOfItemByPage, this.filter, this.structure).then(
                function(remunerations: any) {
                    if (!this.initRemListLength) {
                        this.initRemListLength = remunerations.count;
                    }
                    this.numberOfPages = Math.ceil(remunerations.count / this.numberOfItemByPage);
                    this.populateRemList(remunerations.list);
                }.bind(this),
                function(err: any) {
                    console.error(err);
                },
            );
        }

        /**
         * Tree types of table visualisation :
         * AA | Leader : we can see rem data without the user(by matricule) information
         * LDS : we can see rem data and sector, and we can access user dashboard
         * ZM | DD | DR : we can see rem data, sector name, and we can access user dashboard
         * @param data
         */
        public populateRemList(data) {
            this.remunerationsList = [];
            let remu;
            data.forEach((e) => {
                if(this.user.profile === 'AA') {
                    remu = {
                        periode: {display: this.displaysArrayService.getDisplayBy('default'), value: e.periode, class: this.displaysArrayService.getClassBy('both')},
                        cagroupeleader: {display: this.displaysArrayService.getDisplayBy('default'), value: this.euroFormat(e.catotal), class: this.displaysArrayService.getClassBy('both')},
                        caraf: {display: this.displaysArrayService.getDisplayBy('default'), value: this.euroFormat(e.caraf), class: this.displaysArrayService.getClassBy('both')},
                        gaincagroupe: {display: this.displaysArrayService.getDisplayBy('default'), value: this.euroFormat(e.gaincagroupe), class: this.displaysArrayService.getClassBy('both')},
                        gaincaleader: {display: this.displaysArrayService.getDisplayBy('default'), value: this.euroFormat(e.gaincaleader), class: this.displaysArrayService.getClassBy('both')},
                        gaintotalindirect: {display: this.displaysArrayService.getDisplayBy('default'), value: this.euroFormat(e.totalGainIndirect), class: this.displaysArrayService.getClassBy('both')},
                        gaincreationleader: {display: this.displaysArrayService.getDisplayBy('default'), value: this.euroFormat(e.gaincreationleader), class: this.displaysArrayService.getClassBy('both')},
                        totalprimrecrut: {display: this.displaysArrayService.getDisplayBy('default'), value: this.euroFormat(e.totalGainRecrutement), class: this.displaysArrayService.getClassBy('both')},
                        gaincatotal: {display: this.displaysArrayService.getDisplayBy('default'), value: this.euroFormat(e.totalGain), class: this.displaysArrayService.getClassBy('both')},
                    };
                }else if(this.user.profile === 'LDS' || this.user.profile === 'Leader') {
                    remu = {
                        follow: {display: this.displaysArrayService.getDisplayBy('gototxtless'), matricule: e.matricule, class: this.displaysArrayService.getClassBy('both')},
                        lastName: {display: this.displaysArrayService.getDisplayBy('showmoreparent'), value: e.name, class: this.displaysArrayService.getClassBy('both'), index: 0},
                        periode: {display: this.displaysArrayService.getDisplayBy('default'), value: e.periode, class: this.displaysArrayService.getClassBy('both')},
                        cagroupeleader: {display: this.displaysArrayService.getDisplayBy('default'), value: this.euroFormat(e.catotal), class: this.displaysArrayService.getClassBy('both')},
                        caraf: {display: this.displaysArrayService.getDisplayBy('default'), value: this.euroFormat(e.caraf), class: this.displaysArrayService.getClassBy('both')},
                        gaincagroupe: {display: this.displaysArrayService.getDisplayBy('default'), value: this.euroFormat(e.gaincagroupe), class: this.displaysArrayService.getClassBy('both')},
                        gaincaleader: {display: this.displaysArrayService.getDisplayBy('default'), value: this.euroFormat(e.gaincaleader), class: this.displaysArrayService.getClassBy('both')},
                        gaintotalindirect: {display: this.displaysArrayService.getDisplayBy('default'), value: this.euroFormat(e.totalGainIndirect), class: this.displaysArrayService.getClassBy('both')},
                        gaincreationleader: {display: this.displaysArrayService.getDisplayBy('default'), value: this.euroFormat(e.gaincreationleader), class: this.displaysArrayService.getClassBy('both')},
                        totalprimrecrut: {display: this.displaysArrayService.getDisplayBy('default'), value: this.euroFormat(e.totalGainRecrutement), class: this.displaysArrayService.getClassBy('both')},
                        gaincatotal: {display: this.displaysArrayService.getDisplayBy('default'), value: this.euroFormat(e.totalGain), class: this.displaysArrayService.getClassBy('both')},
                        userExpand: {0: e},
                        expanded: {0: false}
                    };
                    }else if(this.user.profile === 'ZM' ||
                    this.user.profile === 'DD' ||
                    this.user.profile === 'DR') {
                    remu = {
                        follow: {display: this.displaysArrayService.getDisplayBy('gototxtless'), matricule: e.matricule, class: this.displaysArrayService.getClassBy('both')},
                        secteur: {display: this.displaysArrayService.getDisplayBy('default'), value: e.sectorName, class: this.displaysArrayService.getClassBy('both')},
                        lastName: {display: this.displaysArrayService.getDisplayBy('showmoreparent'), value: e.name, class: this.displaysArrayService.getClassBy('both'), index: 0},
                        periode: {display: this.displaysArrayService.getDisplayBy('default'), value: e.periode, class: this.displaysArrayService.getClassBy('both')},
                        cagroupeleader: {display: this.displaysArrayService.getDisplayBy('default'), value: this.euroFormat(e.catotal), class: this.displaysArrayService.getClassBy('both')},
                        caraf: {display: this.displaysArrayService.getDisplayBy('default'), value: this.euroFormat(e.caraf), class: this.displaysArrayService.getClassBy('both')},
                        gaincagroupe: {display: this.displaysArrayService.getDisplayBy('default'), value: this.euroFormat(e.gaincagroupe), class: this.displaysArrayService.getClassBy('both')},
                        gaincaleader: {display: this.displaysArrayService.getDisplayBy('default'), value: this.euroFormat(e.gaincaleader), class: this.displaysArrayService.getClassBy('both')},
                        gaintotalindirect: {display: this.displaysArrayService.getDisplayBy('default'), value: this.euroFormat(e.totalGainIndirect), class: this.displaysArrayService.getClassBy('both')},
                        gaincreationleader: {display: this.displaysArrayService.getDisplayBy('default'), value: this.euroFormat(e.gaincreationleader), class: this.displaysArrayService.getClassBy('both')},
                        totalprimrecrut: {display: this.displaysArrayService.getDisplayBy('default'), value: this.euroFormat(e.totalGainRecrutement), class: this.displaysArrayService.getClassBy('both')},
                        gaincatotal: {display: this.displaysArrayService.getDisplayBy('default'), value: this.euroFormat(e.totalGain), class: this.displaysArrayService.getClassBy('both')},
                        userExpand: {0: e},
                        expanded: {0: false}
                    };
                }

                let isQuarter = e.periode.split("-")[1].charAt(0);
                if(isQuarter == "Q") {
                    remu.highlight = "#DEDEDE";
                }
                this.remunerationsList.push(remu);
            });
        }

        public filterByUser(attibutValue) {
            this.currentPage = 0;
            this.filter = attibutValue;
            this.initVariable(this.user);
        }

        public structureFilter(attibutValue) {
            this.currentPage = 0;
            this.structure = attibutValue;
            this.initVariable(this.user);
        }

        public getNumber = function(num: number): any[] {
            return new Array(num);
        };

        public displayArrayRem() {
            return !!this.initRemListLength;
        };

        public gotoPage(index) {
            if(index >= 0 && index < this.numberOfPages) {
                this.currentPage = index;
                this.initVariable(this.user);
            }
        }

        public goToDashboard(matricule: string): void {
            var url = this.$state.href('seeDashboard', {matricule: matricule});
            window.open(url,'_blank');
        }

        private euroFormat = function(num) {
            if (!num && num !== 0) {
                return "###";
            }
            return (parseFloat(num).toFixed(2) + '€').replace(/[.]/g, ',');
        };

        /**
         * to export data in excel
         */
        public exportExcel() {
            this.exportsService.exportRem(this.user.matricule, this.filter, this.structure);
        }

        public showUser(value, remIndex, index) {
            if(this.$scope.datas) {
                for(let indexExpanded in this.$scope.datas[remIndex].expanded) {
                    this.$scope.datas[remIndex].expanded[indexExpanded] = false;
                }
                this.$scope.datas[remIndex].expanded[index] = value;
            }
            for(let indexExpanded in this.remunerationsList[remIndex].expanded) {
                this.remunerationsList[remIndex].expanded[indexExpanded] = false;
            }
            this.remunerationsList[remIndex].expanded[index] = value;
        }

        public setOrderBy(sort, sens) {
            this.sortSens = sens;
            this.sort = sort;
            this.initVariable(this.user);
        }
    }
}

appDashboard.controller('DashboardRemunerationBlockController',
    controllers.DashboardRemunerationBlockController);
