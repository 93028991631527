/// <reference path="../appDashboard.ts" />7
module models {
    'use strict';
    export interface ICredential{
        login : string;
        password : string;
    }
}


