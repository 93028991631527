/// <reference path="../appDashboard.ts" />7
module services {
    'use strict';
    import IPromise       = angular.IPromise;
    import IPrime         = models.IPrime;
    import ICommonService = services.ICommonService;

    export interface IPrimeService {
        getAmountWinPrimeArray(primeList : IPrime[], yearName : string, idPrime: number) : any[];
        getCurrentPrimeById(primeList : any, currentCycle : any, idPrime : number) : IPrime;
        getYearsArray(listPrime : IPrime [], yearName : any, idPrime: number, getterValue : (current: any) => void) : any[];
        isPrimeRelatedToCurrentMonthAndIdPrime(key : any, currentPrime : any, yearName : string, idPrime: number) : boolean;
        getRecrueValidPrimeArray(primeList : IPrime[], yearName : string, idPrime: number) : any[];
        getSumAttributQuarterly(primeList : IPrime[], quarterName : string , attribut : string, idPrime: number) : number;
        getSumAmountWinPrimeQuarterly(primeList : IPrime[], quarterName : string, idPrime: number) : number;
        getSumOfPrimes(primeList: IPrime[]) : number;
    }

    export class PrimeService implements IPrimeService {

        private $http:ng.IHttpService;
        private $q:ng.IQService;
        private commonService:ICommonService;

        public static $inject = [
            '$http',
            '$q',
            'CommonService'
        ];

        constructor($http:ng.IHttpService, $q:ng.IQService, commonService:ICommonService) {
            this.commonService = commonService;
        }

        public getCurrentPrimeById(primeList : any, currentCycle : any, idPrime : number) : IPrime {
            var selectedPrime : IPrime = null;
            angular.forEach(primeList, function (currentPrime : IPrime) {
                if (currentPrime.periodePrime.periodeName === currentCycle.periodeName && currentPrime.idPrime === idPrime){
                    selectedPrime = currentPrime;
                }
            });
            return selectedPrime;
        }

        /**
         *
         * @param key
         * @param current_activity
         * @param yearName
         * @returns {boolean}
         */
        public isPrimeRelatedToCurrentMonthAndIdPrime(key : any, currentPrime : any, yearName : string, idPrime: number) : boolean {
            var monthNumberCurrentPrime = parseInt(currentPrime.periodePrime.periodeName.substring(1,3));
            return monthNumberCurrentPrime === key && currentPrime.periodePrime.yearName === yearName && currentPrime.idPrime === idPrime;
        }

        /**
         *
         * @param listPrime
         * @param yearName
         * @param idPrime
         * @param getterValue
         * @returns {Array}
         */
        public getYearsArray(listPrime : IPrime [], yearName : any, idPrime: number, getterValue : (current: any) => void) : any[] {

            if(!listPrime || !yearName || !idPrime || !getterValue){
                console.error(listPrime, yearName, idPrime, getterValue);
                throw new Error('Arg missing');
            }

            var resArray : any = {
                1 : 0,
                2 : 0,
                3 : 0,
                4 : 0,
                5 : 0,
                6 : 0,
                7 : 0,
                8 : 0,
                9 : 0,
                10 : 0,
                11 : 0,
                12 : 0,
            };

            angular.forEach(resArray, function (value : any, key : any) {
                angular.forEach(listPrime, function (currentPrime : IPrime) {
                    if (this.isPrimeRelatedToCurrentMonthAndIdPrime(key, currentPrime, yearName, idPrime)){
                        resArray[key] = getterValue(currentPrime);
                    }
                }.bind(this))
            }.bind(this));

            var sum : number= 0;
            var res : any[];
            res = [];
            angular.forEach(resArray, function (value : any, key : any) {
                sum += value;
                res.push(value);
            });
            if (sum !== 0){
                return res;
            } else{
                return [];
            }
        }

        /**
         * Get the array of amountWinPrime values
         *
         * @param primeList
         * @param yearName
         * @param idPrime
         * @returns {any[]}
         */
        public getAmountWinPrimeArray(primeList : IPrime[], yearName : string, idPrime: number) : any[] {
            return this.getYearsArray(primeList, yearName, idPrime,
                function (prime : any) {
                    return prime.amountWinPrime;
                }.bind(this)
            );
        }

        /**
         * Get the array of numberRecrueValidPrime values
         *
         * @param primeList
         * @param yearName
         * @param idPrime
         * @returns {any[]}
         */
        public getRecrueValidPrimeArray(primeList : IPrime[], yearName : string, idPrime: number) : any[] {
            return this.getYearsArray(primeList, yearName, idPrime,
                function (prime : any) {
                    return prime.numberRecrueValidPrime;
                }.bind(this)
            );
        }

        /**
         * in primeList, sum prime values matching quarterName
         *
         * @param primeList
         * @param quarterName
         * @param attribut
         * @param idPrime
         * @returns {number}
         */
        public getSumAttributQuarterly(primeList : IPrime[], quarterName : string , attribut : string, idPrime: number) : number{
            var sum : number = 0;
            angular.forEach(primeList, function (currentPrime : IPrime) {
                if (currentPrime.periodePrime.quarterName === quarterName && currentPrime.idPrime === idPrime) {
                    sum += currentPrime[attribut];
                }
            });
            return sum;
        }

        public getSumAmountWinPrimeQuarterly(primeList : IPrime[], quarterName : string, idPrime: number) : number {
            var sum = 0;
            angular.forEach(primeList, function (currentPrime : IPrime) {
                if (currentPrime.periodePrime.quarterName === quarterName && currentPrime.idPrime === idPrime && currentPrime.statutPrime) {
                    sum += currentPrime.amountWinPrime;
                }
            });
            return sum;
        }

        public getSumOfPrimes(primeList: IPrime[]) : number {
            var sum = 0;
            angular.forEach(primeList, function (currentPrime : IPrime) {
                if (currentPrime && currentPrime.statutPrime) {
                    sum += currentPrime.amountWinPrime;
                }
            });
            return sum;
        }
    }
}
appDashboard.service("PrimeService", services.PrimeService);
