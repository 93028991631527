/// <reference path="../appDashboard.ts" />

let arrayDefault = function () {
    return {
        restrict : 'E',
        replace: false,
        scope: {
            tableHeaders: '=',
            datas: '=',
            promiseDataPopulated: '=',
            currentPage: '=',
            numberPage: '=',
            displayNewTab: '=',
        },
        controller : 'DashboardArrayController as arrayController',
        templateUrl : '/templates/array-default.html',
    };
};

appDashboard.directive("arrayDefault", [arrayDefault]);

