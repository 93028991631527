module controllers {

  import IUserPopulated             = models.IUserPopulated;
  import IUsersService              = services.IUsersService;
  import IUser                      = models.IUser;
  import ICommercialCalendarService = services.ICommercialCalendarsService;
  import ICommercialCalendar        = models.ICommercialCalendar;
  import IExportsService            = services.IExportsService;
  import IP2RService                = services.IP2RService;
  import IPrimeService              = services.IPrimeService;
  import IPrime                     = models.IPrime;

  export class DashboardManagerPrimeRecruitmentController implements angular.IController {
    $onInit = () => { };

    private $scope: any;
    private $state: ng.ui.IStateService;
    private usersService: IUsersService;
    private exportsService: IExportsService;
    private commercialCalendarService: ICommercialCalendarService;
    private p2RService: IP2RService;
    private $q: ng.IQService;
    private primeService: IPrimeService;
    private currentYearName: string;
    private lastYearName: string;

    public userPopulated: IUserPopulated;
    public promiseUserPopulated: any;
    public promiseCommercialCalendar: any;
    public commercialCalendarList: ICommercialCalendar[];
    public currentCycle: ICommercialCalendar;
    public currentCycleNumber: number;
    public currentPrime: IPrime;
    public amountWinPrimeArrayCurrentYear: any;
    public amountWinPrimeArrayLastYear: any;
    public recrueValidPrimeArrayCurrentYear: any;
    public recrueValidPrimeArrayLastYear: any;
    public tooltipOption: any;
    public amountWinPrimeChartOptions: any;
    public recrueValidPrimeChartOptions: any;
    public chartIsDisplayed: any;
    public numberRecruePrimeCurrentQuarter: number;
    public numberRecrueValidPrimeCurrentQuarter: number;
    public lastQuaterName: any;
    public numberRecruePrimeLastQuarter: number;
    public numberRecrueValidPrimeLastQuarter: number;
    public titleCurrentQuarter: any;
    public titleLastQuarter: any;
    public amountWinPrimeCurrentQuarter: number;
    public amountWinPrimeLastQuarter: number;

    public static $inject = [
      '$scope',
      '$state',
      'UsersService',
      'CommercialCalendarsService',
      'P2RService',
      '$q',
      'PrimeService',
      'ExportsService',
    ];

    /**
     *
     * @param $scope
     * @param $state
     * @param usersService
     * @param commercialCalendarService
     * @param p2RService
     */
    constructor(
        $scope: any,
        $state: ng.ui.IStateService,
        usersService: IUsersService,
        commercialCalendarService: ICommercialCalendarService,
        p2RService: IP2RService,
        $q: ng.IQService,
        primeService: IPrimeService,
        exportsService: IExportsService) {

      this.$scope = $scope;
      this.$state = $state;
      this.usersService = usersService;
      this.commercialCalendarService = commercialCalendarService;
      this.p2RService = p2RService;
      this.primeService = primeService;
      this.exportsService = exportsService;
      this.$q = $q;

      this.initVariables();

      this.$q.all(
          [$scope.promiseUserPopulated, $scope.promiseCommercialCalendar]).
          then(function(data: any) {
            this.init(data[0], data[1]);
          }.bind(this));

      //this.exportPrimeRecruitmentQuarter(113, 14018);

    }

    public initVariables() {

      this.chartIsDisplayed = {
        amountWinPrimeChart: false,
        recrueValidPrimeChart: false,
      };

      this.tooltipOption = {
        shared: true,
        borderWidth: 0,
        backgroundColor: 'transparent',
        valueSuffix: '',
        shadow: false,
        useHTML: true,
        style: {
          color: '#48494d',
          fontWeight: '700',
          fontSize: '15px',
          fontFamily: 'Lato',
          right: '-50px',
        },
        headerFormat: '<table>',
        footerFormat: '</table>',
        padding: '0',
        hideDelay: '5000',
        positioner: function(labelWidth: any, labelHeight: any, point: any) {
          return {
            x: this.chart.chartWidth - 200,
            y: this.chart.chartHeight - 200,
          };
        },
      };
    }

    private init(
        userPopulated: IUserPopulated,
        commercialCalendarList: ICommercialCalendar[]): void {
      this.userPopulated = userPopulated;
      this.commercialCalendarList = commercialCalendarList;
      this.currentCycle = this.commercialCalendarService.getCurrentCycleForced(
          this.commercialCalendarList);

      if (this.currentCycle) {

        this.currentCycleNumber = this.commercialCalendarService.getCycleNumber(
            this.currentCycle);
        this.currentYearName = this.commercialCalendarService.getCurrentYearName(
            this.currentCycle);
        this.lastYearName = this.commercialCalendarService.getLastYearName(
            this.currentCycle);

        //Show Prime Cycle Current
        this.currentPrime = this.primeService.getCurrentPrimeById(
            this.userPopulated.primeList, this.currentCycle, 4);
        this.amountWinPrimeArrayCurrentYear = this.primeService.getAmountWinPrimeArray(
            this.userPopulated.primeList, this.currentYearName, 4);
        this.amountWinPrimeArrayLastYear = this.primeService.getAmountWinPrimeArray(
            this.userPopulated.primeList, this.lastYearName, 4);
        this.amountWinPrimeChartOptions = this.loadOptionsCharts(
            this.amountWinPrimeArrayCurrentYear,
            this.amountWinPrimeArrayLastYear, '€', 'Montant gagné');

        //Show Prime Trimestre Current
        this.titleCurrentQuarter = this.getTitleByQuarterName(
            this.currentCycle.quarterName);
        this.numberRecruePrimeCurrentQuarter = this.primeService.getSumAttributQuarterly(
            this.userPopulated.primeList, this.currentCycle.quarterName,
            'numberRecruePrime', 5);
        this.numberRecrueValidPrimeCurrentQuarter = this.primeService.getSumAttributQuarterly(
            this.userPopulated.primeList, this.currentCycle.quarterName,
            'numberRecrueValidPrime', 5);
        this.amountWinPrimeCurrentQuarter = this.primeService.getSumAmountWinPrimeQuarterly(
            this.userPopulated.primeList, this.currentCycle.quarterName, 5);
        this.recrueValidPrimeArrayCurrentYear = this.primeService.getRecrueValidPrimeArray(
            this.userPopulated.primeList, this.currentYearName, 5);
        this.recrueValidPrimeArrayLastYear = this.primeService.getRecrueValidPrimeArray(
            this.userPopulated.primeList, this.lastYearName, 5);
        this.recrueValidPrimeChartOptions = this.loadOptionsCharts(
            this.recrueValidPrimeArrayCurrentYear,
            this.recrueValidPrimeArrayLastYear, '', 'Recrue');

        //Show Prime Trimestre Last
        this.lastQuaterName = this.commercialCalendarService.getLastQuarterName(
            this.currentCycle);
        this.titleLastQuarter = this.getTitleByQuarterName(this.lastQuaterName);
        this.numberRecruePrimeLastQuarter = this.primeService.getSumAttributQuarterly(
            this.userPopulated.primeList, this.lastQuaterName,
            'numberRecruePrime', 5);
        this.numberRecrueValidPrimeLastQuarter = this.primeService.getSumAttributQuarterly(
            this.userPopulated.primeList, this.lastQuaterName,
            'numberRecrueValidPrime', 5);
        this.amountWinPrimeLastQuarter = this.primeService.getSumAmountWinPrimeQuarterly(
            this.userPopulated.primeList, this.lastQuaterName, 5);
      }

    }

    public exportPrimeRecruitment(division, sector) {
      this.exportsService.exportPrimeRecruitment(division, sector);
    }

    public exportPrimeRecruitmentQuarter(division, sector) {
      this.exportsService.exportPrimeRecruitmentQuarter(division, sector);
    }

    public getTitleByQuarterName(quarterName: string) {
      var quarterIndex = parseInt(quarterName.substring(1, 2));
      switch (quarterIndex) {
        case 1 :
          return 'T1 - (P1,P2,P3)';
        case 2 :
          return 'T2 - (P4,P5,P6)';
        case 3 :
          return 'T3 - (P7,P8,P9)';
        case 4 :
          return 'T4 - (P10,P11,P12)';
      }
    }

    /**
     * Redraw the chart
     *
     * @param arrayCurrentYear
     * @param arrayLastYear
     */
    private loadOptionsCharts(
        arrayCurrentYear: number[], arrayLastYear: number[], suffix: string,
        serieName: string) {
      return [
        {
          data: arrayLastYear,
          seriesName: 'L\'année dernière',
          fillColor1: [0, 'rgba(255, 183, 112, 0.1)'],
          fillColor2: [1, 'rgba(255, 183, 112, 0.9)'],
          color: '#ffb266',
          valueSuffix: suffix,
          colorLabel: 'orange',
          tooltip: {
            pointFormat: '<tr><td>Référentiel*: &nbsp&nbsp</td><td style="width: 10px"></td>' +
            '<td style="text-align: right; border: 2px solid {series.color}; padding :5px; background-color: white;"><b>{point.y}' +
            suffix + '</b></td></tr><tr style="height: 10px"></tr>',
          },
        },
        {
          data: arrayCurrentYear,
          seriesName: 'Cette année',
          fillColor1: [0, 'rgba(142, 233, 251, 0.4)'],
          fillColor2: [1, 'rgba(142, 233, 251, 0.9)'],
          color: '#82ddff',
          valueSuffix: suffix,
          colorLabel: 'blue',
          tooltip: {
            pointFormat: '<tr><td>' + serieName +
            ': &nbsp&nbsp</td><td style="width: 10px"></td>' +
            '<td style="text-align: right; border: 2px solid {series.color}; padding :5px; background-color: white;"><b>{point.y}' +
            suffix + '</b></td></tr><tr style="height: 10px"></tr>',
          },
        },
      ];
    }

    /**
     * To display or hide chart of commission
     * @param label
     */
    public toggleChartDisplaying(label: string) {
      this.chartIsDisplayed[label] = !this.chartIsDisplayed[label];
    }

    /**
     * get if the Chart is displayed
     * @returns {null|boolean}
     */
    public displayHighcharts(): boolean {
      return ((this.amountWinPrimeArrayCurrentYear &&
          this.amountWinPrimeArrayCurrentYear.length != 0) ||
          (this.recrueValidPrimeArrayCurrentYear &&
              this.recrueValidPrimeArrayCurrentYear.length != 0));
    }

  }
}

appDashboard.controller('DashboardManagerPrimeRecruitmentController',
    controllers.DashboardManagerPrimeRecruitmentController);
