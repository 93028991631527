/// <reference path="../appDashboard.ts" />
var myLevelRemunerationBlock = function () {
  return {
    restrict: 'E',
    replace: false,
    scope: {
      promiseUserPopulated: '=',
    },
    templateUrl: '/templates/my-level-remuneration-block.html',
    controller: 'DashboardMyLevelRemunerationBlockController as dashboardMyLevelRemunerationBlockController',

  };
};

appDashboard.directive("myLevelRemunerationBlock", [myLevelRemunerationBlock]);
