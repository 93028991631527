/// <reference path="../appDashboard.ts" />
module controllers {

  export class DashboardMainController implements angular.IController {

    $onInit = () => { };
    private $scope: ng.IScope;
    private $state: ng.ui.IStateService;

    public static $inject = [
      '$scope',
      '$state',
    ];

    constructor($scope: ng.IScope, $state: ng.ui.IStateService) {
      this.$scope = $scope;
      this.$state = $state;
    }
  }
}

appDashboard.controller('DashboardMainController',
    controllers.DashboardMainController);
