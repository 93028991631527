module controllers {

  import IUserPopulated             = models.IUserPopulated;
  import IUsersService              = services.IUsersService;
  import IUser                      = models.IUser;
  import ICommercialCalendarService = services.ICommercialCalendarsService;
  import ICommercialCalendar        = models.ICommercialCalendar;
  import IExportsService            = services.IExportsService;
  import IP2RService                = services.IP2RService;

  export class DashboardManagerSectionController implements angular.IController {

    $onInit = () => { };
    private $scope: any;
    private $state: ng.ui.IStateService;
    private usersService: IUsersService;
    private commercialCalendarService: ICommercialCalendarService;
    private p2RService: IP2RService;
    private $q: ng.IQService;
    public userPopulated: IUserPopulated;
    public promiseUserPopulated: any;
    public promiseCommercialCalendar: any;

    public static $inject = [
      '$scope',
      '$state',
      'UsersService',
      'CommercialCalendarsService',
      'P2RService',
      '$q',
    ];

    /**
     *
     * @param $scope
     * @param $state
     * @param usersService
     * @param commercialCalendarService
     * @param p2RService
     */
    constructor(
        $scope: any,
        $state: ng.ui.IStateService,
        usersService: IUsersService,
        commercialCalendarService: ICommercialCalendarService,
        p2RService: IP2RService,
        $q: ng.IQService) {

      this.$scope = $scope;
      this.$state = $state;
      this.usersService = usersService;
      this.commercialCalendarService = commercialCalendarService;
      this.p2RService = p2RService;
      this.$q = $q;

      this.$q.all(
          [$scope.promiseUserPopulated, $scope.promiseCommercialCalendar]).
          then(function(data: any) {
            this.init(data[0], data[1]);
          }.bind(this));

    }

    private init(
        userPopulated: IUserPopulated,
        commercialCalendarList: ICommercialCalendar[]): void {
      this.userPopulated = userPopulated;
    }

  }
}

appDashboard.controller('DashboardManagerSectionController',
    controllers.DashboardManagerSectionController);
