/// <reference path="../appDashboard.ts" />7
module services {
  'use strict';
  import IPromise = angular.IPromise;
  import ITypologyActivity = models.ITypologyActivity;
  import ICommercialCalendar = models.ICommercialCalendar;
  import ICommonService = services.ICommonService;

  export interface ITypologyActivityService {
    getTypologyActivityCurrent(
        typologyActivityList: ITypologyActivity[],
        currentCycle: ICommercialCalendar): ITypologyActivity;

    getSumTypologyAttributes(listFiltered: ITypologyActivity[]): ITypologyActivity;

    getRecrueArray(
        typologyActivityList: ITypologyActivity[], yearName: string,
        typology: string): any[];

    getActiveArray(
        typologyActivityList: ITypologyActivity[], yearName: string,
        typology: string): any[];

    getFilteredByOptions(
        typologyActivityList: ITypologyActivity[], typology: string,
        currentCycle: string): ITypologyActivity;

    getGetGlobalActivity(periodeName: string): ng.IPromise<any>
  }

  export class TypologyActivityService implements ITypologyActivityService {

    private $http: ng.IHttpService;
    private $q: ng.IQService;
    private commonService: ICommonService;
    private $filter: ng.IFilterService;
    private base_url: string = '/api/typologyActivity';

    public static $inject = [
      '$http',
      '$q',
      'CommonService',
      '$filter',
    ];

    constructor(
        $http: ng.IHttpService, $q: ng.IQService, commonService: ICommonService,
        $filter: ng.IFilterService) {
      this.commonService = commonService;
      this.$filter = $filter;
    }

    public getTypologyActivityCurrent(
        typologyActivityList: ITypologyActivity[],
        currentCycle: ICommercialCalendar): ITypologyActivity {
      let selectedTypologyActivity: ITypologyActivity = null;
      angular.forEach(typologyActivityList,
          function(current: ITypologyActivity) {
            if (current.periode === currentCycle.periodeName) {
              selectedTypologyActivity = current;
            }
          });
      return selectedTypologyActivity;
    }

    public getSumTypologyAttributes(listFiltered: ITypologyActivity[]): ITypologyActivity {
      let activityStructureList = {
        totalDemission: 0,
        totalRecrue: 0,
        totalEffectif: 0,
        totalWatched: 0,
        activeNumber: 0,
        turnOver: 0,
      };

      angular.forEach(listFiltered, function(current) {
        activityStructureList.totalDemission += current.totalDemission;
        activityStructureList.totalRecrue += current.totalRecrue;
        activityStructureList.totalEffectif += current.totalEffectif;
        activityStructureList.totalWatched += current.totalWatched;
        activityStructureList.activeNumber += current.activeNumber;
        activityStructureList.turnOver += current.turnOver;
      });

      return activityStructureList;
    }

    public getFilteredByOptions(
        typologyActivityList: ITypologyActivity[], typology: string,
        currentCycle: string): ITypologyActivity {

      let options = {
        typology: typology === 'ALL' ? '!!' : typology,
        periode: currentCycle,
      };

      return this.getSumTypologyAttributes(
          this.$filter('filter')(typologyActivityList, options));
    }

    /**
     *
     * @param list
     * @param yearName
     * @param getterValue
     * @returns {Array}
     */
    private getYearsArray(
        list: any[], yearName: any, typology: any,
        getterValue: (current: any) => void): any[] {

      if (!list || !yearName || !typology || !getterValue) {
        console.error(list, yearName, typology, getterValue);
        throw new Error('Arg missing');
      }

      let resArray: any = {
        'P01': 0,
        'P02': 0,
        'P03': 0,
        'P04': 0,
        'P05': 0,
        'P06': 0,
        'P07': 0,
        'P08': 0,
        'P09': 0,
        'P10': 0,
        'P11': 0,
        'P12': 0,
      };

      angular.forEach(resArray, function(value: any, key: any) {
        var listFiltered = this.getFilteredByOptions(list, typology, key + '-' +
            yearName);
        resArray[key] = getterValue(listFiltered);
      }.bind(this));

      var sum: number = 0;
      var res: any[];
      res = [];
      angular.forEach(resArray, function(value: any, key: any) {
        sum += value;
        res.push(value);
      });
      if (sum !== 0) {
        return res;
      } else {
        return [];
      }
    }

    /**
     * Get the array of active values
     *
     * @param typologyActivityList
     * @param yearName
     * @param typology
     * @returns {any[]}
     */
    public getActiveArray(
        typologyActivityList: ITypologyActivity[], yearName: string,
        typology: string): any[] {
      return this.getYearsArray(typologyActivityList, yearName, typology,
          function(typologyActivity: any) {
            return typologyActivity.activeNumber;
          }.bind(this),
      );
    }

    /**
     * Get the array of recrue values
     *
     * @param typologyActivityList
     * @param yearName
     * @param typology
     * @returns {any[]}
     */
    public getRecrueArray(
        typologyActivityList: ITypologyActivity[], yearName: string,
        typology: string): any[] {
      return this.getYearsArray(typologyActivityList, yearName, typology,
          function(typologyActivity: any) {
            return typologyActivity.totalRecrue;
          }.bind(this),
      );
    }

    /**
     * Get the number of active and the number of effective from all database line
     * The result is split by typology
     * @returns {ng.IPromise<any>}
     */
    public getGetGlobalActivity(periodeName: string): ng.IPromise<any> {
      return this.commonService.httpGet(this.base_url + '/getGlobalActivity', {
        periodeName,
      });
    }

  }
}
appDashboard.service('TypologyActivityService',
    services.TypologyActivityService);
