/// <reference path="../appDashboard.ts" />7
module services {
    'use strict';
    import IPromise = angular.IPromise;
    import ICommission = models.ICommission;
    import IObjective = models.IObjective;
    export interface IObjectivesService {
    }
    export class ObjectivesService implements IObjectivesService {

        private $http:ng.IHttpService;
        private $q:ng.IQService;
        private commonService:ICommonService;

        public static $inject = [
            '$http',
            '$q',
            'CommonService'
        ];

        constructor($http:ng.IHttpService, $q:ng.IQService, commonService:ICommonService) {
            this.commonService = commonService;
        }





    }
}
appDashboard.service("ObjectivesService", services.ObjectivesService);


