/// <reference path="../appDashboard.ts" />
module controllers {

  import ICommercialCalendarService = services.ICommercialCalendarsService;
  import ICommissionsService = services.ICommissionsService;
  import IUsersService = services.IUsersService;
  import IUserPopulated = models.IUserPopulated;
  import ICommercialCalendar = models.ICommercialCalendar;
  import IDisplayService = services.IDisplayService;

  declare var Promise: any;
  declare var jQuery: any;

  export class DashboardCommissionBlockController implements angular.IController {
    $onInit = () => { };

    private scope: any;
    private commercialCalendarService: ICommercialCalendarService;
    private activitiesService: IActivitiesService;
    private commissionsService: ICommissionsService;
    private displayService: IDisplayService;
    private usersService: IUsersService;
    private $q: ng.IQService;

    public promiseUserPopulated: any;
    public promiseCommercialCalendar: any;
    public currentActivity: any;
    public currentCycleNumber: number;
    public currentCycleForced: any;
    public personalCommission: any;
    public n1P2RCommissionObject: any;
    public n2P2RCommissionObject: any;
    public n1ForcedCommissionObject: any;
    public n2ForcedCommissionObject: any;
    public totalCommission: any;
    public chartIsDisplayed: any;
    public userPopulated: IUserPopulated;
    public isSeen: boolean;
    public displayForcedCommissionsBlock: boolean;
    public displayPalierBlock: boolean;
    public limitNumber: number;
    public limitNumber_turnover: number;
    public limitGainNumber: number;
    public params: any;
    public directCommissionChartOptions: any;
    public indirectCommissionChartOptions: any;
    public forcedCommissionChartOptions: any;
    public tooltipOption: any;
    public isDestroy: boolean;
    public turnoverCurrentPeriod: number;

    public static $inject = [
      '$scope',
      'CommercialCalendarsService',
      'ActivitiesService',
      'CommissionsService',
      'DisplayService',
      'UsersService',
      'limitNumber',
      'limitGainNumber',
      '$q',
    ];

    /**
     *
     * @param $scope
     * @param commercialCalendarService
     * @param activitiesService
     * @param commissionsService
     * @param displayService
     * @param usersService
     * @param limitNumber
     * @param limitGainNumber
     */
    constructor($scope: any,
                commercialCalendarService: ICommercialCalendarService,
                activitiesService: IActivitiesService,
                commissionsService: ICommissionsService,
                displayService: IDisplayService, usersService: IUsersService,
                limitNumber: any, limitGainNumber: any, $q: ng.IQService) {
      this.scope = $scope;
      this.limitNumber = limitNumber;
      this.limitNumber_turnover = 2;
      this.turnoverCurrentPeriod = 0;
      this.limitGainNumber = limitGainNumber;
      this.commercialCalendarService = commercialCalendarService;
      this.activitiesService = activitiesService;
      this.displayService = displayService;
      this.usersService = usersService;
      this.commissionsService = commissionsService;
      this.chartIsDisplayed = {
        directCommission: false,
        indirectCommission: false,
        forcedCommission: false,

      };
      this.isSeen = false;
      this.isDestroy = false;
      this.params = {
        type: this.scope.type ? this.scope.type : null,
        // Value to determinate if array-commission is display in a new tab or in dashboard
        showGoTab: this.scope.showGoTab ? this.scope.showGoTab : false,
      };
      this.$q = $q;

      this.$q.all(
          [$scope.promiseUserPopulated, $scope.promiseCommercialCalendar]).
          then(function(data: any) {
            this.init(data[0], data[1]);
          }.bind(this));

      $scope.$on('$destroy', function iVeBeenDismissed() {
        this.isDestroy = true;
      }.bind(this));
    }

    /**
     *
     * @param userPopulated
     * @param commercialCalendarList
     */
    private init(
        userPopulated: IUserPopulated,
        commercialCalendarList: ICommercialCalendar[]) {
      this.userPopulated = userPopulated;
      this.displayForcedCommissionsBlock = this.displayService.displayForcedCommissionsBlock(
          userPopulated);
      this.displayPalierBlock = this.displayService.displayPalierBlock(
          userPopulated);
      this.currentCycleForced = this.commercialCalendarService.getCurrentCycleForced(
          commercialCalendarList);
      this.currentCycleNumber = this.commercialCalendarService.getCycleNumber(
          this.currentCycleForced);

      this.commissionsService.getByMatricule(userPopulated.matricule).
          then(function(commissionList) {
            this.personalCommission = this.commissionsService.getPersonalCommission(
                commissionList);
            this.n1P2RCommissionObject = this.commissionsService.getSubCommissionListObject(
                commissionList, 'N1', 'P2R');
            this.n2P2RCommissionObject = this.commissionsService.getSubCommissionListObject(
                commissionList, 'N2', 'P2R');
            this.n1ForcedCommissionObject = this.commissionsService.getSubCommissionListObject(
                commissionList, 'N1', 'forced');
            this.n2ForcedCommissionObject = this.commissionsService.getSubCommissionListObject(
                commissionList, 'N2', 'forced');
            this.totalCommission = this.commissionsService.getTotalCommission(
                this.n1P2RCommissionObject, this.n2P2RCommissionObject,
                this.n1ForcedCommissionObject, this.n2ForcedCommissionObject);

          }.bind(this));

      this.activitiesService.getByMatricule(userPopulated.matricule).
          then(function(activityList) {
            var turnoverCurrentQuarter: any = this.activitiesService.getSumTurnoverCurrentQuarterly(
                activityList, this.currentCycleForced.quarterName);
            this.circleLaunch(turnoverCurrentQuarter,
                this.userPopulated.objective ? this.userPopulated.objective.quarterlyTurnoverRemainsToBeDone : 0);
            var arrayTotalCommission = this.activitiesService.getSumMultipleCommissionArray(
                activityList,
                ['direct', 'indirectN1P2R', 'indirectN2P2R', 'indirectForced'],
                this.currentCycleForced.yearName);
            var arrayDirectCommission = this.activitiesService.getCommissionArray(
                activityList, 'direct', this.currentCycleForced.yearName);
            var arrayIndirectCommission = this.activitiesService.getSumMultipleCommissionArray(
                activityList, ['indirectN1P2R', 'indirectN2P2R'],
                this.currentCycleForced.yearName);
            var arrayForcedCommission = this.activitiesService.getCommissionArray(
                activityList, 'indirectForced',
                this.currentCycleForced.yearName);
            this.directCommissionChartOptions = this.loadOptionsCharts(
                arrayTotalCommission, arrayDirectCommission,
                'Commissions directes');
            this.indirectCommissionChartOptions = this.loadOptionsCharts(
                arrayTotalCommission, arrayIndirectCommission,
                'Commissions indirectes');
            this.forcedCommissionChartOptions = this.loadOptionsCharts(
                arrayTotalCommission, arrayForcedCommission,
                'Commissions forcées');

            this.currentActivity = this.activitiesService.getCurrentActivity(activityList, this.currentCycleForced);
            this.turnoverCurrentPeriod = this.activitiesService.getTurnover(this.currentActivity, 'n1n2', '')

            this.tooltipOption = {
              shared: true,
              borderWidth: 0,
              valueSuffix: ' €',
            };

            if (this.scope.$root.$$phase != '$apply' &&
                this.scope.$root.$$phase != '$digest') {
              this.scope.$apply();
            }

          }.bind(this));
    }

    /**
     * Redraw the chart
     *
     * @param arrayCurrentYear
     * @param arrayLastYear
     */
    private loadOptionsCharts(
        arrayCurrentYear: number[], arrayLastYear: number[], name: string) {

      return [
        {
          data: arrayCurrentYear,
          seriesName: 'Commissions Directes + Indirectes',
          fillColor1: [0, 'rgba(165,5,224,0.6)'],
          fillColor2: [1, 'rgba(226,191,255,0.6)'],
          color: '#bc54e6',
          valueSuffix: '€',
          colorLabel: 'violet',

        },
        {
          data: arrayLastYear,
          seriesName: name,
          fillColor1: [0, 'rgba(251,135,170,0.83)'],
          fillColor2: [1, 'rgba(224,0,38,0.83)'],
          color: '#e1052b',
          valueSuffix: '€',
          colorLabel: 'rouge',
        },
      ];
    }

    /**
     * display the circle
     *
     * @param turnoverCurrentQuarter
     * @param turnoverObjective
     */
    private circleLaunch(turnoverCurrentQuarter, turnoverObjective) {
      jQuery(window).scroll(function() {
        if (!this.isDestroy) {
          var hWindow: any = jQuery(window).height();
          var heightScroll: any = jQuery(window).scrollTop();
          if (!this.isSeen && jQuery('#courbe1')) {
            if (jQuery('#courbe1').offset()) {
              var heightElement1: any = (jQuery('#courbe1').offset().top) -
                  hWindow;
            }
            if (heightElement1 && heightScroll >= heightElement1) {
              this.isSeen = true;
              var cerclecomplet1: number = turnoverCurrentQuarter +
                  turnoverObjective;
              var valeurcerclefinal1: number = turnoverCurrentQuarter;
              var degres1: number;
              if (cerclecomplet1 === 0) {
                degres1 = 1;
              } else {
                degres1 = valeurcerclefinal1 / cerclecomplet1;
              }
              jQuery('#courbe1').circleChart({
                size: 150,
                deg: 360 * degres1,
                startAngle: -90,
                counterclockwise: false,
                color: '#55409c',
                widthRatio: 0.2,
                backgroundColor: '#e6ecf3',
                background: false,
                lineCap: 'square',
                cAngle: 0.5,
                backgroundFix: false,
                rad: false,
                text: '0%',
                textCenter: true,
                onDraw: function(el, circle) {
                  if (circle.settings.counterclockwise) {
                  } else {
                  }
                  jQuery('.circleChart_text', el).
                      html(Math.round(degres1 * 100) + '%');
                },
              });
            }

          }
        }
      }.bind(this));

    }

    /**
     * To display or hide chart of commission
     * @param label
     */
    public toggleChartDisplaying(label: string) {
      this.chartIsDisplayed[label] = !this.chartIsDisplayed[label];
    }

    /**
     * if the current use can see indirect commission
     *
     * @returns {boolean}
     */
    public displayIndirectCommission(): boolean {
      if (this.userPopulated) {
        return this.displayService.displayIndirectCommission(
            this.userPopulated);
      } else {
        return false;
      }
    }

    public seeinfo() {
      if (this.userPopulated) {
        return this.usersService.isTeamLead(this.userPopulated);
      } else {
        return false;
      }
    }

  }
}

appDashboard.controller('DashboardCommissionBlockController',
    controllers.DashboardCommissionBlockController);
