module controllers {

  import IUserPopulated             = models.IUserPopulated;
  import IUsersService              = services.IUsersService;
  import IUser                      = models.IUser;
  import ICommercialCalendarService = services.ICommercialCalendarsService;
  import ICommercialCalendar        = models.ICommercialCalendar;
  import IExportsService            = services.IExportsService;
  import IP2RService                = services.IP2RService;
  import IEventsService             = services.EventsService;
  import IDisplaysArrayService      = services.IDisplaysArrayService;

  export class DashboardManagerTeamLeadersController implements angular.IController {

    $onInit = () => { };
    private $scope: any;
    private $state: ng.ui.IStateService;
    private usersService: IUsersService;
    private commercialCalendarService: ICommercialCalendarService;
    private p2RService: IP2RService;
    private $q: ng.IQService;
    public userPopulated: IUserPopulated;
    public commercialCalendarList: ICommercialCalendar[];
    public subUserList: IUserPopulated[];
    public userSubListParams: any;
    public currentCycle: ICommercialCalendar;
    public currentCycleShortName: string;
    public exportsService: IExportsService;
    private eventsService: IEventsService;
    private displaysArrayService: IDisplaysArrayService;
    public currentPage: number;
    public limitNumber: number;
    public displayNewTab: boolean;
    public currentCycleNumber: number;
    public numberOfItemByPage: number;
    public orderBySens: number;
    public orderByLabel: string;
    public numberOfPages: number;
    public dataPromise: any;
    public tableHeaders: any;
    public populateDataArray: any;
    public datas: any;

    public static $inject = [
      '$scope',
      '$state',
      'UsersService',
      'CommercialCalendarsService',
      'ExportsService',
      'EventsService',
      'DisplaysArrayService',
      'P2RService',
      '$q',
    ];

    /**
     *
     * @param $scope
     * @param $state
     * @param usersService
     * @param commercialCalendarService
     * @param exportsService
     * @param eventsService
     * @param displaysArrayService
     * @param p2RService
     * @param $q
     */
    constructor($scope: any,
                $state: ng.ui.IStateService,
                usersService: IUsersService,
                commercialCalendarService: ICommercialCalendarService,
                exportsService: IExportsService,
                eventsService: IEventsService,
                displaysArrayService: IDisplaysArrayService,
                p2RService: IP2RService, $q: ng.IQService) {

      this.$scope = $scope;
      this.$state = $state;
      this.usersService = usersService;
      this.commercialCalendarService = commercialCalendarService;
      this.exportsService = exportsService;
      this.p2RService = p2RService;
      this.$q = $q;
      this.eventsService = eventsService;
      this.displaysArrayService = displaysArrayService;

      this.userSubListParams = {
        expression: 'inactivesWeekNumber',
        reverse: true,
      };
      this.displayNewTab = this.$scope.displayNewTab
          ? this.$scope.displayNewTab
          : false;

      this.limitNumber = 0;
      this.numberOfItemByPage = this.displayNewTab === true ? 0 : 10;
      this.currentPage = 0;
      this.orderBySens = 1;
      this.orderByLabel = null;
      this.datas = [];
      this.$q.all(
          [$scope.promiseUserPopulated, $scope.promiseCommercialCalendar]).
          then(function(data: any) {
            this.dataPromise = data;
            this.initVariable(this.dataPromise);
          }.bind(this));

      this.$scope.$on(this.eventsService.getEventBy('goto'), function(event, data) {
        this.setPage(data)
      }.bind(this));
      this.$scope.$on(this.eventsService.getEventBy('filter'), function(event, data) {
        this.setOrderBy(data.sort, data.sens)
      }.bind(this));

      this.tableHeaders = [
        {label: "Suivi", key: "follow", sort: false, class: this.displaysArrayService.getClassBy('both')},
        {label: "Nom Prénom", key: "lastName", sort: true, class: this.displaysArrayService.getClassBy('both')},
        {
          label: "Chiffre d'affaire",
          key: "turnOverperformanceIndicator",
          sort: true,
          class: this.displaysArrayService.getClassBy('both')
        },
        {
          label: "Recrutement",
          key: "recrueOverperformanceIndicator",
          sort: true,
          class: this.displaysArrayService.getClassBy('both')
        },
      ];
    }

    private initVariable(data) {
      this.usersService.getListTeamLeadersOfManager(data[0], this.orderByLabel,
          this.orderBySens, this.numberOfItemByPage,
          this.currentPage * this.numberOfItemByPage).then(
          function(teamLeaders: any) {
            this.numberOfPages = Math.ceil(teamLeaders.count /
                this.numberOfItemByPage);
            this.init(teamLeaders.list, data[0], data[1]);
          }.bind(this),
          function(err: any) {
            console.error(err);
          },
      );
    }

    /**
     *
     * @param teamLeaders
     * @param commercialCalendarList
     */
    private init(
        teamLeaders: IUserPopulated[], userPopulated: IUserPopulated,
        commercialCalendarList: ICommercialCalendar[]) {
      this.currentCycle = this.commercialCalendarService.getCurrentCycleForced(
          commercialCalendarList);
      this.currentCycleNumber = this.commercialCalendarService.getCycleNumber(
          this.currentCycle);
      this.userPopulated = userPopulated;
      this.commercialCalendarList = commercialCalendarList;
      if (!this.currentCycle) {
        console.error('Current Cycle not defined');
      } else {
        this.currentCycleShortName = this.commercialCalendarService.getShortNameCycle(
            this.currentCycle);
      }
      this.subUserList = this.usersService.setPropertiesPopulated(teamLeaders,
          this.currentCycle, this.commercialCalendarList);

      this.datas = [];
      teamLeaders.forEach((e) => {
        this.datas.push({
          follow: {
            display: this.displaysArrayService.getDisplayBy('gototxtless'),
            matricule: e.matricule,
            class: this.displaysArrayService.getClassBy('both')
          },
          lastName: {
            display: this.displaysArrayService.getDisplayBy('showmoreparent'),
            value: e.lastName + " " + e.firstName,
            class: this.displaysArrayService.getClassBy('both'),
            index: 0
          },
          turnOverperformanceIndicator: {display: this.displaysArrayService.getDisplayBy('graduate'), value: e.turnOverperformanceIndicator, class: this.displaysArrayService.getClassBy('both')},
          recrueOverperformanceIndicator: {display: this.displaysArrayService.getDisplayBy('graduate'), value: e.recrueOverperformanceIndicator, class: this.displaysArrayService.getClassBy('both')},
          userExpand: {0: e},
          expanded: {0: false}
        });
      });
      this.$scope.$broadcast(this.eventsService.getEventBy('sort'), {sort: this.orderByLabel, sortSens: this.orderBySens});
      this.$scope.$broadcast(this.eventsService.getEventBy('data'), this.datas);
      this.$scope.$broadcast(this.eventsService.getEventBy('number'), this.numberOfPages);

      setTimeout(function() {
        $('.slider .cursor').each(function() {
          $(this).css('left', $(this).attr('perf') + '%');
        });

        $('.tableau-inactives-v2 .toggle-detail').click(function(e) {
          e.preventDefault();
          $(this).parent('tr').next('detail').toggleClass('active');
        });

        $('.divisions-zones-table').basictable({
          breakpoint: 1410,
        });
        $('#tab_primes').basictable({
          breakpoint: 768,
        });

        $('.tooltip').tooltip({
          position: {my: 'left+22 top-45', at: 'left top+15'},
        });
      }.bind(this));

    }

    public goToDashboard(matricule: string): void {
      var url = this.$state.href('seeDashboard', {matricule: matricule});
      window.open(url,'_blank');
    }

    /**
     * set values of selected params to orderBy Filter
     *
     * @param expression
     * @param reverse
     */
    public setOrderBy(orderByLabel: string, sens: number) {
      this.orderBySens = sens;
      this.orderByLabel = orderByLabel;
      this.initVariable(this.dataPromise);
    }

    /**
     * to export data in excel
     *
     */
    public exportExcel() {
      var leaderZoneType;
      if (this.userPopulated.profile === 'DR') {
        leaderZoneType = 'D';
      } else if (this.userPopulated.profile === 'DD') {
        leaderZoneType = 'S';
      } else if (this.userPopulated.profile === 'ZM') {
        leaderZoneType = 'S';
      }
      this.exportsService.exportTeamManger(this.userPopulated.matricule,
          this.userPopulated.GSDRValue, leaderZoneType);
    }

    /**
     * to see data in new tab
     *
     */
    public goTabView() {
      var a = document.createElement('a');
      a.href = '#!/tab/leaders/' + this.userPopulated.matricule + '?filter=';
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
    }

    public previousPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
        this.initVariable(this.dataPromise);
      }
    }

    public nextPage() {
      if (this.currentPage < this.numberOfPages - 1) {
        this.currentPage++;
        this.initVariable(this.dataPromise);
      }
    }

    public setPage(index: number) {
      this.currentPage = index;
      this.initVariable(this.dataPromise);
    }

    /**
     * Get number
     *
     * @param num
     * @return {any[]}
     */
    public getNumber = function(num: number): any[] {
      return new Array(num);
    };

  }
}

appDashboard.controller('DashboardManagerTeamLeadersController',
    controllers.DashboardManagerTeamLeadersController);
