/// <reference path="../appDashboard.ts" />7
module enums {
    'use strict';
    export enum  Level {
        n0,
        n1,
        n2

    }
}


