/// <reference path="../appDashboard.ts" />
var turnoverBlock = function () {
    return {
        restrict: 'E',
        replace: false,
        scope: {
            promiseUserPopulated: "=",
            promiseCommercialCalendar: "="
        },
        templateUrl: '/templates/turnoverBlock.html',
        controller: 'DashboardTurnoverBlockController as turnoverBlockController',
    };
};

appDashboard.directive("turnoverBlock", turnoverBlock);
