/// <reference path="../appDashboard.ts" />
module controllers {

  import IUsersService = services.IUsersService;
  import ICommercialCalendarService = services.ICommercialCalendarsService;
  import ICommercialCalendar = models.ICommercialCalendar;
  import IUserPopulated = models.IUserPopulated;
  import IDisplayService = services.IDisplayService;
  import IEventsService = services.EventsService;
  import IExportsService = services.ExportsService;
  import IDisplaysArrayService = services.IDisplaysArrayService;

  declare var Promise: any;

  export class DashboardCentralBlockController implements angular.IController {
    $onInit = () => { };

    private $scope: any;
    private $state: any;
    private usersService: IUsersService;
    private exportsService: IExportsService;
    private eventsService: IEventsService;
    private displaysArrayService: IDisplaysArrayService;
    private commercialCalendarService: ICommercialCalendarService;
    private $q: ng.IQService;

    public promiseUserPopulated: any;
    public promiseCommercialCalendar: any;
    public userPopulated: IUserPopulated;
    public currentCycleForced: ICommercialCalendar;
    public userCentralList: any[];
    public initCount: number;
    public userCentralCount: number;
    public sort: string;
    public sortSens: number;
    public filter: any;
    public currentPage: number;
    public numberOfItemByPage: number;
    public numberOfPages: number;
    private tableHeaders: any;

    public static $inject = [
      '$scope',
      '$state',
      'UsersService',
      'ExportsService',
      'EventsService',
      'DisplaysArrayService',
      'CommercialCalendarsService',
      'limitNumber',
      'limitGainNumber',
      '$q',
    ];

    /**
     *
     * @param $scope
     * @param $state
     * @param usersService
     * @param exportsService
     * @param eventsService
     * @param displaysArrayService
     * @param commercialCalendarService
     * @param limitNumber
     * @param limitGainNumber
     * @param $q
     */
    constructor($scope: any, $state: any, usersService: IUsersService,
                exportsService: IExportsService,
                eventsService: IEventsService,
                displaysArrayService: IDisplaysArrayService,
                commercialCalendarService: ICommercialCalendarService,
                limitNumber: number, limitGainNumber: number,
                $q: ng.IQService) {
      this.$scope = $scope;
      this.$state = $state;
      this.usersService = usersService;
      this.exportsService = exportsService;
      this.eventsService = eventsService;
      this.displaysArrayService = displaysArrayService;
      this.commercialCalendarService = commercialCalendarService;
      this.sort = null;
      this.sortSens = 1;
      this.numberOfItemByPage = 10;
      this.currentPage = 0;
      this.filter = {};
      this.$q = $q;
      this.$q.all(
          [$scope.promiseUserPopulated, $scope.promiseCommercialCalendar]).
          then(function(data: any) {
            this.init(data[0], data[1]);
          }.bind(this));

      this.$scope.$on(this.eventsService.getEventBy('goto'), function(event, data) {
        this.setPage(data)
      }.bind(this));
      this.$scope.$on(this.eventsService.getEventBy('filter'), function(event, data) {
        this.setOrderBy(data.sort, data.sens)
      }.bind(this));

      this.tableHeaders = [
        {label: "Suivi", key: "follow", sort: false, class: this.displaysArrayService.getClassBy('both')},
        {label: "Nom Prénom", key: "lastName", sort: true, class: this.displaysArrayService.getClassBy('desktop')},

        {label: "Nom", key: "lastNameMob", sort: false, class: this.displaysArrayService.getClassBy('mobile')},

        {label: "Profil", key: "profile", sort: true, class: this.displaysArrayService.getClassBy('desktop')},
        {label: "CA Période", key: "personalCACurrentPeriod", sort: true, class: this.displaysArrayService.getClassBy('desktop')},
        {label: "CA Référence", key: "caRef", sort: false, class: this.displaysArrayService.getClassBy('desktop')},

        {label: "Croissance CA", key: "growCa", sort: false, class: this.displaysArrayService.getClassBy('both')},
        {label: "Recruteuse", key: "parent", sort: false, class: this.displaysArrayService.getClassBy('both')}
      ];
    }

    /**
     *
     * @param userPopulated
     * @param commercialCalendarList
     */
    private init(
      userPopulated: IUserPopulated,
      commercialCalendarList: ICommercialCalendar[]): void {
      this.userPopulated = userPopulated;
      this.currentCycleForced = this.commercialCalendarService.getCurrentCycleForced(
          commercialCalendarList);
      this.updateUserFromCentral();
    }

    private updateUserFromCentral() {
      this.numberOfItemByPage = this.$scope.pagination === false
          ? 0
          : this.numberOfItemByPage;
      this.usersService.getUserFromCentral(this.userPopulated.matricule, {
        sort: this.sort,
        sortSens: this.sortSens,
        limit: this.numberOfItemByPage,
        offset: this.currentPage * this.numberOfItemByPage,
        filter: this.filter,
      }).then(
          function(data) {
            this.userCentralList = [];
            data.list.forEach((e) => {
              this.userCentralList.push({
                follow: {
                  display: this.displaysArrayService.getDisplayBy('gototxtless'),
                  matricule: e.matricule,
                  class: this.displaysArrayService.getClassBy('both')
                },
                lastName: {
                  display: this.displaysArrayService.getDisplayBy('showmoreparent'),
                  value: e.lastName + " " + e.firstName,
                  class: this.displaysArrayService.getClassBy('desktop'),
                  index: 0
                },
                lastNameMob: {
                  display: this.displaysArrayService.getDisplayBy('showmoreparent'),
                  value: e.lastName + " " + e.firstName,
                  class: this.displaysArrayService.getClassBy('mobile'),
                  index: 0
                },

                profile: {
                  display: this.displaysArrayService.getDisplayBy('default'),
                  value: e.profile,
                  class: this.displaysArrayService.getClassBy('desktop')
                },
                personalCACurrentPeriod: {
                  display: this.displaysArrayService.getDisplayBy('average'),
                  value: e.personalCACurrentPeriod,
                  class: this.displaysArrayService.getClassBy('desktop')
                },
                caRef: {
                  display: this.displaysArrayService.getDisplayBy('default'),
                  value: this.getRefCa(e),
                  class: this.displaysArrayService.getClassBy('desktop')
                },

                growCa: {
                  display: this.displaysArrayService.getDisplayBy('color'),
                  color: this.getGrowthUser(e) < 0 ? 'red' : 'green',
                  value: this.formatGrowth(this.getGrowthUser(e)),
                  class: this.displaysArrayService.getClassBy('both')
                },
                parent: {
                  display: this.displaysArrayService.getDisplayBy('showmoreparent'),
                  value: e.recruteur.lastName + " " + e.recruteur.firstName,
                  class: this.displaysArrayService.getClassBy('both'),
                  index: 1
                },
                userExpand: {0: e, 1: e.recruteur},
                expanded: {0: false, 1: false}
              })
            });
            this.userCentralCount = data.count;
            if (!this.initCount) {
              this.initCount = data.count;
            }
            this.numberOfPages = Math.ceil(data.count /
                this.numberOfItemByPage);

            this.$scope.$broadcast(this.eventsService.getEventBy('sort'), {sort: this.sort, sortSens: this.sortSens});
            this.$scope.$broadcast(this.eventsService.getEventBy('data'), this.userCentralList);
            this.$scope.$broadcast(this.eventsService.getEventBy('number'), this.numberOfPages);
          }.bind(this),
      );
    }

    private getRefCa(user) {
      if (user.nMinus1Activity) {
        return this.getFormatNumberEurosWithCommas(
            user.nMinus1Activity.personalTotalTurnover);
      } else {
        return this.getFormatNumberEurosWithCommas(0);
      }
    }

    private getFormatNumberEurosWithCommas(num) {
      if (!num) {
        num = 0;
      }
      return (num + '€').replace(/[.]/g, ',');
    }

    private getGrowthUser(user) {
      let growth;
      if (user.nMinus1Activity) {
        growth = this.getGrowth(user.personalCACurrentPeriod,
            user.nMinus1Activity.personalTotalTurnover);
      } else {
        growth = this.getGrowth(user.personalCACurrentPeriod, 0);
      }
      return growth;
    }

    private formatGrowth(growth) {
      return parseInt((growth * 100) + "") + '%';
    }

    private getGrowth(current, ref) {
      if (current && ref) {
        return ((parseFloat(current) - parseFloat(ref)) / parseFloat(ref));
      } else {
        if (parseInt(ref) === 0 && parseInt(current) === 0) {
          return 0;
        } else if (parseFloat(current) === 0) {
          return -1;
        } else if (parseFloat(ref) === 0) {
          return 1;
        } else {
          return 0;
        }
      }
    }

    public getConcernedStructure(user) {
      var CENTRAL_DIVISION = 'Division Centrale',
          CENTRAL_SECTOR = 'Secteur Central', CENTRAL_GROUPE = 'Groupe Central';
      if (user.divisionName == CENTRAL_DIVISION &&
          user.sectorName == CENTRAL_SECTOR &&
          user.groupName == CENTRAL_GROUPE) {
        return 'Groupe Central';
      } else if (user.divisionName == CENTRAL_DIVISION &&
          user.sectorName == CENTRAL_SECTOR &&
          user.groupName != CENTRAL_GROUPE) {
        return 'Secteur Central';
      } else if (user.divisionName == CENTRAL_DIVISION &&
          user.sectorName != CENTRAL_SECTOR &&
          user.groupName == CENTRAL_GROUPE) {
        return 'Groupe Central';
      } else if (user.divisionName == CENTRAL_DIVISION &&
          user.sectorName != CENTRAL_SECTOR &&
          user.groupName != CENTRAL_GROUPE) {
        return 'Division Centrale';
      } else if (user.divisionName != CENTRAL_DIVISION &&
          user.sectorName == CENTRAL_SECTOR &&
          user.groupName == CENTRAL_GROUPE) {
        return 'Groupe Central';
      } else if (user.divisionName != CENTRAL_DIVISION &&
          user.sectorName == CENTRAL_SECTOR &&
          user.groupName != CENTRAL_GROUPE) {
        return 'Secteur Central';
      } else if (user.divisionName != CENTRAL_DIVISION &&
          user.sectorName != CENTRAL_SECTOR &&
          user.groupName == CENTRAL_GROUPE) {
        return 'Groupe Central';
      } else if (user.divisionName != CENTRAL_DIVISION &&
          user.sectorName != CENTRAL_SECTOR &&
          user.groupName != CENTRAL_GROUPE) {
        return '';
      } else {
        return 'NO DEFINED';
      }

    }

    public setOrderBy(sort, sortSens) {
      this.sort = sort;
      this.sortSens = sortSens;
      this.updateUserFromCentral();
    }

    public exportExcel() {
      this.exportsService.exportCentral(this.userPopulated.matricule);
    }

    public goToDashboard(matricule: string): void {
      var url = this.$state.href('seeDashboard', {matricule: matricule});
      window.open(url,'_blank');
    }

    public filterModification(attributName, attibutValue) {
      this.currentPage = 0;
      this.filter[attributName] = attibutValue;
      this.updateUserFromCentral();
    }

    filterByActivity() {

    }

    /**
     * to see data in new tab
     *
     * @param filter
     */
    public goTabView(filter: any) {
      var a = document.createElement('a');
      a.href = '#!/tab/central/' + this.userPopulated.matricule + '?filter=' +
          this.filter.profile;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
    }

    public eventSelect() {
      this.currentPage = 0;
    }

    public previousPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
        this.updateUserFromCentral();
      }
    }

    public nextPage() {
      if (this.currentPage < this.numberOfPages - 1) {
        this.currentPage++;
        this.updateUserFromCentral();
      }
    }

    public setPage(index: number) {
      this.currentPage = index;
      this.updateUserFromCentral();
    }

    /**
     * Get number
     *
     * @param num
     * @return {any[]}
     */
    public getNumber = function(num: number): any[] {
      return new Array(num);
    };

  }
}

appDashboard.controller('DashboardCentralBlockController',
    controllers.DashboardCentralBlockController);
