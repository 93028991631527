/// <reference path="../appDashboard.ts" />

var managerTurnover = function () {
    return {
        restrict : 'E',
        replace: false,
        scope: {
            promiseUserPopulated: '=',
            promiseCommercialCalendar: '=',
        },
        controller : 'DashboardManagerTurnoverController as dashboardManagerTurnoverController',
        templateUrl : '/templates/manager-turnover.html',
    };
};

appDashboard.directive("managerTurnover", [managerTurnover]);
