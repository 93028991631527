module controllers {
  import IEventsService = services.EventsService;

  export class DashboardArrayController implements angular.IController {
    $onInit = () => { };

    private $scope: any;
    private $state: ng.ui.IStateService;
    private $q: ng.IQService;
    private eventsService: IEventsService;
    private tableHeaders: any;
    private datas: any;
    private currentPage: any;
    private numberPage: any;
    private displayNewTab: any;
    private sort: string;
    private sortSens: number;

    public static $inject = [
      '$scope',
      '$state',
      'limitNumber',
      '$q',
      'EventsService'
    ];

    /**
     *
     * @param $scope
     * @param {angular.ui.IStateService} $state
     * @param limitNumber
     * @param {angular.IQService} $q
     * @param eventsService
     */
    constructor(
        $scope: any,
        $state: ng.ui.IStateService,
        limitNumber: number,
        $q: ng.IQService,
        eventsService: IEventsService,
    ) {

      this.$scope = $scope;
      this.$state = $state;
      this.$q = $q;
      this.currentPage = $scope.currentPage;
      this.numberPage = $scope.numberPage;
      this.displayNewTab = $scope.displayNewTab;
      this.eventsService = eventsService;
      this.sort = "";
      this.sortSens = -1;

      $scope.$on(this.eventsService.getEventBy('data'), function(event, datas) {
        this.init(datas)
      }.bind(this));
      $scope.$on(this.eventsService.getEventBy('number'), function(event, data) {
        this.numberPage = data;
      }.bind(this));
      $scope.$on(this.eventsService.getEventBy('sort'), function(event, data) {
        this.sort = data.sort;
        this.sortSens = data.sortSens;
      }.bind(this));

      this.init(this.$scope.datas);
      this.tableHeaders = this.$scope.tableHeaders;
    }

    private init(datas) {
      this.datas = datas;
    }

    public goToDashboard(matricule: string): void {
      var url = this.$state.href('seeDashboard', {matricule: matricule});
      window.open(url,'_blank');
    }

    public gotoPage(index) {
      if(index >= 0 && index < this.numberPage) {
        this.currentPage = index;
        this.$scope.$emit(this.eventsService.getEventBy('goto'), index)
      }
    }

    public filterBy(sort, sens) {
      this.$scope.$emit(this.eventsService.getEventBy('filter'), {sort: sort, sens: sens})
    }

    public getNumber = function(num: number): any[] {
      return new Array(num);
    };

    public showUser(value, dataIndex, index) {
      if(this.$scope.datas) {
        for(let indexExpanded in this.$scope.datas[dataIndex].expanded) {
          this.$scope.datas[dataIndex].expanded[indexExpanded] = false;
        }
        this.$scope.datas[dataIndex].expanded[index] = value;
      }
      for(let indexExpanded in this.datas[dataIndex].expanded) {
        this.datas[dataIndex].expanded[indexExpanded] = false;
      }
      this.datas[dataIndex].expanded[index] = value;
    }
  }
}

appDashboard.controller('DashboardArrayController',
    controllers.DashboardArrayController);
