/// <reference path="../appDashboard.ts" />

var arrayCommissionTab = function () {
    return {
        restrict : 'E',
        replace: false,
        scope: {
            promiseUserPopulated: '=',
            promiseCommercialCalendar: '=',
            type: '=',
            showGoTab: '='
        },
        controller : 'DashboardCommissionBlockController as dashboardCommissionBlockController',
        templateUrl : '/templates/array-commission-tab.html',
    };
};

appDashboard.directive("arrayCommissionTab", [arrayCommissionTab]);

