/// <reference path="../appDashboard.ts" />7
module models {
    'use strict';
    export interface IRemuneration{
        matricule : String,
        periode : ICommercialCalendar,
        cagroupe? : number,
        caleader? : number,
        catotal? : number,
        txcom?: number,
        caraf? : number,
        gaincagroupe? : number,
        gaincaleader?: number,
        totalGainIndirect? : number,
        gaincreationleader?: number,
        gainrecrut3? : number,
        gainrecrut4? : number,
        gainrecrut5? : number,
        gainrecrut6? : number,
        totalGainRecrutement? : number,
        totalGain? : number,

        expanded? : any,
    }
}


