/// <reference path="../appDashboard.ts" />
module controllers {
    import IUsersService = services.IUsersService;
    import IUserPopulated = models.IUserPopulated;
    import IActivitiesService = services.IActivitiesService;
    import IEventsService = services.EventsService;
    import IDisplaysArrayService = services.IDisplaysArrayService;

    export class DashboardManagerTreeController implements angular.IController {

        $onInit = () => {
        };
        private $scope: ng.IScope;
        private $state: ng.ui.IStateService;
        private usersService: IUsersService;
        private $q: ng.IQService;
        private activitiesService: IActivitiesService;
        private eventsService: IEventsService;
        private displaysArrayService: IDisplaysArrayService;
        private $window: any;

        public userPopulated: IUserPopulated;
        public title: string;
        public tree: any;
        public treeData: any;
        public users: any;
        public usersDisplay: any;
        public selectedMatricule: string;
        public initialTree: boolean;
        public loading: boolean;
        public emptyResult: boolean;

        public numberOfItemByPage: number;
        public currentPage: number;
        public numberOfPages: number;
        public display: boolean;
        private tableHeaders: any;
        public orderBySens: number;
        public orderByLabel: string;

        private treeConfig: any;

        public static $inject = [
            '$scope',
            '$state',
            'UsersService',
            '$q',
            'ActivitiesService',
            'EventsService',
            'DisplaysArrayService',
            '$window'
        ];

        constructor(
            $scope: ng.IScope,
            $state: ng.ui.IStateService,
            usersService: IUsersService,
            $q: ng.IQService,
            activitiesService: IActivitiesService,
            eventsService: IEventsService,
            displaysArrayService: IDisplaysArrayService, $window) {
            this.$scope = $scope;
            this.$state = $state;
            this.usersService = usersService;
            this.$q = $q;
            this.activitiesService = activitiesService;
            this.eventsService = eventsService;
            this.displaysArrayService = displaysArrayService;
            this.$window = $window;

            this.initVariable();

            this.$scope.$on(this.eventsService.getEventBy('goto'), function (event, data) {
                this.setPage(data)
            }.bind(this));

            this.$scope.$on(this.eventsService.getEventBy('filter'), function(event, data) {
                this.setOrderBy(data.sort, data.sens)
            }.bind(this));

            this.$q.all(
                [$scope.promiseUserPopulated, $scope.promiseCommercialCalendar]).then(function (data: any) {
                this.init(data[0], data[1]);
            }.bind(this));
        }

        public setOrderBy(orderByLabel, sens) {
            this.orderBySens = sens;
            this.orderByLabel = orderByLabel;

            this.$scope.$broadcast(this.eventsService.getEventBy('sort'), {sort: this.orderByLabel, sortSens: this.orderBySens});
            this.$scope.$broadcast(this.eventsService.getEventBy('data'), this.usersDisplay.sort((a, b) => {
                if (a.name.value > b.name.value) {
                    return this.orderBySens === 1 ? 1 : -1;
                }
                if (b.name.value > a.name.value) {
                    return this.orderBySens === 1 ? -1 : 1;
                }
                return 0;
            }));
        }

        private initVariable() {
            this.title = 'Arbre';
            this.selectedMatricule = '';
            this.tree = [];
            this.treeData = [];
            this.users = [];
            this.usersDisplay = [];
            this.tableHeaders = [];
            this.loading = true;

            this.numberOfItemByPage = 10;
            this.currentPage = 0;
            this.display = false;
            this.treeConfig = {};

            this.orderBySens = 1;
            this.orderByLabel = null;
            this.emptyResult = true;
        }

        private init(userPopulated: IUserPopulated) {
            this.userPopulated = userPopulated;
            this.selectedMatricule = userPopulated.matricule;
            this.tableHeaders = [
                {label: "SUIVI", key: "follow", sort: false, class: this.displaysArrayService.getClassBy('both')},
                {label: "NOM PRÉNOM", key: "name", sort: true, class: this.displaysArrayService.getClassBy('both')},
                {label: "Typology", key: "typology", sort: false, class: this.displaysArrayService.getClassBy('both')},
                {label: "Métier", key: "profile", sort: false, class: this.displaysArrayService.getClassBy('both')},
                {label: "CA", key: "ca", sort: false, class: this.displaysArrayService.getClassBy('both')},
                {
                    label: "Date dernière commande",
                    key: "lastOrderDate",
                    sort: false,
                    class: this.displaysArrayService.getClassBy('both')
                }
            ];
            this.fetchTree(true);
        }

        private parseUser(user) {
            return {
                follow: {
                    display: this.displaysArrayService.getDisplayBy('gototxtless'),
                    matricule: user.matricule,
                    class: this.displaysArrayService.getClassBy('both'),
                },
                name: {
                    display: this.displaysArrayService.getDisplayBy('showmoreparent'),
                    value: user.lastName + " \n" + user.firstName,
                    index: 0,
                    class: this.displaysArrayService.getClassBy('both')
                },
                typology: {
                    display: this.displaysArrayService.getDisplayBy('default'),
                    value: user.typology,
                    class: this.displaysArrayService.getClassBy('both')
                },
                profile: {
                    display: this.displaysArrayService.getDisplayBy('default'),
                    value: user.profile,
                    class: this.displaysArrayService.getClassBy('both')
                },
                ca: {
                    display: this.displaysArrayService.getDisplayBy('average'),
                    value: user.personalCACurrentPeriod,
                    class: this.displaysArrayService.getClassBy('both')
                },
                lastOrderDate: {
                    display: this.displaysArrayService.getDisplayBy('date'),
                    value: user.lastOrderDate,
                    class: this.displaysArrayService.getClassBy('both')
                },
                userExpand: {0: user},
                expanded: {0: false}
            }
        }

        private parseResultToUsers(data) {
            this.users = [];
            // Only selected
            if (data.thirdLevel && data.thirdLevel.length !== 0) {
                this.users = data.thirdLevel
            } else {
                this.users = data.secondLevel ? data.secondLevel : []
            }

            if (this.users.length === 0) {
                this.emptyResult = true
            }else {
                this.emptyResult = false
            }
            this.pageChange();
        }

        private pageChange() {
            this.numberOfPages = Math.ceil(this.users.length / this.numberOfItemByPage);
            this.usersDisplay = this.users
                .slice(this.currentPage * this.numberOfItemByPage, (this.currentPage + 1) * this.numberOfItemByPage)
                .map(e => this.parseUser(e))
            this.$scope.$broadcast(this.eventsService.getEventBy('number'), this.numberOfPages);
            this.$scope.$broadcast(this.eventsService.getEventBy('data'), this.usersDisplay);
        }

        private parseTreeData() {
            this.treeData = [];
            if (this.tree.firstLevel) {
                this.treeData[0] = {
                    "user": this.tree.firstLevel,
                    "matricule": this.tree.firstLevel.matricule,
                    "name": this.tree.firstLevel.matricule + ' ' + this.tree.firstLevel.lastName,
                    "parent": "null",
                    "children": this.tree.secondLevel.map((e) => {
                        return {
                            "user": e,
                            "matricule": e.matricule,
                            "name": e.matricule + ' ' + e.lastName,
                            "parent": this.tree.firstLevel.matricule,
                            "children": (e.matricule === this.selectedMatricule ||
                                        e.lastName === this.selectedMatricule.toUpperCase()) ?
                                this.tree.thirdLevel.map((i) => {
                                    return {
                                        "user": i,
                                        "matricule": i.matricule,
                                        "name": i.matricule + ' ' + i.lastName,
                                        "parent": e.matricule + ' ' + e.lastName
                                    }
                                })
                                :
                                []
                        }
                    })
                }
            }
        }

        public reset() {
            console.log(this)
            this.selectedMatricule = this.userPopulated.matricule
            this.fetchTree(true);
        }

        private fetchTree(state = false) {
            this.loading = true;

            //reset array
            this.currentPage = 0;
            this.orderBySens = 1;
            this.orderByLabel = null;
            this.$scope.$broadcast(this.eventsService.getEventBy('sort'), {sort: this.orderByLabel, sortSens: this.orderBySens});

            this.usersService.fetchTree(this.selectedMatricule, !state).then((res) => {
                this.initialTree = state;
                this.tree = res
                this.loading = false;
                this.parseTreeData();
                this.parseResultToUsers(res)
                this.generateTree();
            }).catch((e) => {
                if(e.errMessage !== "You are not allowed to see data from this matricule"){
                    let emptyRes = {firstLevel: null, secondLevel: []};
                    this.initialTree = state;
                    this.tree = emptyRes;
                    this.loading = false;
                    this.parseTreeData();
                    this.parseResultToUsers(emptyRes)
                    this.generateTree();
                } else {
                    this.loading = false;
                }
            })
        }

        private generateTree() {
            let heighMatricule = 15;
            let d3 = this.$window.d3;
            let treeData = this.treeData;
            let max = this.tree.thirdLevel ?
                (this.tree.secondLevel.length > this.tree.thirdLevel.length ?
                    this.tree.secondLevel.length : this.tree.thirdLevel.length)
                : this.tree.secondLevel.length

            this.treeConfig.margin = {top: 20, right: 145, bottom: 20, left: 145};
            this.treeConfig.width = 960 - this.treeConfig.margin.right - this.treeConfig.margin.left;
            this.treeConfig.height = (500 - this.treeConfig.margin.top - this.treeConfig.margin.bottom) + (max * heighMatricule);

            this.treeConfig.i = 0;
            this.treeConfig.duration = 0;
            this.treeConfig.root;

            this.treeConfig.tree = d3.layout.tree()
                .size([this.treeConfig.height, '100%']);

            this.treeConfig.diagonal = d3.svg.diagonal()
                .projection(function (d) {
                    return [d.y, d.x];
                });

            d3.select("#tree_svg").remove();

            this.treeConfig.svg = d3.select("#tree-component").append("svg")
                .attr("width", '100%')
                .attr("id", "tree_svg")
                .attr("height", this.treeConfig.height + this.treeConfig.margin.top + this.treeConfig.margin.bottom)
                .append("g")
                .attr("transform", "translate(" + this.treeConfig.margin.left + "," + this.treeConfig.margin.top + ")");

            this.treeConfig.root = treeData[0];
            this.treeConfig.root.x0 = this.treeConfig.height / 2;
            this.treeConfig.root.y0 = 0;

            this.update(this.treeConfig.root);

            d3.select(self.frameElement).style("height", "500px");
        }

        private update(source) {

            // Compute the new tree layout.
            let nodes = this.treeConfig.tree.nodes(this.treeConfig.root).reverse();
            this.treeConfig.links = this.treeConfig.tree.links(nodes);

            // Normalize for fixed-depth.
            let clientWidth = document.getElementById('tree').clientWidth || 1000;
            nodes.forEach((d) => {
                if (this.tree.thirdLevel && this.tree.thirdLevel[0]) {
                    d.y = d.depth * ((clientWidth / 3)) - (d.depth !== 0 ? 150 : 0);
                } else {
                    d.y = d.depth * (clientWidth / 2) - (d.depth !== 0 ? 100 : 0);
                }
            });

            // Update the nodes…
            var node = this.treeConfig.svg.selectAll("g.node")
                .data(nodes, (d) => {
                    return d.id || (d.id = ++this.treeConfig.i);
                });

            // Enter any new nodes at the parent's previous position.
            var nodeEnter = node.enter().append("g")
                .attr("class", "node")
                .attr("transform", function (d) {
                    return "translate(" + source.y0 + "," + source.x0 + ")";
                })
                .on("click", this.click.bind(this));

            nodeEnter.append("circle")
                .attr("r", 1e-6)
                .style("fill", (d) => {
                    return (d.matricule === this.selectedMatricule ||
                        d.user.lastName === this.selectedMatricule.toUpperCase()) ? "black" : "#fff";
                });

            nodeEnter.append("text")
                .attr("x", function (d) {
                    return d.children || d._children ? -13 : 13;
                })
                .attr("dy", ".35em")
                .attr("text-anchor", function (d) {
                    return d.children || d._children ? "end" : "start";
                })
                .attr("fill", function (d) {
                    //let exception = false;
                    if(d.user.exitDate) {
                        let today = new Date();
                        let endDate = new Date(d.user.exitDate);
                        const weekMin = 16;
                        const weekMax = 53;
                        const diffWeek = (today.getTime() - endDate.getTime()) / (7*24*60*60*1000)
                        if(diffWeek > weekMin){
                            if(diffWeek > weekMax){
                                return "red"
                            } else {
                                return "orange"
                            }
                        } else {
                            return "black"
                        }
                    } else {
                        return "black";
                    }
                })
                .text(function (d) {
                    return d.name + ' (' + d.user.profile + ')';
                })
                .style("fill-opacity", 1e-6);

            // Transition nodes to their new position.
            var nodeUpdate = node.transition()
                .duration(this.treeConfig.duration)
                .attr("transform", function (d) {
                    return "translate(" + d.y + "," + d.x + ")";
                });

            nodeUpdate.select("circle")
                .attr("r", 10)
                .style("fill", (d) => {
                    return (d.matricule === this.selectedMatricule ||
                        d.user.lastName === this.selectedMatricule.toUpperCase()) ? "black" : "#fff";
                });

            nodeUpdate.select("text")
                .style("fill-opacity", 1);

            // Transition exiting nodes to the parent's new position.
            var nodeExit = node.exit().transition()
                .duration(this.treeConfig.duration)
                .attr("transform", function (d) {
                    return "translate(" + source.y + "," + source.x + ")";
                })
                .remove();

            nodeExit.select("circle")
                .attr("r", 1e-6);

            nodeExit.select("text")
                .style("fill-opacity", 1e-6);

            // Update the links…
            var link = this.treeConfig.svg.selectAll("path.link")
                .data(this.treeConfig.links, function (d) {
                    return d.target.id;
                });

            // Enter any new links at the parent's previous position.
            link.enter().insert("path", "g")
                .attr("class", "link")
                .attr("d", (d) => {
                    var o = {x: source.x0, y: source.y0};
                    return this.treeConfig.diagonal({source: o, target: o});
                });

            // Transition links to their new position.
            link.transition()
                .duration(this.treeConfig.duration)
                .attr("d", this.treeConfig.diagonal);

            // Transition exiting nodes to the parent's new position.
            link.exit().transition()
                .duration(this.treeConfig.duration)
                .attr("d", (d) => {
                    var o = {x: source.x, y: source.y};
                    return this.treeConfig.diagonal({source: o, target: o});
                })
                .remove();

            // Stash the old positions for transition.
            nodes.forEach(function (d) {
                d.x0 = d.x;
                d.y0 = d.y;
            });
        }

        // Toggle children on click.
        private click(d) {
            this.selectedMatricule = d.matricule;
            this.fetchTree();
        }

        public previousPage() {
            if (this.currentPage > 0) {
                this.currentPage--;
                this.pageChange();
            }
        }

        public nextPage() {
            if (this.currentPage < this.numberOfPages - 1) {
                this.currentPage++;
                this.pageChange();
            }
        }

        public setPage(index: number) {
            this.currentPage = index;
            this.pageChange();
        }

        to = null;

        public filterModification(search) {
            // debounce
            if(this.to) {
                clearTimeout(this.to)
            }
            this.to = setTimeout(() => {
                if(search) {
                    this.selectedMatricule = search;
                    this.fetchTree(false);
                }
            }, 1000)
        }
    }

}

appDashboard.controller('DashboardManagerTreeController',
    controllers.DashboardManagerTreeController);
