/// <reference path="../appDashboard.ts" />7
module models {
    'use strict';
    export interface ITypologyActivity{
        region? : number;
        division? : number;
        sector? : number;
        typology? : string;
        periode? : string;
        turnOver : number;
        activeNumber : number;
        totalEffectif : number;
        totalWatched : number;
        totalRecrue : number;
        totalDemission : number;
    }
}
