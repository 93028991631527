/// <reference path="../appDashboard.ts" />
module controllers {

  import IActivitiesService = services.IActivitiesService;
  import ICommercialCalendarService = services.ICommercialCalendarsService;
  import IChartsService = services.IChartsService;
  import IUsersService = services.IUsersService;
  import IUserPopulated = models.IUserPopulated;
  import ICommercialCalendar = models.ICommercialCalendar;
  import IActivity = models.IActivity;
  import IExportsService = services.IExportsService;
  declare var jQuery: any;
  declare var Promise: any;
  declare var Highcharts: any;

  export class DashboardTurnoverBlockController implements angular.IController {

    $onInit = () => { };
    private scope: any;
    private activitiesService: IActivitiesService;
    private commercialCalendarService: ICommercialCalendarService;
    private exportsService: IExportsService;
    private chartsService: IChartsService;
    private usersService: IUsersService;
    private currentYearName: string;
    private lastYearName: string;
    private maxTurnoverValue: number;
    private currentActivity: IActivity;
    private nMinus1Activity: IActivity;
    private $q: ng.IQService;

    public promiseUserPopulated: any;
    public promiseCommercialCalendar: any;
    public userPopulated: IUserPopulated;
    public currentCycle: ICommercialCalendar;
    public nMinusCycle: ICommercialCalendar;
    public selectLevel: string;
    public selectOptions: any;
    public selectMark: string;
    public turnoverCurrentPeriod: number;
    public turnoverNMinus1: number;
    public percentTurnover: number;
    public diffTurnover: number;
    public orderNumberCurrentPeriod: number;
    public averageBasketCurrentPeriod: number;
    public turnoverArrayLastYear: any;
    public turnoverSumLastYear: number;
    public turnoverArrayCurrentYear: any;
    public turnoverSumCurrentYear: number;
    public lastCycleNumber: number;
    public currentCycleNumber: number;
    public turnoverSumToLastCycle: number;
    public turnoverSumToLastCycleLastYear: number;
    public diffPercentTurnovers: number;
    public limitNumber: number;
    public limitGainNumber: number;
    public activityList: IActivity[];

    public static $inject = [
      '$scope',
      'ActivitiesService',
      'CommercialCalendarsService',
      'ChartsService',
      'UsersService',
      'limitNumber',
      'limitGainNumber',
      '$q',
      'ExportsService',

    ];

    /**
     *
     * @param $scope
     * @param activitiesService
     * @param commercialCalendarService
     * @param chartsService
     * @param usersService
     * @param limitNumber
     * @param limitGainNumber
     * @param exportsService
     */
    constructor($scope: any, activitiesService: IActivitiesService,
                commercialCalendarService: ICommercialCalendarService,
                chartsService: IChartsService, usersService: IUsersService,
                limitNumber: number, limitGainNumber: number,
                $q: ng.IQService, exportsService: IExportsService) {
      this.scope = $scope;
      this.scope.Math = (window as any).Math;
      this.activitiesService = activitiesService;
      this.exportsService = exportsService;
      this.commercialCalendarService = commercialCalendarService;
      this.chartsService = chartsService;
      this.usersService = usersService;
      this.limitNumber = limitNumber;
      this.limitGainNumber = limitGainNumber;
      this.$q = $q;

      this.$q.all(
          [$scope.promiseUserPopulated, $scope.promiseCommercialCalendar]).
          then(function(data: any) {
            this.init(data[0], data[1]);
          }.bind(this));

    }

    private initVariable(): void {
      this.selectLevel = 'n0';
      this.selectOptions = [];
      this.selectMark = '';
      this.turnoverCurrentPeriod = 0;
      this.percentTurnover = 0;
      this.diffTurnover = 0;
      this.orderNumberCurrentPeriod = 0;
      this.averageBasketCurrentPeriod = 0;
      this.turnoverArrayLastYear = [];
      this.turnoverSumLastYear = 0;
      this.turnoverArrayCurrentYear = [];
      this.turnoverSumCurrentYear = 0;
      this.lastCycleNumber = 0;
      this.diffPercentTurnovers = 0;
    }

    /**
     *
     * @param userPopulated
     * @param commercialCalendarList
     */
    private init(
        userPopulated: IUserPopulated,
        commercialCalendarList: ICommercialCalendar[]): void {
      this.initVariable();
      this.userPopulated = userPopulated;
      this.selectOptions = this.usersService.getLevelSelectOptions(
          this.userPopulated);
      this.currentCycle = this.commercialCalendarService.getCurrentCycleForced(
          commercialCalendarList);
      this.currentCycleNumber = this.commercialCalendarService.getCycleNumber(
          this.currentCycle);
      if (this.currentCycle) {
        this.nMinusCycle = this.commercialCalendarService.getNMinus1Cycle(
            commercialCalendarList, this.currentCycle);
      }
      this.lastCycleNumber = this.activitiesService.getLastCycle(
          this.currentCycle);
      this.currentYearName = this.commercialCalendarService.getCurrentYearName(
          this.currentCycle);
      this.lastYearName = this.commercialCalendarService.getLastYearName(
          this.currentCycle);

      this.activitiesService.getByMatricule(userPopulated.matricule).
          then(function(activityList) {
            this.activityList = activityList;
            this.maxTurnoverValue = this.activitiesService.getMAxTurnoverValue(
                activityList, this.currentYearName, this.lastYearName);
            this.currentActivity = this.activitiesService.getCurrentActivity(
                activityList, this.currentCycle);
            this.nMinus1Activity = this.activitiesService.getNMinus1Activity(
                activityList, this.nMinusCycle);

            if (this.currentActivity) {
              this.turnoverCurrentPeriod = this.activitiesService.getTurnover(
                  this.currentActivity, 'n0', null);
              this.orderNumberCurrentPeriod = this.activitiesService.getOrderNumber(
                  this.currentActivity, 'n0');
              if (this.orderNumberCurrentPeriod !== 0) {
                this.averageBasketCurrentPeriod = this.turnoverCurrentPeriod /
                    this.orderNumberCurrentPeriod;
              } else {
                this.averageBasketCurrentPeriod = 0;
              }
              if (this.nMinus1Activity) {
                this.turnoverNMinus1 = this.activitiesService.getTurnover(
                    this.nMinus1Activity, 'n0', null);
                this.diffTurnover = this.turnoverCurrentPeriod -
                    this.turnoverNMinus1;
                if (this.turnoverNMinus1 > 0) {
                  this.percentTurnover = Math.round((this.turnoverCurrentPeriod -
                      this.turnoverNMinus1) / this.turnoverNMinus1 * 100);
                }
              }
            }

            this.turnoverArrayCurrentYear = this.activitiesService.getTurnoverArray(
                activityList, this.currentYearName, 'n0', null);
            this.turnoverArrayLastYear = this.activitiesService.getTurnoverArray(
                activityList, this.lastYearName, 'n0', null);
            this.turnoverSumCurrentYear = this.activitiesService.getTurnoverSum(
                this.turnoverArrayCurrentYear);
            this.turnoverSumLastYear = this.activitiesService.getTurnoverSum(
                this.turnoverArrayLastYear);
            this.turnoverSumToLastCycle = this.activitiesService.getTurnoverSum(
                this.turnoverArrayCurrentYear.slice(0, this.lastCycleNumber));
            this.turnoverSumToLastCycleLastYear = this.activitiesService.getTurnoverSum(
                this.turnoverArrayLastYear.slice(0, this.lastCycleNumber));
            this.diffPercentTurnovers = this.turnoverSumToLastCycleLastYear
                ? 100 * (this.turnoverSumToLastCycle -
                this.turnoverSumToLastCycleLastYear) /
                this.turnoverSumToLastCycleLastYear
                : 0;
            setTimeout(function() {
              this.loadCharts(this.turnoverArrayCurrentYear,
                  this.turnoverArrayLastYear);
            }.bind(this));

          }.bind(this));

    }

    /**
     * Redraw the chart
     *
     * @param turnoverArrayCurrentYear
     * @param turnoverArrayLastYear
     */
    private loadCharts(
        turnoverArrayCurrentYear: number[],
        turnoverArrayLastYear: number[]): void {
      Highcharts.setOptions({
        lang: {
          decimalPoint: ',',
          thousandsSep: ' ',
        },
      });

      var optionsColorChart =
          [
            {
              data: turnoverArrayLastYear,
              seriesName: 'Référentiel',
              fillColor1: [0, 'rgba(165,5,224,0.6)'],
              fillColor2: [1, 'rgba(226,191,255,0.6)'],
              color: '#bc54e6',
              valueSuffix: ' €',
              colorLabel: 'violet',
              tooltip: {
                pointFormat: '<tr><td>Référentiel:&nbsp&nbsp&nbsp&nbsp </td><td style="width: 10px"></td>' +
                '<td style="text-align: right; border: 2px solid {series.color}; padding :5px; background-color: white;"><b>{point.y} €</b></td></tr>' +
                '<tr style="height: 10px"><td> </td></tr>',
              },
            },
            {
              data: turnoverArrayCurrentYear,
              seriesName: 'Ventes réalisées',
              fillColor1: [0, 'rgba(251,135,170,0.83)'],
              fillColor2: [1, 'rgba(224,0,38,0.83)'],
              color: '#e1052b',
              valueSuffix: ' €',
              colorLabel: 'rouge',
              tooltip: {
                pointFormatter: function() {
                  var valueToPrint = '';
                  var currentY = this.y;
                  //assuming the n-1 year series are before this year's series
                  var n1seriesIndex = this.series.index - 1;
                  if (this.series.chart.series.length > 0 &&
                      this.series.chart.series[n1seriesIndex].data.length > 0) {
                    var otherYearData = this.series.chart.series[n1seriesIndex].data;
                    var n1YValue = otherYearData[this.x].y;
                    if (currentY >= n1YValue) {
                      if (n1YValue) {
                        valueToPrint = '<span style=\'color: green\'>&nbsp+ ' +
                            Math.round((currentY - n1YValue) / n1YValue * 100) +
                            '%</span>';
                      } else {
                        valueToPrint = '<span style=\'color: grey\'>&nbsp+ ' +
                            Math.round(currentY) + '€</span>';
                      }
                    } else {
                      valueToPrint = '<span style=\'color: red\'>&nbsp- ' +
                          (n1YValue - currentY) + '€</span>';
                    }
                  }
                  var returnString = '<tr><td>Vente réalisées: &nbsp&nbsp&nbsp&nbsp</td><td style="width: 10px"></td>' +
                      '<td style="text-align: right; border: 2px solid ' +
                      this.series.color +
                      '; padding :5px; background-color: white;"><b>' + this.y +
                      ' €</b></td>';
                  if (valueToPrint) returnString += '<td>' + valueToPrint +
                      '</td></tr>';
                  returnString += '<tr style="height: 10px"><td></td></tr>';
                  return returnString;
                },
              },
            },
          ];

      var tooltipOption = {
        borderWidth: 0,
        backgroundColor: 'transparent',
        valueSuffix: '',
        shadow: false,
        useHTML: true,
        style: {
          color: '#48494d',
          fontWeight: '700',
          fontSize: '15px',
          fontFamily: 'Lato',
          right: '-50px',
        },
        headerFormat: '<table>',
        footerFormat: '</table>',
        hideDelay: '5000',
        shared: true,
        positioner: function() {
          return {
            x: this.chart.plotLeft,
            y: this.chart.plotTop
          };
        },
      };

      var chart = Highcharts.chart('container',
          this.chartsService.getChart(optionsColorChart, tooltipOption));
      chart.redraw();
    }

    /**
     * get if the Chart is displayed
     * @returns {null|boolean}
     */
    public displayHighcharts(): boolean {
      return (this.turnoverArrayCurrentYear &&
          this.turnoverArrayCurrentYear.length != 0) ||
          (this.turnoverArrayLastYear && this.turnoverArrayLastYear.length !=
              0);
    }

    /**
     * to export data in excel
     *
     */
    public exportExcel(level, mark) {
      this.exportsService.exportCA(this.userPopulated.matricule, level, mark);
    }

    /**
     * update the chart's data
     * @param selectedLevel
     * @param selectMark
     * @return void
     */
    public updateGraphData(selectedLevel: string, selectMark: string): void {

      var local_mark = null;
      if (selectMark !== 'null') {
        local_mark = angular.copy(selectMark);
      }
      this.turnoverArrayCurrentYear = this.activitiesService.getTurnoverArray(
          this.activityList, this.currentYearName, selectedLevel, local_mark);
      this.turnoverSumCurrentYear = this.activitiesService.getTurnoverSum(
          this.turnoverArrayCurrentYear);
      this.turnoverArrayLastYear = this.activitiesService.getTurnoverArray(
          this.activityList, this.lastYearName, selectedLevel, local_mark);
      this.turnoverSumLastYear = this.activitiesService.getTurnoverSum(
          this.turnoverArrayLastYear);
      this.turnoverSumToLastCycle = this.activitiesService.getTurnoverSum(
          this.turnoverArrayCurrentYear.slice(0, this.lastCycleNumber));
      this.turnoverSumToLastCycleLastYear = this.activitiesService.getTurnoverSum(
          this.turnoverArrayLastYear.slice(0, this.lastCycleNumber));
      this.diffPercentTurnovers = this.turnoverSumToLastCycleLastYear ? 100 *
          (this.turnoverSumToLastCycle - this.turnoverSumToLastCycleLastYear) /
          this.turnoverSumToLastCycleLastYear : 100;

      setTimeout(function() {
        this.loadCharts(this.turnoverArrayCurrentYear,
            this.turnoverArrayLastYear);
      }.bind(this));

      if (this.currentActivity) {
        this.turnoverCurrentPeriod = this.activitiesService.getTurnover(
            this.currentActivity, selectedLevel, selectMark);
        this.orderNumberCurrentPeriod = this.activitiesService.getOrderNumber(
            this.currentActivity, selectedLevel);
        if (this.orderNumberCurrentPeriod !== 0) {
          this.averageBasketCurrentPeriod = this.turnoverCurrentPeriod /
              this.orderNumberCurrentPeriod;
        } else {
          this.averageBasketCurrentPeriod = 0;
        }
        if (this.nMinus1Activity) {
          this.turnoverNMinus1 = this.activitiesService.getTurnover(
              this.nMinus1Activity, selectedLevel, selectMark);
          this.diffTurnover = this.turnoverCurrentPeriod - this.turnoverNMinus1;
          if (this.turnoverNMinus1 > 0) {
            this.percentTurnover = Math.round((this.turnoverCurrentPeriod -
                this.turnoverNMinus1) / this.turnoverNMinus1 * 100);
          }
        }
      }
    };
  }
}

appDashboard.controller('DashboardTurnoverBlockController',
    controllers.DashboardTurnoverBlockController);
