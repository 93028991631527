/// <reference path="../appDashboard.ts" />
module controllers {

  import IUsersService              = services.IUsersService;
  import IUserPopulated             = models.IUserPopulated;
  import ICommercialCalendarService = services.ICommercialCalendarsService;
  import ICommercialCalendar        = models.ICommercialCalendar;
  import IActivity                  = models.IActivity;
  import IActivitiesService         = services.IActivitiesService;
  import IChartsService             = services.IChartsService;
  import IExportsService             = services.IExportsService;

  export class DashboardManagerTurnoverController implements angular.IController {

    $onInit = () => { };
    private $scope: ng.IScope;
    private $state: ng.ui.IStateService;
    private usersService: IUsersService;
    private exportsService: IExportsService;
    private commercialCalendarService: ICommercialCalendarService;
    private $q: ng.IQService;
    private activitiesService: IActivitiesService;
    private maxTurnoverValue: number;
    private chartsService: IChartsService;
    private commercialCalendarList: ICommercialCalendar[];

    private currentYearName: string;
    private lastYearName: string;
    public userPopulated: IUserPopulated;
    public currentCycle: ICommercialCalendar;
    public currentCycleNumber: number;
    public currentActivity: IActivity;
    public turnoverCurrentPeriod: number;
    public orderNumberCurrentPeriod: number;
    public limitNumber: number;
    public averageBasketCurrentPeriod: number;
    public selectMark: string;
    public selectLevel: string;
    public objectifNumber: number;
    public totalTurnoverReference: number;
    public display_diffReferencePercent: boolean;
    public display_diffObjectifPercent: boolean;

    public turnoverArray: any;
    public objectifArray: any;
    public referenceArray: any;
    public turnoverSum: number;
    public objectifSum: number;
    public referenceSum: number;

    public lastCycleNumber: number;
    public turnoverSumToLastCycle: number;
    public turnoverSumReferenceToLastcycle: number;
    public diffPercentTurnovers: number;
    public diffTurnover: number;
    public diffObjectifPercent: number;
    public diffReferencePercent: number;
    public diffReferenceValue: number;
    public activityList: IActivity[];

    public static $inject = [
      '$scope',
      '$state',
      'UsersService',
      'CommercialCalendarsService',
      '$q',
      'ActivitiesService',
      'limitNumber',
      'ChartsService',
      'ExportsService',
    ];

    constructor(
        $scope: ng.IScope,
        $state: ng.ui.IStateService,
        usersService: IUsersService,
        commercialCalendarService: ICommercialCalendarService,
        $q: ng.IQService,
        activitiesService: IActivitiesService,
        limitNumber: number,
        chartsService: IChartsService,
        exportsService: IExportsService) {
      this.$scope = $scope;
      this.$state = $state;
      this.usersService = usersService;
      this.exportsService = exportsService;
      this.commercialCalendarService = commercialCalendarService;
      this.$q = $q;
      this.activitiesService = activitiesService;
      this.limitNumber = limitNumber;
      this.chartsService = chartsService;

      this.initVariable();

      this.$q.all(
          [$scope.promiseUserPopulated, $scope.promiseCommercialCalendar]).
          then(function(data: any) {
            this.init(data[0], data[1]);
          }.bind(this));
    }

    private initVariable() {
      this.selectMark = '';
      this.turnoverCurrentPeriod = 0;
      this.orderNumberCurrentPeriod = 0;
      this.averageBasketCurrentPeriod = 0;
      this.turnoverArray = [];
      this.turnoverSum = 0;
      this.referenceArray = [];
      this.referenceSum = 0;
      this.objectifArray = [];
      this.objectifSum = 0;
      this.lastCycleNumber = 0;
      this.diffPercentTurnovers = 0;
    }

    private init(
        userPopulated: IUserPopulated,
        commercialCalendarList: ICommercialCalendar[]) {
      this.userPopulated = userPopulated;

      this.commercialCalendarList = commercialCalendarList;
      this.currentCycle = this.commercialCalendarService.getCurrentCycleForced(
          commercialCalendarList);
      this.lastCycleNumber = this.activitiesService.getLastCycle(
          this.currentCycle);

      this.currentCycleNumber = this.commercialCalendarService.getCycleNumber(
          this.currentCycle);
      this.currentYearName = this.commercialCalendarService.getCurrentYearName(
          this.currentCycle);

      this.activitiesService.getByMatricule(userPopulated.matricule).then(
          function(activityList) {
            this.activityList = activityList;
            this.currentActivity = this.activitiesService.getCurrentActivity(
                activityList, this.currentCycle);
            this.selectLevel = this.usersService.getLevelSelectOptions(
                this.userPopulated);

            this.selectOptions = this.usersService.getLevelSelectOptions(this.userPopulated);

            this.updateGraphData(null);
          }.bind(this),
      );
      
    }

    /**
     * to export data in excel
     *
     */
    public exportExcel(mark) {
      this.exportsService.exportCA(this.userPopulated.matricule,
          this.selectLevel, mark);
    }

    /**
     * Redraw the chart
     *
     * @param turnoverArrayCurrentYear
     * @param turnoverArrayLastYear
     * @param objectifArray
     */
    private loadCharts(
        turnoverArrayCurrentYear: number[], turnoverArrayLastYear: number[],
        objectifArray: number[]): void {
      Highcharts.setOptions({
        lang: {
          decimalPoint: ',',
          thousandsSep: ' ',
        },
      });

      var tooltipOption = {
        shared: true,
        borderWidth: 0,
        backgroundColor: 'transparent',
        valueSuffix: '',
        shadow: false,
        useHTML: true,
        style: {
          color: '#48494d',
          fontWeight: '700',
          fontSize: '15px',
          fontFamily: 'Lato',
          right: '-50px',
        },
        headerFormat: '<table>',
        footerFormat: '</table>',
        padding: '0',
        hideDelay: '5000',
        positioner: function(labelWidth: any, labelHeight: any, point: any) {
          return {
            x: this.chart.chartWidth - 250,
            y: this.chart.chartHeight - 200,
          };
        },
        pointFormat: '<tr><td>{series.name}: &nbsp&nbsp</td><td style="width: 10px"></td>' +
        '<td style="text-align: right; border: 2px solid {series.color}; padding :5px; background-color: white;"><b>{point.y} €</b></td></tr><tr style="height: 10px"></tr>',
      };

      var optionsColorChart =
          [
            {
              data: objectifArray,
              seriesName: 'Objectif',
              fillColor1: [0, 'rgba(0,0,0,0)'],
              fillColor2: [1, 'rgba(0,0,0,0)'],
              color: '#82ddff',
              valueSuffix: '€',
              colorLabel: 'blue',
            },
            {
              data: turnoverArrayLastYear,
              seriesName: 'Référentiel',
              fillColor1: [0, 'rgba(165,5,224,0.6)'],
              fillColor2: [1, 'rgba(226,191,255,0.6)'],
              color: '#bc54e6',
              valueSuffix: '€',
              colorLabel: 'violet',
            },
            {
              data: turnoverArrayCurrentYear,
              seriesName: 'Ventes réalisées',
              fillColor1: [0, 'rgba(251,135,170,0.83)'],
              fillColor2: [1, 'rgba(224,0,38,0.83)'],
              color: '#e1052b',
              valueSuffix: '€',
              colorLabel: 'rouge',
            },

          ];

      var chart = Highcharts.chart('container',
          this.chartsService.getChart(optionsColorChart, tooltipOption));
      chart.redraw();
    }

    /**
     * get if the Chart is displayed
     * @returns {null|boolean}
     */
    public displayHighcharts(): boolean {
      return (this.turnoverArray && this.turnoverArray.length != 0) ||
          (this.referenceArray && this.referenceArray.length != 0);
    }

    /**
     * update the chart's data
     * @param selectMark
     * @return void
     */
    public updateGraphData(selectMark: string): void {

      var local_mark = null;
      if (selectMark !== 'null') {
        local_mark = angular.copy(selectMark);
      }

      if (this.currentActivity) {
        // get the turvover of the user in the current activity
        this.turnoverCurrentPeriod = this.activitiesService.getTurnover(
            this.currentActivity, this.selectLevel, local_mark);
        this.orderNumberCurrentPeriod = this.activitiesService.getOrderNumber(
            this.currentActivity, this.selectLevel);
        if (this.orderNumberCurrentPeriod !== 0) {
          this.averageBasketCurrentPeriod = this.turnoverCurrentPeriod /
              this.orderNumberCurrentPeriod;
        } else {
          this.averageBasketCurrentPeriod = 0;
        }
        // get the objectif of the current period
        //this.objectifNumber = this.activitiesService.getObjectifNumber(this.currentActivity, this.selectLevel);
        // get the referential of the user
        var referentialActivity = this.activitiesService.getNMinus1Activity(
            this.activityList, this.commercialCalendarService.getNMinus1Cycle(
                this.commercialCalendarList, this.currentCycle));
        var turnoverReferencialPeriod = this.activitiesService.getTurnover(
            referentialActivity, this.selectLevel, local_mark);

        //var objectifCurrentPariod = turnoverReferencialPeriod * this.objectifNumber;
        //this.totalTurnoverReference = this.activitiesService.getTotalTurnoverReference(this.currentActivity, this.selectLevel);

        if (turnoverReferencialPeriod) {
          this.diffReferencePercent = ((this.turnoverCurrentPeriod -
              turnoverReferencialPeriod) / turnoverReferencialPeriod) * 100;
        } else {
          this.diffReferencePercent = 100;
        }
        this.diffReferenceValue = (this.turnoverCurrentPeriod -
            turnoverReferencialPeriod);
        //this.diffObjectifPercent = ((this.turnoverCurrentPeriod - objectifCurrentPariod)  / objectifCurrentPariod ) * 100;
        this.display_diffReferencePercent = !!referentialActivity;
        //this.display_diffObjectifPercent = !!this.diffObjectifPercent;
      }

      this.turnoverArray = this.activitiesService.getTurnoverArray(
          this.activityList, this.currentYearName, this.selectLevel,
          local_mark);
      this.turnoverSum = this.activitiesService.getTurnoverSum(
          this.turnoverArray);

      this.referenceArray = this.activitiesService.getTurnoverArray(
          this.activityList,
          this.commercialCalendarService.getLastYearName(this.currentCycle),
          this.selectLevel, local_mark);
      this.referenceSum = this.activitiesService.getTurnoverSum(
          this.referenceArray);

      //this.objectifArray = this.activitiesService.getObjectifArray(this.activityList, this.commercialCalendarService.getLastYearName(this.currentCycle), this.selectLevel, local_mark);
      //this.objectifSum = this.activitiesService.getTurnoverSum(this.objectifArray);

      setTimeout(function() {
        this.loadCharts(this.turnoverArray, this.referenceArray,
            this.objectifArray);
      }.bind(this));

      this.turnoverSumToLastCycle = this.activitiesService.getTurnoverSum(
          this.turnoverArray.slice(0, this.lastCycleNumber));
      this.turnoverSumReferenceToLastcycle = this.activitiesService.getTurnoverSum(
          this.referenceArray.slice(0, this.lastCycleNumber));
      this.diffPercentTurnovers = this.turnoverSumReferenceToLastcycle ? 100 *
          (this.turnoverSumToLastCycle - this.turnoverSumReferenceToLastcycle) /
          this.turnoverSumReferenceToLastcycle : 0;

    };

  }
}

appDashboard.controller('DashboardManagerTurnoverController',
    controllers.DashboardManagerTurnoverController);
