/// <reference path="../appDashboard.ts" />7
module models {
  'use strict';

  export interface IUser {
    firstName: string;
    lastName: string;
    pan: any;
    profile: string;
    matricule: string;
    parentMatricule: string;
    grandParentMatricule: string;
    password: string;
    typology: string;
    tokenObjectList: any[];
    isActive: boolean;
    childLevelForParent?: number;
    currentActivity?: IActivity;
    orderNumberCurrentPeriod?: number;
  }
}


