/// <reference path="../appDashboard.ts" />
module controllers {

  import IUsersService = services.IUsersService;
  import IUser = models.IUser;
  import ICommonService = services.ICommonService;

  export class DashboardSupportController implements angular.IController {
    $onInit = () => { };

    private $scope: ng.IScope;
    private $state: ng.ui.IStateService;
    private $window: any;
    private usersService: IUsersService;
    private commonService: ICommonService;
    public selected: any;
    public displayGoToButton: boolean;
    public token: string;

    public static $inject = [
      '$scope',
      '$state',
      'UsersService',
      'CommonService',
      '$window',
    ];

    constructor(
        $scope: ng.IScope, $state: ng.ui.IStateService,
        usersService: IUsersService, commonService: ICommonService, $window) {
      this.$scope = $scope;
      this.$state = $state;
      this.usersService = usersService;
      this.commonService = commonService;
      this.$window = $window;
    }

    public logAs(matricule: string) {
      this.$window.location.href = '/#!/seeDashboard/' + matricule;
    }
  }
}

appDashboard.controller('DashboardSupportController',
    controllers.DashboardSupportController);
