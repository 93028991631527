/// <reference path="../appDashboard.ts" />7
module models {
    'use strict';
    export interface IObjective{
        nextDirectCommissionRate : number;
        nextLevelP2R : string;
        currentLevelP2R : string;
        nextN1LCommissionRate : number;
        nextN2LCommissionRate : number;
        lastLevelP2R : string;
        nextDirectCommissionRateTurnoverCriteria : number;
        nextDirectCommissionRateTurnoverRemainsToBeDone : number;
        minimumQuarterlyTurnoverCriteria : number;
        quarterlyTurnoverRemainsToBeDone : number;
        nextLevelP2RNumberActivesCriteria : number;
        nextLevelP2RNumberActivesRemainsToBeDone : number;
        QuarterlyTurnover: number;
    }
}


