/// <reference path="../appDashboard.ts" />
module controllers {

  import IUserPopulated             = models.IUserPopulated;
  import IUsersService              = services.IUsersService;
  import IExportsService            = services.IExportsService;
  import IDisplaysArrayService      = services.IDisplaysArrayService;
  import IEventsService             = services.EventsService;
  export class DashboardMyLevelRemunerationBlockController implements angular.IController {
    $onInit = () => { };

    private $scope: any;
    private $state: ng.ui.IStateService;
    private usersService: IUsersService;
    private exportsService: IExportsService;
    private displaysArrayService: IDisplaysArrayService;
    private eventsService: IEventsService;
    private $q: ng.IQService;
    public userPopulated: IUserPopulated;
    public sort: string;
    public sortSens: number;
    public displayNewTab: any = true;
    public syntheseRemList: any[] = [];

    public static $inject = [
      '$scope',
      '$state',
      'UsersService',
      'ExportsService',
      'DisplaysArrayService',
      'EventsService',
      '$q',
    ];

    constructor(
      $scope: any,
      $state: ng.ui.IStateService,
      usersService: IUsersService,
      exportsService: IExportsService,
      displaysArrayService: IDisplaysArrayService,
      eventsService: IEventsService,
      $q: ng.IQService) {

      this.$scope = $scope;
      this.$state = $state;
      this.usersService = usersService;
      this.exportsService = exportsService;
      this.displaysArrayService = displaysArrayService;
      this.eventsService = eventsService;
      this.sort = 'periode';
      this.sortSens = -1;
      this.$q = $q;

      this.$q.all(
        [$scope.promiseUserPopulated]).
      then(function(data: any) {
        this.userPopulated = data[0];
        this.tableHeaders = [
          {label: "Période", key: "periode", sort: true, display: this.displaysArrayService.getDisplayBy('default'), class: this.displaysArrayService.getClassBy('both')},
          {label: "Palier", key: "levelP2R", sort: false, class: this.displaysArrayService.getClassBy('both')},
          {label: "Actives Trimestre", key: "nombreActiveTrimestre", sort: false, class: this.displaysArrayService.getClassBy('both')},
          {label: "Montant Commission Directe", key: "directCommissionAmount", sort: false, class: this.displaysArrayService.getClassBy('both')},
          {label: "Montant Commission Indirectee", key: "indirectCommissionAmount", sort: false, class: this.displaysArrayService.getClassBy('both')},
          {label: "Total Commission", key: "totalCommissionAmount", sort: false, class: this.displaysArrayService.getClassBy('both')},
          {label: "Montant Prime Mensuel", key: "monthPrimeAmount", sort: false, class: this.displaysArrayService.getClassBy('both')},
          {label: "Filleules Actives Trimestre", key: "nombreActivePeriode", sort: false, class: this.displaysArrayService.getClassBy('both')},
          {label: "Prime Parrainage Trimestre", key: "quarterPrimeAmount", sort: false, class: this.displaysArrayService.getClassBy('both')},
          {label: "Total", key: "totalAmount", sort: false, class: this.displaysArrayService.getClassBy('both')},
          {label: "Gain Produit", key: "gainEligibility", sort: false, class: this.displaysArrayService.getClassBy('both')},
        ];

        this.userPopulated?.syntheseRemList.forEach((e) => {
          this.syntheseRemList.push({
            periode: {
              display: this.displaysArrayService.getDisplayBy('default'),
              value: e.periode,
              class: this.displaysArrayService.getClassBy('both')
            },
            levelP2R: {
              display: this.displaysArrayService.getDisplayBy('default'),
              value: e.levelP2R,
              class: this.displaysArrayService.getClassBy('desktop')
            },
            nombreActiveTrimestre: {
              display: this.displaysArrayService.getDisplayBy('plain'),
              value: e.nombreActiveTrimestre,
              class: this.displaysArrayService.getClassBy('desktop')
            },
            directCommissionAmount: {
              display: this.displaysArrayService.getDisplayBy('average'),
              value: e.directCommissionAmount,
              class: this.displaysArrayService.getClassBy('desktop')
            },
            indirectCommissionAmount: {
              display: this.displaysArrayService.getDisplayBy('average'),
              value: e.indirectCommissionAmount,
              class: this.displaysArrayService.getClassBy('desktop')
            },
            totalCommissionAmount: {
              display: this.displaysArrayService.getDisplayBy('average'),
              value: e.totalCommissionAmount,
              class: this.displaysArrayService.getClassBy('desktop')
            },
            monthPrimeAmount: {
              display: this.displaysArrayService.getDisplayBy('average'),
              value: e.monthPrimeAmount,
              class: this.displaysArrayService.getClassBy('desktop')
            },
            nombreActivePeriode: {
              display: this.displaysArrayService.getDisplayBy('plain'),
              value: e.nombreActivePeriode,
              class: this.displaysArrayService.getClassBy('desktop')
            },
            quarterPrimeAmount: {
              display: this.displaysArrayService.getDisplayBy('average'),
              value: e.quarterPrimeAmount,
              class: this.displaysArrayService.getClassBy('desktop')
            },
            totalAmount: {
              display: this.displaysArrayService.getDisplayBy('average'),
              value: e.totalAmount,
              class: this.displaysArrayService.getClassBy('desktop')
            },
            gainEligibility: {
              display: this.displaysArrayService.getDisplayBy('default'),
              value: e.gainEligibility,
              class: this.displaysArrayService.getClassBy('desktop')
            },
          })
        });

        this.$scope.$broadcast(this.eventsService.getEventBy('data'), this.syntheseRemList);
        this.$scope.$broadcast(this.eventsService.getEventBy('filter'), {sort: this.sort, sortSens: this.sortSens});


      }.bind(this));
      this.$scope.$on(this.eventsService.getEventBy('filter'), function(event, data) {
        this.setOrderBy(data.sort, data.sens)
      }.bind(this));

    }

    public exportExcel() {
      this.exportsService.exportSyntheseRemuneration(this.userPopulated.matricule);
    }

    public setOrderBy(sort, sortSens) {
      if (sort === 'periode') {
        this.syntheseRemList.sort((a: any, b: any) => {
          const aperiode = Number(a.periode.value.split("-")[0]);
          const atime = Number(a.periode.value.split("-")[1].substring(1));
          const bperiode = Number(b.periode.value.split("-")[0]);
          const btime = Number(b.periode.value.split("-")[1].substring(1));
          if (sortSens === 1) {
            return  (aperiode * 10 + atime) - (bperiode * 10 + btime);
          } else {
            return  (bperiode * 10 + btime) - (aperiode * 10 + atime);
          }
        });
      }
      this.$scope.$broadcast(this.eventsService.getEventBy('sort'), {sort, sortSens});
    }

    public displayNivRemuneration() {
        return !(this.userPopulated.profile == 'DD' || this.userPopulated.profile == 'DR' || this.userPopulated.profile == 'ZM') && this.userPopulated.isSF;
    }
  }
}

appDashboard.controller('DashboardMyLevelRemunerationBlockController',
  controllers.DashboardMyLevelRemunerationBlockController);
