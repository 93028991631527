/// <reference path="../appDashboard.ts" />7
module models {
    'use strict';
    export interface IMessage{
        isSeen : boolean
        birthdayUserList : any[]

    }
}


