/// <reference path="../appDashboard.ts" />

var managerPrimeTurnover = function () {
    return {
        restrict : 'E',
        replace: false,
        scope: {
            promiseUserPopulated: '=',
            promiseCommercialCalendar: '=',
        },
        controller : 'DashboardManagerPrimeTurnoverController as primeTurnover',
        templateUrl : '/templates/manager-prime-turnover.html',
    };
};

appDashboard.directive("managerPrimeTurnover", [managerPrimeTurnover]);
