/// <reference path="../appDashboard.ts" />7
module models {
  'use strict';

  export interface IUserPopulated {
    childLevelForParent?: number
    childType?: string
    objective: IObjective;
    matricule: string;
    profile: string;
    parent?: IUserPopulated;
    p2RLevel: any;
    isSF: any;
    GSDRType: string;
    GSDRValue: number;
    orderNumberCurrentPeriod?: number;
    typology: string;
    personalPerformanceIndicator: number;
    _id: any;
    firstName: string;
    lastName: string;
    fromStartYearEffectif: number;
    currentEffectif: number;
    currentYearLeader: number;
    currentYearAnimatrice: number;
    fromStartYearRecrue: number;
    fromStartYearDemission: number;
    myCurrentNumberOfWeek: number;
    turnOverperformanceIndicator: number;
    recrueOverperformanceIndicator: number;
    dateOfEnd12Week: any;
    turnOver12WeekPeriode: number;
    typologyActivityList: ITypologyActivity[];
    zoneSize: number;
    totalEffectif: number;
    totalWatched: number;
    totalRecrue: number;
    activeNumber: number;
    totalDemission: number;
    turnoverAvg: number;
    zoneTotalTurnover: number;
    zoneReferenceTurnover: number;
    increase: number;
    codeZone: number;
    divisionSize: string;
    groupSize: string;
    regionSize: string;
    sectorSize: string;
    isActive: boolean;
    primeList: IPrime[];
    lastQuarterLeader: string;
    lastLevelLeader: string;

    nMinus1Activity?: any
    currentActivity?: any
    typologyActivityCurrent?: any
    teamRegion?: any
    teamDivision?: any
    teamSector?: any
    teamGroup?: any
    team?: any
    active?: number
    period?: string
    caHt?: number
  }
}


