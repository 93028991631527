/// <reference path="../appDashboard.ts" />

var managerTree = function () {
    return {
        restrict : 'E',
        replace: false,
        scope: {
            promiseUserPopulated: '=',
            promiseCommercialCalendar: '=',
        },
        controller : 'DashboardManagerTreeController as dashboardManagerTreeController',
        templateUrl : '/templates/manager-tree.html',
    };
};

appDashboard.directive("managerTree", [managerTree]);
