/// <reference path="../appDashboard.ts" />7
module services {
  'use strict';

  export interface IExportsService {
    exportCommission(
        matricule: string, type: string, cycle: string): ng.IPromise<any>;

    exportLeader(matricule: string, GSDRValue: number): ng.IPromise<any>;

    exportPrimeRecruitment(division: string, sector: number): ng.IPromise<any>;

    exportPrimeRecruitmentQuarter(
        division: string, sector: number): ng.IPromise<any>;

    exportTeam(matricule: any, GSDRValue: any): ng.IPromise<any>;

    exportCA(matricule: string, level: string, mark: string): ng.IPromise<any>

    exportCentral(matricule: string): ng.IPromise<any>

    exportTeamManger(
        matricule: string, managerZoneValue: any,
        leaderZoneType: string): ng.IPromise<any>

    exportPan(matricule: string, GSDRValue: number): ng.IPromise<any>

    exportRem(matricule: string, filter: any, structure: string): ng.IPromise<any>

    exportSyntheseRemuneration(matricule: string): ng.IPromise<any>
  }

  export class ExportsService implements IExportsService {

    private $q: ng.IQService;
    private commonService: ICommonService;
    public static $inject = [
      '$q',
      'CommonService',
    ];

    /**
     *
     * @param $q
     * @param commonService
     */
    constructor($q: ng.IQService, commonService: ICommonService) {
      this.commonService = commonService;
    }

    /**

     * @param matricule
     * @param GSDRValue
     * @returns {IPromise<TResult>}
     */
    public exportTeam(matricule: any, GSDRValue: any): ng.IPromise<any> {
      return this.commonService.httpGet(
          '/api/exports/team', {matricule: matricule, GSDRValue: GSDRValue},
      ).then(function(data) {
        window.location.assign('/export/' + data);
      });
    }

    /**

     * @param matricule
     * @returns {IPromise<TResult>}
     */
    public exportCentral(matricule: string): ng.IPromise<any> {
      return this.commonService.httpGet(
          '/api/exports/central', {matricule: matricule},
      ).then(function(data) {
        window.location.assign('/export/' + data);
      });
    }

    /**
     *
     * @param matricule
     * @param level
     * @param mark
     * @returns {IPromise<TResult>}
     */
    public exportCA(
        matricule: string, level: string, mark: string): ng.IPromise<any> {
      return this.commonService.httpGet(
          '/api/exports/ca', {matricule: matricule, level: level, mark: mark},
      ).then(function(data) {
        window.location.assign('/export/' + data);
      });
    }

    /**
     * @param matricule
     * @param managerZoneValue
     * @param leaderZoneType
     * @returns {IPromise<any>}
     */
    public exportTeamManger(
        matricule: string, managerZoneValue: any,
        leaderZoneType: string): ng.IPromise<any> {
      return this.commonService.httpGet(
          '/api/exports/teamManager', {
            matricule: matricule,
            managerZoneValue: managerZoneValue,
            leaderZoneType: leaderZoneType,
          },
      ).then(function(data) {
        window.location.assign('/export/' + data);
      });
    }

    /**
     *
     * @param matricule
     * @param type
     * @param cycle
     * @returns {IPromise<TResult>}
     */
    public exportCommission(
        matricule: string, type: string, cycle: string): ng.IPromise<any> {
      return this.commonService.httpGet(
          '/api/exports/commission',
          {matricule: matricule, type: type, cycle: cycle},
      ).then(function(data) {
        window.location.assign('/export/' + data);
      });
    }

    public exportLeader(
        matricule: string, GSDRValue: number): ng.IPromise<any> {
      return this.commonService.httpGet(
          '/api/exports/leader', {matricule: matricule, GSDRValue: GSDRValue},
      ).then(function(data) {
        window.location.assign('/export/' + data);
      });
    }

    public exportPrimeRecruitment(
        division: string, sector: number): ng.IPromise<any> {
      return this.commonService.httpGet(
          '/api/exports/primeRecruitment', {division: division, sector: sector},
      ).then(function(data) {
        window.location.assign('/export/' + data);
      });
    }

    public exportPrimeRecruitmentQuarter(
        division: string, sector: number): ng.IPromise<any> {
      return this.commonService.httpGet(
          '/api/exports/primeRecruitmentQuarter',
          {division: division, sector: sector},
      ).then(function(data) {
        window.location.assign('/export/' + data);
      });
    }

    public exportPan(matricule: string, GSDRValue : number): ng.IPromise<any> {
      return this.commonService.httpGet(
          '/api/exports/pan', {matricule: matricule, GSDRValue : GSDRValue},
      ).then(function(data) {
        window.location.assign('/export/' + data);
      });
    }

    public exportRem(matricule: string, filter: any, structure: string): ng.IPromise<any> {
      return this.commonService.httpGet(
          '/api/exports/rem', {
            matricule: matricule,
            structure: structure,
            filter: filter
      },
      ).then(function(data) {
        window.location.assign('/export/' + data);
      });
    }

    public exportSyntheseRemuneration(matricule: string): ng.IPromise<any> {
      return this.commonService.httpGet(
        '/api/exports/syntheserem', {matricule: matricule},
      ).then(function(data) {
        window.location.assign('/export/' + data);
      });
    }
  }
}
appDashboard.service('ExportsService', services.ExportsService);


