/// <reference path="../appDashboard.ts" />
module controllers {

  import IUsersService = services.IUsersService;
  import IUser = models.IUser;
  import ICommonService = services.ICommonService;

  export class DashboardLoginController implements angular.IController {

    $onInit = () => { };

    private $scope: ng.IScope;
    private $window: any;
    private $state: ng.ui.IStateService;
    private usersService: IUsersService;
    private commonService: ICommonService;
    private showError: boolean;

    public userList: IUser[];

    public static $inject = [
      '$scope',
      '$state',
      'UsersService',
      'CommonService',
      '$window',

    ];

    constructor(
        $scope: ng.IScope, $state: ng.ui.IStateService,
        usersService: IUsersService, commonService: ICommonService,
        $window: any) {
      this.$scope = $scope;
      this.$state = $state;
      this.$window = $window;
      this.usersService = usersService;
      this.showError = false;
      this.commonService = commonService;

      this.reset();
    }

    private reset() {
    }

    public login(login: string, password: string) {
      this.showError = false;
      this.commonService.httpPost('/api/users/login/',
          {login: login, password: password}).then(
          function(data: any) {
            this.$window.location.href = '/support';
          }.bind(this),
          function(err: any) {
            this.showError = true;
          }.bind(this),
      );
    }
  }
}

appDashboard.controller('DashboardLoginController',
    controllers.DashboardLoginController);
