/// <reference path="../appDashboard.ts" />7
module services {
  'use strict';

  export interface IChartsService {
    getChart(seriesObjectList: any, chartOption: IChartOption): any;

    defaultHighchartsOption: any
  }

  export interface IChartOption {
  }

  export interface ISeriesObjectList {
    data: number[]
    seriesName: string
    fillColor1: any
    fillColor2: any
    color: string
    valueSuffix: string
    tooltip: any
  }

  declare var Highcharts: any;

  export class ChartsService implements IChartsService {

    public defaultHighchartsOption: any;

    constructor() {
      this.defaultHighchartsOption = {
        lang: {
          decimalPoint: ',',
          thousandsSep: ' ',
        },
      };
    }

    /**
     * round values in array
     *
     * @param array
     * @returns {Array}
     */
    private roundArray(array: any[]): any[] {
      var res: any[];
      res = [];
      angular.forEach(array, function(data) {
        res.push(Math.round(data));
      });
      return res;
    }

    /**
     * format graph data and return them
     *
     * @param seriesObjectList
     * @param chartOption
     * @returns {{chart: {type: string, margin: number[]}, title: {text: string}, legend: {enabled: boolean}, xAxis: {gridLineColor: string, gridLineWidth: number, gridZIndex: number, tickLength: number, tickmarkPlacement: string, categories: string[], labels: {style: {color: string, textTransform: string, fontSize: string, fontWeight: string, fontFamily: string}}}, yAxis: {title: {text: string}, gridLineColor: string, minorGridLineColor: string, minorTickInterval: number, minorTickLength: number, minorTickColor: string, tickColor: string, minorTickWidth: number, minorTickPosition: string, showFirstLabel: boolean, showLastLabel: boolean, offset: number, labels: {style: {color: string, textTransform: string, fontSize: string, textAlign: string, fontFamily: string}, formatter: (function(): *)}}, zAxis: {gridLineColor: string, gridLineWidth: number, gridZIndex: number}, tooltip: {shared: boolean, borderWidth: number, valueSuffix: string}, plotOptions: {series: {stickyTracking: boolean}}, credits: {enabled: boolean}, exporting: {enabled: boolean}, series: any[]}}
     */
    public getChart(seriesObjectList: any, chartOption: IChartOption): any {

      var res = {

        chart: {
          type: 'areaspline',
          margin: [0, 0, 100, 0],
        },
        title: {
          text: '',
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          gridLineColor: '#f7f8f9',
          gridLineWidth: 1,
          gridZIndex: 0,
          tickLength: 0,
          tickmarkPlacement: 'between',
          categories: [
            'Période 01',
            'Période 02',
            'Période 03',
            'Période 04',
            'Période 05',
            'Période 06',
            'Période 07',
            'Période 08',
            'Période 09',
            'Période 10',
            'Période 11',
            'Période 12',
          ],

          labels: {
            style: {
              color: '#48494d',
              textTransform: 'uppercase',
              fontSize: '11px',
              fontWeight: '600',
              fontFamily: 'Lato',
            },
          },
        },

        yAxis: {
          title: {
            text: '',
          },

          gridLineColor: '#f7f8f9',
          minorGridLineColor: '#f7f8f9',
          minorTickInterval: 2000,
          minorTickLength: 10,
          minorTickColor: '#a0abb7',
          minorTickWidth: 2,
          minorTickPosition: 'inside',
          tickColor: '#a0abb7',
          showFirstLabel: false,
          showLastLabel: false,
          offset: 0,
          labels: {
            x: 5,
            y: 0,
            style: {
              color: '#6f7a8b',
              textTransform: 'uppercase',
              fontSize: '10px',
              textAlign: 'right',
              fontFamily: 'Lato',
            },
            formatter: function() {
              return Highcharts.numberFormat(this.value, 0, ' ', ',');
            },
          },
        },
        zAxis: {
          gridLineColor: '#f7f8f9',
          gridLineWidth: 1,
          gridZIndex: 1,
        },
        tooltip: chartOption,
        plotOptions: {
          series: {
            stickyTracking: true,
          },
        },
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: false,
        },

        series: [],

      };

      angular.forEach(seriesObjectList,
          function(currentSeriesObject: ISeriesObjectList) {
            res.series.push({
              name: currentSeriesObject.seriesName || '',
              data: this.roundArray(currentSeriesObject.data),
              color: currentSeriesObject.color,
              fillOpacity: 0.1,
              fillColor: {
                linearGradient: [0, 0, 0, 450],
                stops: [
                  currentSeriesObject.fillColor1
                      ? currentSeriesObject.fillColor1
                      : [
                        0,
                        'rgba(165,5,224,0.6)'],
                  currentSeriesObject.fillColor2
                      ? currentSeriesObject.fillColor2
                      : [
                        1,
                        'rgba(226,191,255,0.6)'],
                ],
              },
              marker: {
                fillColor: '#FFFFFF',
                lineWidth: 3,
                lineColor: currentSeriesObject.color,
                radius: 6,
                symbol: 'circle',
              },
              lineWidth: 1,
              tooltip: currentSeriesObject.tooltip
                  ? currentSeriesObject.tooltip
                  : chartOption,
            });
          }.bind(this));

      return res;
    }
  }
}
appDashboard.service('ChartsService', services.ChartsService);