/// <reference path="../appDashboard.ts" />

var myInformationBlock = function () {
    return {
        restrict : 'E',
        replace: false,
        scope: {
            promiseUserPopulated: '=',
            promiseCommercialCalendar: '='
        },
        controller : 'DashboardMenuBlockController as menuBlockController',
        templateUrl : '/templates/my-information-block.html',
    };
};

appDashboard.directive("myInformationBlock", [myInformationBlock]);
