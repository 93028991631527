/// <reference path="../appDashboard.ts" />7
module services {
    'use strict';
    export interface IEventsService {
        getEventBy(name: string): string
    }
    export class EventsService implements IEventsService {
        events = {};
        constructor() {
            this.events = {
                data: "DATA_UPDATE",
                number: "NUMBER_PAGE",
                goto: "GOTO_PAGE",
                filter: "FILTER_BY",
                expand: "EXPAND",
                hide: "HIDE",
                sort: "SORT"
            }
        }

        public SEARCH_LIMIT = 3;

        /**
         * get event
         * @param name
         * @returns {string}
         */
        public getEventBy(name: string): string {
            return this.events[name];
        }
    }
}
appDashboard.service("EventsService", services.EventsService);


