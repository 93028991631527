/// <reference path="../appDashboard.ts" />7
module models {
    'use strict';
    export interface IActivity{
        periode : ICommercialCalendar
        personalTotalTurnover : number
        personalKiotisTurnover : number
        personalStanhomeTurnover : number
        personalFamilyExpertTurnover : number
        personalFlormarTurnover : number
        personalOrderNumber : number
        n1TotalTurnover : number
        n1KiotisTurnover : number
        n1StanhomeTurnover : number
        n1FamilyExpertTurnover : number
        n1FlormarTurnover : number
        n1OrderNumber : number
        n2TotalTurnover : number
        n2KiotisTurnover : number
        n2StanhomeTurnover : number
        n2FamilyExpertTurnover : number
        n2FlormarTurnover : number
        n2OrderNumber : number
        n3TotalTurnover : number
        n3KiotisTurnover : number
        n3StanhomeTurnover : number
        n3FamilyExpertTurnover : number
        n3FlormarTurnover : number
        n3OrderNumber : number
        weekActivityNumber : number
        directCommissionAmount : number
        indirectCommissionAmountN1P2R : number
        indirectCommissionAmountN2P2R : number
        indirectCommissionAmountForced : number
        teamTotalTurnover: number
        teamKiotisTurnover: number
        teamStanhomeTurnover: number
        teamFamilyExpertTurnover: number
        teamFlormarTurnover: number
        teamOrderNumber : number
        sectorTotalTurnover: number
        sectorKiotisTurnover: number
        sectorStanhomeTurnover: number
        sectorFamilyExpertTurnover: number
        sectorFlormarTurnover: number
        sectorOrderNumber : number
        divisionTotalTurnover: number
        divisionKiotisTurnover: number
        divisionStanhomeTurnover: number
        divisionFamilyExpertTurnover: number
        divisionFlormarTurnover: number
        divisionOrderNumber : number
        divisionObjectifNumber : number
        divisionTotalTurnoverReference : number
        regionTotalTurnover: number
        regionKiotisTurnover: number
        regionStanhomeTurnover: number
        regionFamilyExpertTurnover: number
        regionFlormarTurnover: number
        regionOrderNumber : number
        regionObjectifNumber : number
        regionTotalTurnoverReference : number
        personalRicaudTurnover: number
        teamRicaudTurnover: number
        sectorRicaudTurnover: number
        divisionRicaudTurnover: number
        regionRicaudTurnover: number
        n1RicaudTurnover: number
        n2RicaudTurnover: number
        n3RicaudTurnover: number

    }
}


