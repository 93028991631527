/// <reference path="../appDashboard.ts" />
var menuBlock = function () {
    return {
        restrict: 'E',
        replace: false,
        scope: {
            promiseUserPopulated: "=",
            promiseCommercialCalendar: "="
        },
        templateUrl: '/templates/menu-block.html',
        controller: 'DashboardMenuBlockController as menuBlockController',
    };
};

appDashboard.directive("menuBlock", menuBlock);
