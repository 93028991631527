/// <reference path="../appDashboard.ts" />

var centralBlock = function () {
    return {
        restrict : 'E',
        replace: false,
        scope: {
            promiseUserPopulated: '=',
            promiseCommercialCalendar: '=',
            pagination: '='
        },
        controller : 'DashboardCentralBlockController as centralBlockController',
        templateUrl : '/templates/central-block.html',
    };
};

appDashboard.directive("centralBlock", [centralBlock]);
