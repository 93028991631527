/// <reference path="../appDashboard.ts" />7
module services {
  'use strict';
  import IPromise = angular.IPromise;
  import ICommission = models.ICommission;
  import IUser = models.IUser;
  import IUserPopulated = models.IUserPopulated;

  export interface ICommissionsService {
    getByMatricule(matricule: string): IPromise<any>;

    getPersonalCommission(commissionList: ICommission[]): ICommission

    getSubCommissionListObject(
        commissionList: ICommission[], level: string,
        commissionType: string): any;

    getTotalCommission(
        n1P2R: any, n2P2R: any, n1Forced: any, n2Forced: any): number
  }

  export class CommissionsService implements ICommissionsService {

    private $http: ng.IHttpService;
    private $q: ng.IQService;
    private commonService: ICommonService;
    private promise_getByMatricule: IPromise<any>[] = [];
    private base_url: string = '/api/commissions';

    public static $inject = [
      '$http',
      '$q',
      'CommonService',
    ];

    /**
     * @param $http
     * @param $q
     * @param commonService
     */
    constructor(
        $http: ng.IHttpService, $q: ng.IQService,
        commonService: ICommonService) {
      this.commonService = commonService;
    }

    /**
     * Get user commission
     * @param matricule
     * @returns {ng.IPromise<any>}
     */
    public getByMatricule(matricule: string): ng.IPromise<any> {
      let promiseString = matricule;
      if (!this.promise_getByMatricule[promiseString]) {
        this.promise_getByMatricule[promiseString] = this.commonService.httpGet(this.base_url +
            '/getByMatricule/' + matricule, {});
      }
      return this.promise_getByMatricule[promiseString];
    }

    /**
     * get commision with level n0 in commissionList
     *
     * @param commissionList
     * @returns {ICommission}
     */
    public getPersonalCommission(commissionList: ICommission[]): ICommission {
      let selectedCommission: ICommission;
      angular.forEach(commissionList, function(commission: ICommission) {
        if (commission.level === 'N0') {
          selectedCommission = commission;
        }
      });
      return selectedCommission;
    }

    /**
     * find parent from the child's matricule in team array
     *
     * @param team
     * @param matriculeChild
     * @returns {any}
     */
    private findParent(team: any[], matriculeChild: string): any {
      let res, tempParent: any;
      angular.forEach(team, function(user_n1: any) {
        tempParent = user_n1;
        angular.forEach(user_n1.team, function(user_n2: any) {
          if (user_n2.matricule === matriculeChild) {
            res = tempParent;
          }
        });
      });
      return res;
    }

    /**
     *
     * @param commissionList
     * @param level
     * @param commissionType
     * @param team
     * @returns {any}
     */
    public getSubCommissionListObject(
        commissionList: ICommission[], level: string,
        commissionType: string): any {
      let subCommissionObject: any = {
        personalTurnoverSum: 0,
        commissionRate: null,
        commissionAmountSum: 0,
        list: [],
      };
      angular.forEach(commissionList, function(commission: ICommission) {
        if (commission.level === level &&
            commission.commissionType === commissionType) {
          if (!isNaN(commission.personalTurnover)) {
            subCommissionObject.personalTurnoverSum += commission.personalTurnover;
          } else {
            console.error('commission.personalTurnover is NAN',
                commission.personalTurnover);
          }
          if (!isNaN(commission.commissionRate)) {
            subCommissionObject.commissionRate = commission.commissionRate;
          } else {
            console.error('commission.commissionRate is NAN',
                commission.commissionRate);
          }
          if (!isNaN(commission.commissionAmount)) {
            subCommissionObject.commissionAmountSum += commission.commissionAmount;
          } else {
            console.error('commission.commissionAmount is NAN',
                commission.commissionAmount);
          }
          subCommissionObject.list.push(commission);
        }
      }.bind(this));
      return subCommissionObject;
    }

    /**
     * Return the sum of all commission
     * @param n1P2R
     * @param n2P2R
     * @param n1Forced
     * @param n2Forced
     * @returns {any}
     */
    public getTotalCommission(
        n1P2R: any, n2P2R: any, n1Forced: any, n2Forced: any): number {
      return n1P2R.commissionAmountSum + n2P2R.commissionAmountSum +
          n1Forced.commissionAmountSum + n2Forced.commissionAmountSum;

    }

  }
}
appDashboard.service('CommissionsService', services.CommissionsService);


