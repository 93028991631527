module controllers {

  import IUserPopulated             = models.IUserPopulated;
  import IUsersService              = services.IUsersService;
  import IUser                      = models.IUser;
  import ICommercialCalendarService = services.ICommercialCalendarsService;
  import ICommercialCalendar        = models.ICommercialCalendar;
  import IExportsService            = services.IExportsService;
  import IP2RService                = services.IP2RService;
  import IActivity                  = models.IActivity;
  import IEventsService             = services.EventsService;
  import IDisplaysArrayService      = services.IDisplaysArrayService;

  export class DashboardTeamActivityBlockController implements angular.IController {

    $onInit = () => { };
    private $scope: any;
    private $state: ng.ui.IStateService;
    private usersService: IUsersService;
    private activitiesService: IActivitiesService;
    private commercialCalendarService: ICommercialCalendarService;
    private p2RService: IP2RService;
    private $q: ng.IQService;
    public userPopulated: IUserPopulated;
    public subUserList: IUserPopulated[];
    public subUserCount: any;
    public activesCount: any;
    public userTeamObject: any;
    public userSubListParams: any;
    public currentCycle: ICommercialCalendar;
    public currentCycleShortName: string;
    public exportsService: IExportsService;
    public currentPage: number;
    public pageSize: number;
    public limitNumber: number;
    public limitNumber_turnover: number;
    public subUserTypologyList: string[];
    public subUserLevelList: number[];
    public subUserPerformanceList: number[];
    public titleNewTab: string;
    public currentCycleNumber: number;
    public commercialCalendarList: ICommercialCalendar[];
    public nMinus1Cycle: ICommercialCalendar;
    public afterNMinus1Cycle: ICommercialCalendar;
    public tableHeaders: any;
    public periods: string[];
    private eventsService: IEventsService;
    private displaysArrayService: IDisplaysArrayService;
    private filter: any;

    public static $inject = [
      '$scope',
      '$state',
      'UsersService',
      'ActivitiesService',
      'CommercialCalendarsService',
      'ExportsService',
      'P2RService',
      '$q',
      'EventsService',
      'DisplaysArrayService',
    ];

    /**
     *
     * @param $scope
     * @param $state
     * @param usersService
     * @param commercialCalendarService
     * @param exportsService
     * @param p2RService
     */
    constructor($scope: any,
                $state: ng.ui.IStateService,
                usersService: IUsersService,
                activitiesService: IActivitiesService,
                commercialCalendarService: ICommercialCalendarService,
                exportsService: IExportsService,
                p2RService: IP2RService, $q: ng.IQService,
                eventsService: IEventsService,
                displaysArrayService: IDisplaysArrayService) {
      this.$scope = $scope;
      this.$state = $state;
      this.usersService = usersService;
      this.activitiesService = activitiesService;
      this.commercialCalendarService = commercialCalendarService;
      this.exportsService = exportsService;
      this.p2RService = p2RService;
      this.$q = $q;
      this.eventsService = eventsService;
      this.displaysArrayService = displaysArrayService;
      this.filter = [];
      this.$scope.$on(this.eventsService.getEventBy('goto'), function(event, data) {
        this.setPage(data)
      }.bind(this));
      this.$scope.$on(this.eventsService.getEventBy('filter'), function(event, data) {
        this.setSelectedSubListParams(data.sort, data.sens === 1)
      }.bind(this));

      this.initVariable();

      this.$q.all(
          [$scope.promiseUserPopulated, $scope.promiseCommercialCalendar]).
          then(function(data: any) {
            this.init(data[0], data[1]);
          }.bind(this));

    }

    public initPeriods(){
      const periods = this.subUserList.map((user: IUserPopulated)=>{
        return user.period
      })
     return periods.filter(function (valeur, index, self) {
        return self.indexOf(valeur) === index;
      })
    }
    private initVariable() {
      this.userSubListParams = {
        expression: 'inactivesWeekNumber',
        reverse: true,
        filter: {
          isActive: this.$scope.filters && this.$scope.filters.isActive
              ? this.$scope.filters.isActive
              : true,
          periodOrTri: this.$scope.filters &&
          this.$scope.filters.periodOrTri != 'null'
              ? this.$scope.filters.periodOrTri
              : '!!',
          typology: this.$scope.filters &&
          this.$scope.filters.typology != 'null'
              ? this.$scope.filters.typology
              : '!!',
          childLevelForParent: this.$scope.filters &&
          this.$scope.filters.childLevelForParent != 'null'
              ? this.$scope.filters.childLevelForParent
              : '!!',
          personalPerformanceIndicator: this.$scope.filters &&
          this.$scope.filters.personalPerformanceIndicator != 'null'
              ? this.$scope.filters.personalPerformanceIndicator
              : '!!',
          statut: '!DEM',
        },
      };

      this.tableHeaders = [
        {label: "NOM PRÉNOM", key: "lastName", sort: true, class: this.displaysArrayService.getClassBy('desktop')},
        {label: "NOM", key: "lastNameMob", sort: true, class: this.displaysArrayService.getClassBy('mobile')},

        {label: "SUIVI", key: "follow", sort: false, class: this.displaysArrayService.getClassBy('desktop')},
        {label: "TYPE", key: "type", sort: true, class: this.displaysArrayService.getClassBy('desktop')},
        {label: "NIV", key: "level", sort: true, class: this.displaysArrayService.getClassBy('desktop')},
        {label: "NB CMD DU Période", key: "commands", sort: true, class: this.displaysArrayService.getClassBy('desktop')},
        {label: "DERN. CMD", key: "lastCommand", sort: true, class: this.displaysArrayService.getClassBy('desktop')},

        {label: "CA Période", key: "caCycle", sort: true, class: this.displaysArrayService.getClassBy('desktop')},
        {label: "CA.", key: "ca", sort: true, class: this.displaysArrayService.getClassBy('mobile')},

        {label: "PERFORMANCE", key: "perf", sort: true, class: this.displaysArrayService.getClassBy('desktop')},
        {label: "PERF", key: "perf", sort: true, class: this.displaysArrayService.getClassBy('mobile')},
      ];

      this.currentPage = 0;
      this.pageSize = 10;
      this.limitNumber = 0;
      this.limitNumber_turnover = 2;
      this.titleNewTab = this.setTitle(this.userSubListParams.filter);
    }

    public setPage(index: number) {
      this.currentPage = index;
    }

    /**
     *
     * @param userPopulated
     * @param commercialCalendarList
     */
    private init(
        userPopulated: IUserPopulated,
        commercialCalendarList: ICommercialCalendar[]) {
      this.userPopulated = userPopulated;
      // Search cycle forced else return cycle by date
      this.commercialCalendarList = commercialCalendarList;
      this.currentCycle = this.commercialCalendarService.getCurrentCycleForced(
          commercialCalendarList);

      this.usersService.getSubUserByMatricule(userPopulated.matricule,
          userPopulated.GSDRValue).then(
          function(subUserListObject) {
            // TODO
            //this.subUserList            = this.usersService.setOrderNumberCurrentPeriodToSubUserList(this.usersService.getAllSubUserList(this.userPopulated), this.currentCycle);
            this.subUserCount = subUserListObject.count;
            this.subUserList = subUserListObject.list;
            this.activesCount = subUserListObject.activesCount;
            angular.forEach(this.subUserList, function(user: IUser) {
              user.orderNumberCurrentPeriod = this.activitiesService.getOrderNumber(
                  user.currentActivity, 'n0');
            }.bind(this));
            this.userTeamObject = this.usersService.filterTeamForSubUserList(
                subUserListObject.list, userPopulated.matricule);
            this.subUserTypologyList = this.usersService.getAllSubUserTypologyList(
                subUserListObject.list);
            this.subUserLevelList = this.usersService.getAllSubUserLevelList(
                subUserListObject.list, userPopulated);
            this.periods = this.initPeriods();

          }.bind(this),
      );

      if (this.userPopulated)
        if (!this.currentCycle) {
          console.error('Current Cycle not defined');
        } else {
          this.currentCycleShortName = this.commercialCalendarService.getShortNameCycle(
              this.currentCycle);
          this.currentCycleNumber = this.commercialCalendarService.getCycleNumber(
              this.currentCycle);
          this.nMinus1Cycle = this.commercialCalendarService.getNMinus1Cycle(
              commercialCalendarList, this.currentCycle);
          this.afterNMinus1Cycle = this.commercialCalendarService.getCycleAfterNMinus1Cycle(
              commercialCalendarList, this.currentCycle);

        }
      this.userSubListParams.filter.isActive = '';
      if (!this.canSeeFilterDefault()) {
        this.userSubListParams.filter.childLevelForParent = 'team';
      } else {
      }
      if (this.$scope.$root.$$phase != '$apply' &&
          this.$scope.$root.$$phase != '$digest') {
        this.$scope.$apply();
      }
    }

    /**
     * angular filter for the performance
     * @returns {(item:any)}
     */
    public filterPerformance(filterSelected: any) {
      return function(item: any) {
        switch (filterSelected) {
          case  'red':
            if (item.personalPerformanceIndicator < 0.5) {
              return true;
            } else {
              return false;
            }
            break;
          case  'orange':
            if (item.personalPerformanceIndicator >= 0.5 &&
                item.personalPerformanceIndicator <= 1) {
              return true;
            } else {
              return false;
            }
            break;
          case  'green':
            if (item.personalPerformanceIndicator > 1) {
              return true;
            } else {
              return false;
            }
            break;
          default:
            return true;
            break;
        }
      };
    }

    public goToDashboard(matricule: string): void {
      var url = this.$state.href('seeDashboard', {matricule: matricule});
      window.open(url,'_blank');
    }

    public getSubUserLevel(child: IUser) {
      if (this.userPopulated) {
        if (child.parentMatricule == this.userPopulated.matricule) {
          return '1';
        } else if (child.grandParentMatricule == this.userPopulated.matricule) {
          return '2';
        } else {
          return '3 et +';
        }
      }
    }

    /**
     * angular filter for the performance
     * @returns {(item:any)}
     */
    public filterChildType(childLevelForParent) {
      return function(item) {
        if (this.userPopulated) {
          switch (childLevelForParent) {
            case  '1':
              return item.parentMatricule == this.userPopulated.matricule;
              break;
            case  '2':
              return item.grandParentMatricule == this.userPopulated.matricule;
              break;
            case  '3':
              return !(item.parentMatricule == this.userPopulated.matricule ||
                  item.grandParentMatricule == this.userPopulated.matricule);
              break;
            case  '4':
              return item.parentMatricule == this.userPopulated.matricule ||
                  item.grandParentMatricule == this.userPopulated.matricule;
              break;
            case  'team':
              return item.group == this.userPopulated.group;
              break;
            case  'sector':
              return item.sector == this.userPopulated.sector;
              break;
            default:
              return true;
              break;
          }
        }
      }.bind(this);
    }

    public filterBy(attributName, attibutValue) {
      this.currentPage = 0;
      this.filter[attributName] = attibutValue;
    }

    /**
     * angular filter for the lastName
     * @returns {(item:any)}
     */
    public filterName() {
      return function(item) {
        const searchName = new RegExp(['^.*', this.filter.name, '.*$'].join(''), 'i');
        return searchName.test(item.lastName) || searchName.test(item.firstName) || searchName.test(item.matricule);
      }.bind(this);
    }

    /**
     * angular filter for period
     * @returns {(item:any)}
     */
    public filterPeriod() {
      return function(item) {
        if(this.userSubListParams.filter.periodOrTri === 'period'){
          return item.period.split('-')[1].charAt(0) == 'P'
        } else if (this.userSubListParams.filter.periodOrTri === 'trim'){
          return item.period.split('-')[1].charAt(0) == 'T';
        } else {
          return item.period === this.userSubListParams.filter.periodOrTri
        }
      }.bind(this);
    }

    /**
     * angular filter for actif
     * @returns {(item:any)}
     */
    public filterActif(){
      return function(item){
        if (this.userSubListParams.filter.isActive === ''){
          return item.active >= 0;
        }
        if (this.userSubListParams.filter.isActive){
          return item.active > 0;
        } else{
          return item.active == 0;
        }
      }.bind(this);
    }

    /**
     * return only team
     * @returns {(item:any)}
     */
    public filterChildInTeam() {
      return function(item) {
        return item.childLevelForParent == 1 || item.childLevelForParent == 2;
      };
    }

    public eventSelect() {
      this.currentPage = 0;
    }

    /**
     * Tooltip contentieux
     */
    public getLabel(statut: string) {
      switch (statut) {
        case 'CTX':
          return 'Statut Contentieux : Prise de commande bloquée';
          break;
        case 'DRD':
          return 'Commande autorisée mais facturation effectuée qu\'après régularisation';
          break;
      }
    }

    /**
     * set value filter active
     *
     * @param active
     */
    public setActiveFilter(active) {
      if(active !== '') {
        active = active === 'true';
      }
      this.userSubListParams.filter.isActive = active;
      this.activesCount = active ? this.subUserList.filter((ele) => ele.active > 0).length : 0;
    }

    public setTotalCount(array: any[]){
      if(!array){
        return 0;
      }
      const arrayUnique = new Set();
      array.forEach(ele => {
        const key = `${ele.firstName} ${ele.lastName}`;
        arrayUnique.add(key);
      });
      return arrayUnique.size;
    }
    public setActifCount(array: any[]){
      if(!array){
        return 0;
      }
      const arrayUnique = new Set();
      array.forEach(ele => {
        if (ele.active == 1) {
          const key = `${ele.firstName} ${ele.lastName}`;
          arrayUnique.add(key);
        }
      });
      return arrayUnique.size;
    }

    /**
     * set value filter period
     *
     * @param period
     */
    public setPeriodFilter(_periodOrTri) {
      let periodOrTri = _periodOrTri;
      this.userSubListParams.filter.periodOrTri = periodOrTri;
      if (periodOrTri === 'period' || periodOrTri === 'trim') {
        this.activesCount = (typeof periodOrTri == 'string') || (periodOrTri == undefined) ? this.subUserList.filter((ele) => ele.active == 1).length
            : (periodOrTri === 'period'
                    ? this.subUserList.filter((ele) => ele.active == 1 && ele.period.split('-')[1].charAt(0) == 'P').length
                    : this.subUserList.filter((ele) => ele.active == 1 && ele.period.split('-')[1].charAt(0) == 'T').length
            )
      } else {
        this.activesCount = this.subUserList.filter((ele) => ele.active == 1 && ele.period === periodOrTri).length
      }
    }

    public sortBy() {
      return function(item) {
        if(this.userSubListParams.expression == 'periodOrTri'){
          const aperiode = Number(item.period.split("-")[0]);
          const atime = Number(item.period.split("-")[1].substring(1));
          if(this.userSubListParams.reverse){
            return 1/(aperiode*10 + atime+1);
          } else {
            return   aperiode*10 + atime;
          }
        }

      }.bind(this);
    }

    public getFilter() {
      let filter = {
        typology : this.userSubListParams.filter.typology,
        statut : this.userSubListParams.filter.statut
      };
      return filter;
    }

    /**
     * set values of selected params to orderBy Filter
     *
     * @param expression
     * @param reverse
     */
    public setSelectedSubListParams(expression: string, reverse: boolean) {
      this.userSubListParams.expression = expression;
      this.userSubListParams.reverse = reverse;
    }

    public canSeeFilterDefault(): boolean {
      if (this.userPopulated) {
        return !(this.usersService.isTeamLead(this.userPopulated) &&
            !this.canSeeFilterMySector() && !this.canSeeFilterMyLevel(null));
      }
    }

    public canSeeFilterMySector(): boolean {
      if (this.userPopulated) {
        return this.usersService.isSectorLead(this.userPopulated);
      }
    }

    public canSeeFilterMyTeam(): boolean {
      if (this.userPopulated) {
        return this.usersService.isTeamLead(this.userPopulated);
      }
    }

    public canSeeFilterMyLevel(level): boolean {
      if (this.userPopulated) {
        return !this.usersService.isTeamLead(this.userPopulated);
      }
    }

    public showNiveau() {
      if (this.userPopulated) {
        return !this.usersService.isTeamLead(this.userPopulated);
      }
    }

    public isCoach() {
      if (this.userPopulated) {
        return (!this.usersService.isTeamLead(this.userPopulated) &&
            !this.usersService.isSectorLead(this.userPopulated));
      }
    }

    /**
     * to export data in excel
     *
     */
    public exportExcel() {
      this.exportsService.exportTeam(this.userPopulated.matricule,
          this.userPopulated.GSDRValue);
    }

    /**
     * to see data in new tab
     *
     * @param filter
     */
    public goTabView(filter: any) {
      var a = document.createElement('a');
      a.href = '#!/tab/team/' + this.userPopulated.matricule + '?filter=' +
          filter.isActive + '&filter=' + filter.childLevelForParent +
          '&filter=' + filter.typology + '&filter=' +
          filter.personalPerformanceIndicator;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
    }

    /**
     * set title
     *
     * @param filters
     * @return {string}
     */
    public setTitle(filters: any): string {
      var titleNewTab = 'Liste de votre équipe ';
      if (filters.isActive == 'true') {

        titleNewTab += ' active ';
      }
      if (filters.isActive == 'false') {
        titleNewTab += ' inactive ';
      }
      if (filters.periodOrTri == 'true') {
        titleNewTab += ' Period ';
      }
      if (filters.periodOrTri == 'false') {
        titleNewTab += ' Trimestre ';
      }
      if (filters.typology !== '!!' || filters.childLevelForParent !== '!!' ||
          filters.personalPerformanceIndicator !== '!!') {
        titleNewTab += ' avec les filtres : ';
      }
      if (filters.childLevelForParent !== '!!') {
        titleNewTab += 'niveau ' + filters.childLevelForParent;
      }
      if (filters.typology !== '!!' && filters.childLevelForParent !== '!!') {
        titleNewTab += ', ';
      }
      if (filters.typology !== '!!') {
        titleNewTab += 'typologie ' + filters.typology.toLowerCase();
      }
      if (filters.typology !== '!!' &&
          filters.personalPerformanceIndicator !== '!!') {
        titleNewTab += ', ';
      }
      if (filters.personalPerformanceIndicator !== '!!') {
        titleNewTab += 'performance ' + filters.personalPerformanceIndicator;
      }
      return titleNewTab;

    }

    public displayTeamActivityBlock() {
      if (this.userPopulated && this.subUserList) {
        return (this.userPopulated.isSF && this.subUserList.length == 1 &&
            this.userPopulated.p2RLevel == 'Leader 2') ||
            (this.userPopulated.isSF && this.subUserList.length > 1) ||
            (this.subUserList.length > 1 &&
                this.usersService.getProfilType(this.userPopulated) == 'Ani');
      }
    }

    /**
     * Get number of pages for pagination
     *
     * @param array
     * @param pageSize
     * @return {number}
     */
    public numberOfPages(array: any[], pageSize: number): number {
      if (array && array.length > 0 && pageSize) {
        return Math.ceil(array.length / pageSize);
      }
    }

    /**
     * Get number
     *
     * @param num
     * @return {any[]}
     */
    public getNumber = function(num: number): any[] {
      return new Array(num);
    };

    /**
     *
     * @param array
     * @return {void}
     */
    public nextPage(array: any[]): void {
      if (!(this.currentPage >= array.length / this.pageSize - 1)) {
        this.currentPage = this.currentPage + 1;
      }
    }

    /**
     *
     * @return {void}
     */
    public previousPage(): void {
      if (!(this.currentPage == 0)) {
        this.currentPage = this.currentPage - 1;
      }
    }

    /**
     *
     * @param bestMax
     * @param monthP2R
     * @param currentLevel
     * @return {string}
     */
    public getColorMaxLevel(
        bestMax: string, monthP2R: string, currentLevel: string): string {
      if (bestMax === monthP2R) {
        if (this.p2RService.P2RToNumber(monthP2R) <
            this.p2RService.P2RToNumber(currentLevel)) {
          return 'purple';
        }
        else {
          return 'green';
        }
      }
      else {
        return 'white';
      }
    }

    /**
     *
     * @param bestMax
     * @param monthP2R
     * @param currentLevel
     * @return {string}
     */

    public oneCycleIsBetterThanCurrentLevel(
        bestMax: string, currentLevel: string): boolean {
      if (this.p2RService.P2RToNumber(bestMax) >
          this.p2RService.P2RToNumber(currentLevel)) {
        return true;
      }
      else {
        return false;
      }
    }

    /**
     * Check the best level on the 3 months of trimester
     *
     * @param P2R1 : Level month 1
     * @param P2R2 : Level month 2
     * @param P2R3 : Level month 3
     * @return {number}
     */
    public bestMonthP2R(P2R1: string, P2R2: string, P2R3: string) {
      return this.p2RService.getMaxP2RLevel([P2R1, P2R2, P2R3]);
    }

    /**
     * get number month or "mois en cours"
     *
     * @param offset
     * @param quarterName
     * @param periodeName
     * @return {string}
     */
    public getMonthNumberRelativeToCycle(
        offset: number, quarterName: string, periodeName: string): any {
      if (offset && quarterName && periodeName) {
        var monthNumber = this.commercialCalendarService.getMonthNumberRelativeToQuarter(
            offset, quarterName);
        if (parseInt(periodeName.substring(2, 3)) === monthNumber) {
          return 'en cours';
        }
        else {
          return monthNumber;
        }
      }
    }

    public isNumberActiveBetterThanP2RLevel(
        nbActive: number, p2rLevel: string) {
      return nbActive >= this.p2RService.P2RToNumber(p2rLevel);
    }

    /**
     *
     * @param P2R1 : Level month 1
     * @param P2R2 : Level month 2
     * @param P2R3 : Level month 3
     * @return {string}
     */
    public oneMonthIsMaxP2R(P2R1: string, P2R2: string, P2R3: string) {
      return this.p2RService.getMaxP2RLevel([P2R1, P2R2, P2R3]) ==
          this.p2RService.LEADER_35;
    }

    /**
     *
     * @param P2RLevel : level actual
     * @param P2R1 : Level month 1
     * @param P2R2 : Level month 2
     * @param P2R3 : Level month 3
     * @return {string}
     */
    public referencialAchieved(
        P2RLevel: string, P2R1: string, P2R2: string, P2R3: string) {
      return this.p2RService.getMaxP2RLevel([P2RLevel, P2R1, P2R2, P2R3]) ==
          this.p2RService.getMaxP2RLevel([P2R1, P2R2, P2R3]);
    }

    /**
     * return the personal turnover a the month last years
     * @param user
     * @returns {any}
     //  */
    // public getReferentialTurnover(user : IUserPopulated) : number{
    //     if(this.commercialCalendarService && this.currentCycle){
    //         var nMinus1Activity : IActivity =  this.activitiesService.getNMinus1Activity(user.activityList, this.commercialCalendarService.getNMinus1Cycle(this.commercialCalendarList, this.currentCycle));
    //         return nMinus1Activity ? nMinus1Activity.personalTotalTurnover : 0;
    //     }
    // }

  }
}

appDashboard.controller('DashboardTeamActivityBlockController',
    controllers.DashboardTeamActivityBlockController);
