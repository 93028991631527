/// <reference path="../appDashboard.ts" />7
module services {
    'use strict';
    import IPromise = angular.IPromise;
    import ICommonService = services.ICommonService;

    export interface IRemunerationsService {
        getByMatricule(matricule: number, sort: String, sortSens: number, limit: number, offset: number, filter: any, exportExcel?: string): IPromise<any>;
    }

    export class RemunerationsService implements IRemunerationsService {

        private $http: ng.IHttpService;
        private $q: ng.IQService;
        private commonService: ICommonService;
        private base_url: string = '/api/remunerations';

        public static $inject = [
            '$http',
            '$q',
            'CommonService',
        ];

        /**
         * @param $http
         * @param $q
         * @param commonService
         */
        constructor(
            $http: ng.IHttpService, $q: ng.IQService,
            commonService: ICommonService) {
            this.commonService = commonService;
        }

        /**
         * Get user remuneration
         * @param matricule
         * @param limit
         * @param offset
         * @param filter
         * @param structure
         * @returns {IPromise<any>}
         */
        public getByMatricule(matricule: number, sort: String, sortSens: number, limit: number, offset: number, filter: any, structure: string): IPromise<any> {
            return this.commonService.httpGet(this.base_url
                    + '/matricule/' + matricule,
                    {
                        'sort': sort,
                        'sortSens' : sortSens,
                        'limit': limit,
                        'offset': offset,
                        'structure': structure,
                        'filter': filter
                    });
            }
    }
}
appDashboard.service('RemunerationsService', services.RemunerationsService);


