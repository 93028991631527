module controllers {

  import IUserPopulated             = models.IUserPopulated;
  import IUsersService              = services.IUsersService;
  import IUser                      = models.IUser;
  import ICommercialCalendarService = services.ICommercialCalendarsService;
  import IExportsService            = services.IExportsService;
  import IP2RService                = services.IP2RService;
  import IPanService = services.IPanService;
  import IEventsService = services.EventsService;
  import IDisplaysArrayService = services.IDisplaysArrayService;

  export class DashboardFollowUpNewBlockController implements angular.IController {
    $onInit = () => { };

    private $scope: any;
    private $state: ng.ui.IStateService;
    private usersService: IUsersService;
    private commercialCalendarService: ICommercialCalendarService;
    private panService: IPanService;
    private p2RService: IP2RService;
    public exportsService: IExportsService;
    private eventsService: IEventsService;
    private displaysArrayService: IDisplaysArrayService;
    private $q: ng.IQService;
    public user: IUserPopulated;
    public count: number;
    public newList: IUser[];
    public numberOfItemByPage: number;
    public currentPage: number;
    public numberOfPages: number;
    public orderBySens: number;
    public orderByLabel: string;
    public weekObjectList: any;
    public display: boolean;
    private tableHeaders: any;
    private filterActive: boolean;
    private filter: any;

    public static $inject = [
      '$scope',
      '$state',
      'UsersService',
      'CommercialCalendarsService',
      'PanService',
      'P2RService',
      '$q',
      'ExportsService',
      'EventsService',
      'DisplaysArrayService',
    ];

    /**
     *
     * @param $scope
     * @param {angular.ui.IStateService} $state
     * @param {services.IUsersService} usersService
     * @param {services.ICommercialCalendarsService} commercialCalendarService
     * @param {services.IPanService} panService
     * @param {services.IP2RService} p2RService
     * @param {angular.IQService} $q
     * @param {services.IExportsService} exportsService
     * @param eventsService
     * @param displaysArrayService
     */
    constructor(
        $scope: any,
        $state: ng.ui.IStateService,
        usersService: IUsersService,
        commercialCalendarService: ICommercialCalendarService,
        panService: IPanService,
        p2RService: IP2RService,
        $q: ng.IQService,
        exportsService: IExportsService,
        eventsService: IEventsService,
        displaysArrayService: IDisplaysArrayService) {

      this.$scope = $scope;
      this.$state = $state;
      this.usersService = usersService;
      this.commercialCalendarService = commercialCalendarService;
      this.panService = panService;
      this.p2RService = p2RService;
      this.$q = $q;
      this.exportsService = exportsService;
      this.eventsService = eventsService;
      this.displaysArrayService = displaysArrayService;
      this.numberOfItemByPage = 10;
      this.currentPage = 0;
      this.orderBySens = 1;
      this.orderByLabel = null;
      this.display = false;
      this.filter = {};

      this.$scope.$on(this.eventsService.getEventBy('goto'), function(event, data) {
        this.setPage(data)
      }.bind(this));
      this.$scope.$on(this.eventsService.getEventBy('filter'), function(event, data) {
        this.setOrderBy(data.sort, data.sens)
      }.bind(this));

      this.$q.all(
          [$scope.promiseUserPopulated, $scope.promiseCommercialCalendar],
      ).then((data: any) => {
        this.user = data[0];
        this.initVariable(this.user);
      });
    }

    public filterModification(attributName, attibutValue) {
      this.currentPage = 0;
      this.filter[attributName] = attibutValue;
      this.initVariable(this.user);
    }

    private initVariable(user) {
      this.panService.getSubByMatricule(user.matricule, user.GSDRValue,
          this.orderByLabel, this.orderBySens, this.numberOfItemByPage,
          this.currentPage * this.numberOfItemByPage, this.filterActive, this.filter, false).then(
          data => {
            this.count = data.count;
            this.newList = data.list;

            let datas = [];
            this.newList.forEach((e) => {
              datas.push(this.extractData(e));
            });

            this.numberOfPages = Math.ceil(data.count /
                this.numberOfItemByPage);

            this.$scope.$broadcast(this.eventsService.getEventBy('sort'), {sort: this.orderByLabel, sortSens: this.orderBySens});
            this.$scope.$broadcast(this.eventsService.getEventBy('number'), this.numberOfPages);
            this.$scope.$broadcast(this.eventsService.getEventBy('data'), datas);

            if (this.newList.length > 0) {
              this.display = true;
            }
          },
      );

      this.weekObjectList = this.generateWeekObjectList(1, 24, 4);
    }

    private extractData(e) {
      return {
        follow: {display: this.displaysArrayService.getDisplayBy('gototxtless'), matricule: e.matricule, class: this.displaysArrayService.getClassBy('both')},
        lastName: {display: this.displaysArrayService.getDisplayBy('showmoreparent'), index: 0, value: e.lastName + " \n" + e.firstName, class: this.displaysArrayService.getClassBy('both')},
        phase: {display: this.displaysArrayService.getDisplayBy('default'), value: e.pan.phase, class: this.displaysArrayService.getClassBy('both')},
        first: {display: this.displaysArrayService.getDisplayBy('average'), value: e.pan['S1_S4'], class: this.displaysArrayService.getClassBy('desktop')},
        second: {display: this.displaysArrayService.getDisplayBy('average'), value: e.pan['S5_S8'], class: this.displaysArrayService.getClassBy('desktop')},
        third: {display: this.displaysArrayService.getDisplayBy('average'), value: e.pan['S9_S12'], class: this.displaysArrayService.getClassBy('desktop')},
        fourth: {display: this.displaysArrayService.getDisplayBy('average'), value: e.pan['S13_S16'], class: this.displaysArrayService.getClassBy('desktop')},
        fifth: {display: this.displaysArrayService.getDisplayBy('average'), value: e.pan['S17_S20'], class: this.displaysArrayService.getClassBy('desktop')},
        sixth: {display: this.displaysArrayService.getDisplayBy('average'), value: e.pan['S21_S24'], class: this.displaysArrayService.getClassBy('desktop')},
        //seventh: {display: this.displaysArrayService.getDisplayBy('average'), value: e.pan['S1_S12'], class: this.displaysArrayService.getClassBy('desktop')},
        //height: {display: this.displaysArrayService.getDisplayBy('average'), value: e.pan['S13_S24'], class: this.displaysArrayService.getClassBy('desktop')},
        entryCycle: {display: this.displaysArrayService.getDisplayBy('default'), value: e.pan.entryCycle, class: this.displaysArrayService.getClassBy('desktop')},
        finPhase: {display: this.displaysArrayService.getDisplayBy('date'), value: e.pan.finPhase, class: this.displaysArrayService.getClassBy('desktop')},
        activatedPhase: {display: this.displaysArrayService.getDisplayBy('roundcolor'), value: e.pan.activatedPhase, class: this.displaysArrayService.getClassBy('both')},
        userExpand: {0: e},
        expanded: {0: false}
      };
    }

    private filterByActive(filter) {
      this.filterActive = filter;
      this.initVariable(this.user);
    }

    private generateWeekObjectList(start, end, range) {
      this.tableHeaders = [
        {label: "SUIVI", key: "follow", sort: false, class: this.displaysArrayService.getClassBy('both')},
        {label: "NOM PRÉNOM", key: "lastName", sort: true, class: this.displaysArrayService.getClassBy('both')},
        {label: "PHASE", key: "phase", sort: true, class: this.displaysArrayService.getClassBy('both')},
      ];

      let res = [];
      for (let i = start; i <= end; i = i + range) {
        let _end = i + range - 1;
        res.push(
            {start: i, end: _end, label: 'S' + i + '_S' + _end});
        this.tableHeaders.push(
            {label: 'S' + i + ' à S' + _end, key: "S" + i + '_S' + _end, sort: false, class: this.displaysArrayService.getClassBy('desktop')})
      }

      //this.tableHeaders.push({label: 'S' + 1 + ' à S' + 12, key: "S" + 1 + '_S' + 12, sort: false, class: this.displaysArrayService.getClassBy('desktop')});
      //this.tableHeaders.push({label: 'S' + 13 + ' à S' + 24, key: "S" + 13 + '_S' + 24, sort: false, class: this.displaysArrayService.getClassBy('desktop')});
      this.tableHeaders.push({label: 'Période d\'entrée', key: "entryCycle", sort: true, class: this.displaysArrayService.getClassBy('both')});
      this.tableHeaders.push({label: 'Date fin de phase', key: "finPhase", sort: true, class: this.displaysArrayService.getClassBy('both')});
      this.tableHeaders.push({label: 'Phase actuelle validée', key: "activatedPhase", sort: true, class: this.displaysArrayService.getClassBy('both')});

      //res.push({start: 1, end: 12, label: 'S1_S12'});
      //res.push({start: 13, end: 24, label: 'S13_S24'});
      return res;
    }

    public goToDashboard(matricule: string): void {
      var url = this.$state.href('seeDashboard', {matricule: matricule});
      window.open(url,'_blank');
    }

    public exportExcel() {
      this.exportsService.exportPan(this.user.matricule, this.user.GSDRValue);
    }

    public changeP2RLevel() {
      this.currentPage = 0;
      this.initVariable(this.user);
    }

    public setOrderBy(orderByLabel, sens) {
      this.orderBySens = sens;
      this.orderByLabel = orderByLabel;
      this.initVariable(this.user);

    }

    public previousPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
        this.initVariable(this.user);
      }
    }

    public nextPage() {
      if (this.currentPage < this.numberOfPages - 1) {
        this.currentPage++;
        this.initVariable(this.user);
      }
    }

    public setPage(index: number) {
      this.currentPage = index;
      this.initVariable(this.user);
    }

    public getNumber = function(num: number): any[] {
      return new Array(num);
    };

  }
}

appDashboard.controller('DashboardFollowUpNewBlockController',
    controllers.DashboardFollowUpNewBlockController);
