/// <reference path="../appDashboard.ts" />7
module services {
  'use strict';
  import ICredential = models.ICredential;
  import IUser = models.IUser;
  import IPromise = angular.IPromise;
  import IUserPopulated = models.IUserPopulated;
  import ICommercialCalendarService = services.ICommercialCalendarsService;
  import ICommercialCalendar = models.ICommercialCalendar;
  import IActivity = models.IActivity;
  import IActivitiesService = services.IActivitiesService;
  import ITypologyActivity = models.ITypologyActivity;

  export interface IUsersService {
    loginByCredential(credential: ICredential): ng.IPromise<any>;

    getSubUserByMatricule(
        matricule: string, GSDRValue: number, quarterName?: string): ng.IPromise<any>

    logOut(): ng.IPromise<any>;

    logAs(user: IUser): ng.IPromise<any>;

    getUserPopulatedByMatricule(matricule: String): IPromise<any>;

    getAllSubUserList(userPopulated: IUserPopulated): IUserPopulated[]

    getMe(): IPromise<any>;

    getAllSubUserTypologyList(subUserList: IUserPopulated[]): string[];

    getProfilInterface(user: IUser | IUserPopulated): string

    getAllSubUserListTeam(userPopulated: IUserPopulated): IUserPopulated[]

    getAllSubUserLevelList(
        subUserList: IUserPopulated[], userPopulated: IUserPopulated): number[]

    setOrderNumberCurrentPeriodToSubUserList (
        subUserList: IUserPopulated[],
        currentCycleForced: ICommercialCalendar): IUserPopulated[]

    getAllSubUserPerformanceList(subUserList: IUserPopulated[]): number[]

    getLevelSelectOptions(user: IUserPopulated): any;

    getProfilType(user: IUserPopulated): string;

    getTypologyColor(typology: string): string;

    getProfilInterface(user: IUser | IUserPopulated): string;

    getListTeamLeadersOfManager(
        userPopulated: IUserPopulated, sort: String, sortSens: number,
        limit: number, offset: number): IPromise<any>

    getTypologyListOfTypologyActivityList(typologyActivityList: ITypologyActivity[]): string[];

    getProfileList(profileList: any): string[];

    getLevelList(levelList: any[]): string[];

    isManager(user: IUserPopulated): boolean;

    isDr(user: IUserPopulated): boolean;

    getLeaderListByGSDRValue(
        GSDRValue: any, sort: String, sortSens: number, limit: number,
        offset: number, p2RLevelFilter: string, divisionNameFilter: string,
        isActiveFilter: string, nameFilter: string, quarterName?: string): IPromise<any>

    isSectorLead(user: IUserPopulated): boolean;

    getNumberActiveAllSubUserListTeam(userPopulated: IUserPopulated): number

    isSectorLead(user: IUserPopulated): boolean;

    setPropertiesPopulated(
        teamLeaders: IUserPopulated[], currentCycle: ICommercialCalendar,
        commercialCalendarList: ICommercialCalendar[]): IUserPopulated[];

    isTeamLead(user: IUserPopulated): boolean

    backRoute();

    filterTeamForSubUserList(subUserList: any[], matricule: string): any;

    getUserFromCentral(matricule: string, option: any): IPromise<any>

    fetchTree(matricule: string, middleSearch: boolean): IPromise<any>
  }

  export class UsersService implements IUsersService {

    private $http: ng.IHttpService;
    private $q: ng.IQService;
    private base_url: string = '/api/users';
    private commonService: ICommonService;
    private activitiesService: IActivitiesService;
    private typologyActivityService: ITypologyActivityService;
    private commercialCalendarService: ICommercialCalendarService;
    private promise_getLeaderListByGSDRValue: any = [];
    private promise_getSubUserByMatricule: any = [];
    private promise_getTeamByMatricule: any = [];
    private promise_getUserFromCentral: any = [];

    public static $inject = [
      '$http',
      '$q',
      'CommonService',
      'ActivitiesService',
      'TypologyActivityService',
      'CommercialCalendarsService',
    ];

    /**
     *
     * @param $http
     * @param $q
     * @param commonService
     */
    constructor($http: ng.IHttpService, $q: ng.IQService,
                commonService: ICommonService,
                activitiesService: IActivitiesService,
                typologyActivityService: ITypologyActivityService,
                commercialCalendarService: ICommercialCalendarService,) {
      this.commonService = commonService;
      this.activitiesService = activitiesService;
      this.typologyActivityService = typologyActivityService;
      this.$q = $q;
      this.commercialCalendarService = commercialCalendarService;
    }

    /**
     * fetch logged user entity from server
     * @returns {IPromise<any>}
     */
    public getMe(): IPromise<any> {
      return this.commonService.httpGet(this.base_url + '/me',
          {'304': new Date().getTime()});
    }

    /**
     * Get central user link to user
     * @param {string} matricule
     * @param option
     * @returns {angular.IPromise<any>}
     */
    public getUserFromCentral(matricule: string, option: any): IPromise<any> {
      var promiseString = matricule + '_' + option.sort + '_' +
          option.sortSens + '_' + option.limit + '_' + option.offset + '_' +
          option.filter.profile + '_' + option.filter.structure + '_' + option.filter.name;
      if (this.promise_getUserFromCentral[promiseString]) {
      } else {
        var deferred = this.$q.defer();
        this.commonService.httpGet(this.base_url + '/getUserFromCentral/' +
            matricule, {
          'sort': option.sort,
          'sortSens': option.sortSens,
          'limit': option.limit,
          'offset': option.offset,
          'filter': option.filter,
          'promiseString': promiseString,
        }).then(function(data) {
          deferred.resolve(data);
        }, function(err) {
          deferred.reject(err);
        });
        this.promise_getUserFromCentral[promiseString] = deferred.promise;
      }
      return this.promise_getUserFromCentral[promiseString];
    }

    /**
     * Get team ( n1 and n2) of user and peaople from his structure
     * @param matricule
     * @param GSDRValue
     * @param quarterName
     * @returns {any}
     */
    public getSubUserByMatricule(
        matricule: string, GSDRValue: number, quarterName?: string): ng.IPromise<any> {
      var promiseString = matricule + '_' + GSDRValue;
      if (this.promise_getLeaderListByGSDRValue[promiseString] && !quarterName) {
      } else {
        var deferred = this.$q.defer();
        this.commonService.httpGet(this.base_url + '/getSubUserByMatricule/' +
            matricule, {'GSDRValue': GSDRValue, 'quarterName': quarterName}).then(function(data) {
          deferred.resolve(data);
        }, function(err) {
          deferred.reject(err);
        });
        this.promise_getLeaderListByGSDRValue[promiseString] = deferred.promise;
      }
      return this.promise_getLeaderListByGSDRValue[promiseString];
    }

    /**
     * Get user team (n1 and n2)
     * @param matricule
     * @returns {any}
     */
    public getTeamByMatricule(matricule: string): ng.IPromise<any> {
      var promiseString = matricule;
      if (this.promise_getTeamByMatricule[promiseString]) {
        // promise already set
      } else {
        var deferred = this.$q.defer();
        this.commonService.httpGet(this.base_url + '/getTeamByMatricule/' +
            matricule, {}).then(function(data) {
          deferred.resolve(data);
        }, function(err) {
          deferred.reject(err);
        });
        this.promise_getTeamByMatricule[promiseString] = deferred.promise;
      }
      return this.promise_getTeamByMatricule[promiseString];
    }

    /**
     * logout from server
     * @returns {ng.IPromise<any>}
     */
    public logOut(): IPromise<any> {
      return this.commonService.httpPost(this.base_url + '/logOut', {});
    }

    /**
     * get back route
     * @returns {ng.IPromise<any>}
     */
    public backRoute(): IPromise<any> {
      return this.commonService.httpGet(this.base_url + '/backRoute', {});
    }

    public filterTeamForSubUserList(
        subUserList: any[], matricule: string): any {
      var teamList = subUserList.filter(function(user: IUser) {
        return user.parentMatricule === matricule ||
            user.grandParentMatricule === matricule;
      }.bind(this));
      var teamListActive = subUserList.filter(function(user: IUser) {
        return user.isActive;
      }.bind(this));
      return {
        list: teamList,
        count: teamList.length,
        countActive: teamListActive.length,
      };
    }

    /**
     * get the type of profile, used to choose which type of profile to print in the menu and which block to render in commission-block
     * @param user
     * @returns {string}
     */
    public getProfilType(user: IUserPopulated): string {
      switch (user.profile) {
        case 'LDS':
          return 'LDS';
        case 'DR':
          return 'DR';
        case 'DD':
          return 'DD';
        case 'ZM':
          return 'ZM';
        case 'AS':
        case 'AA':
        case 'ADS':
          return 'Ani';
        case 'Coach':
        case 'CO':
          return 'Coach';
        case 'Leader':
          return 'Leader';
        default:
          console.error('Profil non pris en compte: ' + user.profile);
          return 'COACH';
      }
    }

    /**
     * Return if the user is manager or not, thus user is manager if his profil is DD/ZM or DR
     * @param user
     * @returns {boolean}
     */
    public isManager(user: IUserPopulated): boolean {

      if (user.profile === 'DD' || user.profile === 'DR' ||
          user.profile === 'ZM') {
        return true;
      } else {
        return false;
      }
    }

    /**
     * Return if the user is manager or not, thus user is manager if his profil is DD/ZM or DR
     * @param user
     * @returns {boolean}
     */
    public isDr(user: IUserPopulated): boolean {
      return user.profile === 'DR';
    }

    /**
     * Return true if the user is a the top of a sector ; i-e ADS or LDS
     * @param user
     * @returns {boolean}
     */
    public isSectorLead(user: IUserPopulated): boolean {
      if (user) {
        return user.profile === 'ADS' || user.profile === 'LDS';
      }
    }

    public isTeamLead(user: IUserPopulated): boolean {
      if (user) {
        return user.profile === 'AS' || user.profile === 'AA' ||
            user.profile === 'ADS';
      }
    }

    /**
     * Return the list of options used by the select binded to selectLevel
     * @param user
     * @returns {any}
     */
    public getLevelSelectOptions(user: IUserPopulated): any {
      if (user.lastLevelLeader) {
        return [
          {value: 'n0', label: 'Moi'},
        ];
      } else {
        switch (user.profile) {
          case 'CO':
            return [
              {value: 'n0', label: 'Moi'},
            ];
          case 'ADS':
            return [
              {value: 'n0', label: 'Moi'},
              {value: 'team', label: 'Mon équipe'},
              {value: 'sector', label: 'Mon secteur'},

            ];
          case 'AS':
            return [
              {value: 'n0', label: 'Moi'},
            ];
          case 'AA':
            return [
              {value: 'n0', label: 'Moi'},
              {value: 'team', label: 'Mon équipe'},
            ];
          case 'Coach':
            return [
              {value: 'n0', label: 'Moi'},
            ];
          case 'Leader':
            return [
              {value: 'n0', label: 'Moi'},
              {value: 'n1', label: 'Niveau 1'},
              {value: 'n2', label: 'Niveau 2'},
              {value: 'n1n2', label: 'Niveau 1 et Niveau 2'},
              {value: 'n3', label: 'Niveau 3+'},


            ];
          case 'LDS':
            return [
              {value: 'n0', label: 'Moi'},
              {value: 'n1', label: 'Niveau 1'},
              {value: 'n2', label: 'Niveau 2'},
              {value: 'n1n2', label: 'Niveau 1 et Niveau 2'},
              {value: 'n3', label: 'Niveau 3+'},
              {value: 'sector', label: 'Mon secteur'},
            ];
          case 'DD':
          case 'ZM':
            return 'division';
          case 'DR':
            return 'region';
          default:
            console.error('user profile do not match with enum', user);
        }
      }

    }

    /**
     * parse user profile to get its role
     *
     * @param user
     * @returns {string}
     */
    public getProfilInterface(user: IUser | IUserPopulated): string {
      switch (user.profile) {
        case 'CO':
        case 'AS':
        case 'AA':
        case 'ADS':
          return 'vendeuse';
        case 'Coach':
        case 'Leader':
        case 'LDS':
          return 'SF';
        case 'DD':
        case 'ZM':
        case 'DR':
          return 'manager';
        default:
          console.error('user profile do not match with enum', user);
      }
    }

    /**
     * get list of all user child of logged user
     *
     * @param userPopulated
     * @returns {IUserPopulated[]}
     */
    public getAllSubUserListTeam(userPopulated: IUserPopulated): IUserPopulated[] {
      var subUserList: IUserPopulated[] = [], subUser: IUserPopulated;
      angular.forEach(userPopulated.team, function(user_n1: IUserPopulated) {
        subUser = angular.copy(user_n1);
        subUser.childLevelForParent = 1;
        subUserList.push(subUser);
        angular.forEach(user_n1.team, function(user_n2: IUserPopulated) {
          subUser = angular.copy(user_n2);
          subUser.childLevelForParent = 2;
          subUserList.push(subUser);
        });
      });
      return subUserList;
    }

    /**
     * give the number of active of an user team
     *
     * @param userPopulated
     * @returns {IUserPopulated[]}
     */
    public getNumberActiveAllSubUserListTeam(userPopulated: IUserPopulated): number {
      var res: number = 0;
      angular.forEach(userPopulated.team, function(user_n1: IUserPopulated) {
        if (user_n1.isActive) {
          res++;
        }
        angular.forEach(user_n1.team, function(user_n2: IUserPopulated) {
          if (user_n2.isActive) {
            res++;
          }
        });
      });
      return res;
    }

    /**
     * check if user belongs to Leader's team
     *
     * @param arrayList
     * @param value
     * @returns {number}
     */
    public isUserBelongsToSubUserTeam(
        arrayList: IUserPopulated[], value: string): number {
      for (var i = 0, len = arrayList.length; i < len; i++) {
        if (arrayList[i]._id === value) {
          return i;
        }
      }
      return -1;
    }

    /**
     * get status of Leader
     *
     * @param userPopulated
     * @returns {string}
     */
    public getStatusLeaderGSDR(userPopulated: IUserPopulated): string {
      var statusGSDR = null;
      if (userPopulated.GSDRType && userPopulated.GSDRValue !== 0) {
        if (userPopulated.GSDRType === 'S') {
          statusGSDR = 'teamSector';
        }
        else if (userPopulated.GSDRType === 'G') {
          statusGSDR = 'teamGroup';
        }
        else if (userPopulated.GSDRType === 'D') {
          statusGSDR = 'teamDivision';
        }
        else if (userPopulated.GSDRType === 'R') {
          statusGSDR = 'teamRegion';
        }
      }
      return statusGSDR;
    }

    /**
     * get list of all user child in this team and in this teamGSDR of logged user
     * (if logged user is leader GSRD)
     *
     * @param userPopulated
     * @returns {IUserPopulated[]}
     */
    public getAllSubUserList(userPopulated: IUserPopulated): IUserPopulated[] {
      var team: IUserPopulated[] = this.getAllSubUserListTeam(userPopulated);
      var subUserList: IUserPopulated[] = team;
      var subUser: IUserPopulated;
      var leaderGSDR: string = this.getStatusLeaderGSDR(userPopulated);

      if (leaderGSDR) {
        angular.forEach(userPopulated.teamGroup,
            function(userGSDR: IUserPopulated) {
              if (this.isUserBelongsToSubUserTeam(team, userGSDR._id) === -1) {
                subUser = angular.copy(userGSDR);
                subUser.childLevelForParent = 3;
                subUser.childType = 'team';
                subUserList.push(subUser);
              }
            }.bind(this));
        angular.forEach(userPopulated.teamSector,
            function(userGSDR: IUserPopulated) {
              if (this.isUserBelongsToSubUserTeam(team, userGSDR._id) === -1) {
                subUser = angular.copy(userGSDR);
                subUser.childLevelForParent = 3;
                subUser.childType = 'sector';
                subUserList.push(subUser);
              }
            }.bind(this));
        angular.forEach(userPopulated.teamDivision,
            function(userGSDR: IUserPopulated) {
              if (this.isUserBelongsToSubUserTeam(team, userGSDR._id) === -1) {
                subUser = angular.copy(userGSDR);
                subUser.childLevelForParent = 3;
                subUser.childType = 'division';
                subUserList.push(subUser);
              }
            }.bind(this));
        angular.forEach(userPopulated.teamRegion,
            function(userGSDR: IUserPopulated) {
              if (this.isUserBelongsToSubUserTeam(team, userGSDR._id) === -1) {
                subUser = angular.copy(userGSDR);
                subUser.childLevelForParent = 3;
                subUser.childType = 'region';
                subUserList.push(subUser);
              }
            }.bind(this));
      }
      return subUserList;
    }

    /**
     * the all typologies from sub users
     *
     * @param subUserList
     * @returns {any[]}
     */
    public getAllSubUserTypologyList(subUserList: IUserPopulated[]): string[] {
      var res: string[] = [];
      angular.forEach(subUserList, function(current: IUserPopulated) {
        res.push(current.typology);
      });
      return this.commonService.uniq(res);
    }

    /**
     * all typology contains in typologyActivityList of UserPopulated
     *
     * @param typologyActivityList
     * @returns {any[]}
     */
    public getTypologyListOfTypologyActivityList(typologyActivityList: ITypologyActivity[]): string[] {
      var res: string[] = [];
      angular.forEach(typologyActivityList,
          function(current: ITypologyActivity) {
            res.push(current.typology);
          });
      return this.commonService.uniq(res);
    }

    /**
     * all profiles contains in profile of UserPopulated
     *
     * @param profileList
     * @returns {any[]}
     */
    public getProfileList(profileList: any[]): string[] {
      var res: string[] = [];
      angular.forEach(profileList,
          function(current) {
            res.push(current);
          });
      return this.commonService.uniq(res);
    }

    /**
     * all level contains in pr2Level of UserPopulated
     *
     * @param levelList
     * @returns {any[]}
     */
    public getLevelList(levelList: any[]): string[] {
      var res: string[] = [];
      angular.forEach(levelList,
          function(current) {
            res.push(current);
          });
      return this.commonService.uniq(res);
    }

    /**
     * the all levels from sub users
     *
     * @param subUserList
     * @param userPopulated
     * @returns {any[]}
     */
    public getAllSubUserLevelList(
        subUserList: IUserPopulated[], userPopulated): number[] {
      let res: number[] = [];
      angular.forEach(subUserList, function(current: IUser) {
        if (current.parentMatricule == userPopulated.matricule || current.grandParentMatricule == userPopulated.matricule) {
          res.push(4);
        }
        if (userPopulated.matricule === current.parentMatricule) {
          res.push(1);
        } else if (userPopulated.matricule === current.grandParentMatricule) {
          res.push(2);
        } else {
          res.push(3);
        }
      });
      res.sort((a,b) =>  a !== 3 ? a - b: a = b );
      return this.commonService.uniq(res);
    }

    /**
     * the all performances from sub users
     *
     * @param subUserList
     * @returns {any[]}
     */
    public getAllSubUserPerformanceList(subUserList: IUserPopulated[]): number[] {
      var res: number[] = [];
      angular.forEach(subUserList, function(current: IUserPopulated) {
        res.push(current.personalPerformanceIndicator);
      });
      return this.commonService.uniq(res);
    }

    /**
     * Set an attribute "orderNumberCurrentPeriod" to all users in team
     *
     * @param subUserList
     * @param currentCycleForced
     * @returns {IUserPopulated[]}
     */
    public setOrderNumberCurrentPeriodToSubUserList(
        subUserList: IUserPopulated[],
        currentCycleForced: ICommercialCalendar): IUserPopulated[] {
      var newSubUserList: IUserPopulated[] = [];
      var subUser: IUserPopulated;

      return newSubUserList;
    }

    /**
     * Get List of Leader of the structure
     * @param GSDRValue
     * @param sort
     * @param sortSens
     * @param limit
     * @param offset
     * @returns {ng.IPromise<any>}
     */
    public getLeaderListByGSDRValue(
        GSDRValue: any, sort: String, sortSens: number, limit: number,
        offset: number, p2RLevelFilter: string, divisionNameFilter: string,
        isActiveFilter: string, nameFilter: string, quarterName?: string): IPromise<any> {
      var promiseString = GSDRValue + '_' + sort + '_' + sortSens + '_' +
          limit + '_' + offset + '_' + p2RLevelFilter + '_' +
          divisionNameFilter + '_' + isActiveFilter + '_' + nameFilter + '_' + quarterName;
      if (this.promise_getLeaderListByGSDRValue[promiseString]) {
        // promise already set
      } else {
        var deferred = this.$q.defer();
        this.commonService.httpGet(
            this.base_url + '/listLeader',
            {
              'GSDRValue': GSDRValue,
              'sort': sort,
              'sortSens': sortSens,
              'limit': limit,
              'offset': offset,
              'p2RLevelFilter': p2RLevelFilter !== null ? p2RLevelFilter : '',
              'divisionNameFilter': divisionNameFilter !== null
                  ? divisionNameFilter
                  : '',
              'isActiveFilter': isActiveFilter !== null ? isActiveFilter : '',
              'nameFilter': nameFilter,
              'promiseString': promiseString,
              'quarterName': quarterName
            },
        ).then(function(data) {
          deferred.resolve(data);
        }, function(err) {
          deferred.reject(err);
        });
        this.promise_getLeaderListByGSDRValue[promiseString] = deferred.promise;
      }
      return this.promise_getLeaderListByGSDRValue[promiseString];
    }

    /**
     * logged a user with its matricule
     *
     * @param matricule
     * @returns {IPromise<any>}
     */
    public getUserPopulatedByMatricule(matricule: String): IPromise<any> {
      return this.commonService.httpGet(
          this.base_url + '/getPopulatedByMatricule/' + matricule,
          {'304': new Date().getTime()},
      );
    }

    /**
     * logged a user with its credentials
     *
     * @param credential
     * @returns {IPromise<any>}
     */
    public loginByCredential(credential: ICredential): IPromise<any> {
      return this.commonService.httpPost(
          this.base_url + '/loginByCredential', {
            credential: credential,
          },
      );
    }

    /**
     * log from server
     *
     * @param user
     * @returns {IPromise<any>}
     */
    public logAs(user: IUser): IPromise<any> {
      return this.commonService.httpPost(
          this.base_url + '/logAs',
          {user: user},
      );
    }

    /**
     * get Color from Typology
     *
     * @param typology
     * @returns {string}
     */
    public getTypologyColor(typology: string): string {
      return typology.toLowerCase();
    }

    /**
     * Get List of DR/DD/ZM's teams Leader.
     *
     * @param userPopulated
     * @returns {IPromise<any>}
     */
    public getListTeamLeadersOfManager(
        userPopulated: IUserPopulated, sort: String, sortSens: number,
        limit: number, offset: number): any {
      var leaderZoneType = null;
      if (userPopulated.profile === 'DR') {
        leaderZoneType = 'D';
      } else if (userPopulated.profile === 'DD') {
        leaderZoneType = 'S';
      } else if (userPopulated.profile === 'ZM') {
        leaderZoneType = 'S';
      }
      if (leaderZoneType !== null) {
        return this.commonService.httpGet(
            this.base_url + '/teamLeaders',
            {
              'managerZoneValue': userPopulated.GSDRValue,
              'leaderZoneType': leaderZoneType,
              'sort': sort,
              'sortSens': sortSens,
              'limit': limit,
              'offset': offset,
            },
        );
      } else {
        let err = 'ERROR : profile unknown ' +
            userPopulated.profile;
        var deferred = this.$q.defer();
        deferred.reject(err);
      }

    }

    public fetchTree(matricule: string, middleSearch: boolean): IPromise<any> {
      return this.commonService.httpGet(this.base_url + '/tree', {matricule, middleSearch})
    }

    public getSizeZone(user: IUserPopulated): string {
      switch (user.GSDRType) {
        case 'D' :
          return user.divisionSize;
        case 'G' :
          return user.groupSize;
        case 'R' :
          return user.regionSize;
        case 'S' :
          return user.sectorSize;
        default:
          return '0';
      }
    }

    public setPropertiesPopulated(
        teamLeaders: IUserPopulated[], currentCycle: ICommercialCalendar,
        commercialCalendarList: ICommercialCalendar[]): IUserPopulated[] {
      let teamLeadersList: IUserPopulated[] = [], user: IUserPopulated;
      angular.forEach(teamLeaders, function(item: any) {
        user = angular.copy(item);
        user.zoneSize = this.getSizeZone(user);
        user.totalEffectif = 0;
        user.totalWatched = 0;
        user.totalRecrue = 0;
        user.activeNumber = 0;
        user.totalDemission = 0;
        user.turnoverAvg = 0;
        user.zoneTotalTurnover = 0;
        user.zoneReferenceTurnover = 0;
        user.increase = 0;
        user.codeZone = user.GSDRValue;

        //var typologyActivityCurrent = this.typologyActivityService.getTypologyActivityCurrent(user.typologyActivityList, currentCycle);
        //var activityCurrent = this.activitiesService.getCurrentActivity(user.activityList, currentCycle);
        //var nMinusCycle = this.commercialCalendarService.getNMinus1Cycle(commercialCalendarList, currentCycle);
        //var nMinus1Activity = this.activitiesService.getNMinus1Activity(user.activityList, nMinusCycle);
        let typologyActivityCurrent = user.typologyActivityCurrent;

        if (typologyActivityCurrent && typologyActivityCurrent !== null) {
          user.totalEffectif = typologyActivityCurrent.totalEffectif;
          user.totalWatched = typologyActivityCurrent.totalWatched;
          user.totalRecrue = typologyActivityCurrent.totalRecrue;
          user.activeNumber = typologyActivityCurrent.activeNumber;
          user.totalDemission = typologyActivityCurrent.totalDemission;
          user.turnoverAvg = typologyActivityCurrent.turnOver /
              typologyActivityCurrent.activeNumber;
        }
        if (user.currentActivity) {
          user.zoneTotalTurnover = this.activitiesService.getTurnoverLeadersByProfile(
              user.currentActivity, user.profile);
        }
        if (user.nMinus1Activity) {
          user.zoneReferenceTurnover = this.activitiesService.getTurnoverLeadersByProfile(
              user.nMinus1Activity, user.profile);
        }
        if (user.zoneTotalTurnover !== 0 && user.zoneReferenceTurnover !== 0) {
          user.increase = ((user.zoneTotalTurnover -
              user.zoneReferenceTurnover) / user.zoneReferenceTurnover) * 100;
        }
        teamLeadersList.push(user);
      }.bind(this));
      return teamLeadersList;
    }
  }
}
appDashboard.service('UsersService', services.UsersService);


