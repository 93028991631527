/// <reference path="../appDashboard.ts" />7
module services {
    'use strict';
    import IPromise = angular.IPromise;
    import ICommission = models.ICommission;
    import ICommercialCalendar = models.ICommercialCalendar;
    export interface ICommercialCalendarsService {
        getCommercialCalendarList() : IPromise<any>
        getCurrentCycle(commercialCalendarList : ICommercialCalendar[]) : ICommercialCalendar;
        getCurrentYearName(currentCycle : ICommercialCalendar) : string;
        getNextQuarterName(currentCycle : ICommercialCalendar) : string;
        getLastYearName(currentCycle : ICommercialCalendar) : string;
        getShortNameCycle(cycle : ICommercialCalendar) : string;
        getCycleNumber(cycle : ICommercialCalendar) : number;
        getNMinus1Cycle(commercialCalendarList : ICommercialCalendar[], currentCycle : ICommercialCalendar) : ICommercialCalendar;
        getMonthNumberInQuarter(commercialCalendar : ICommercialCalendar) : number;
        getCurrentCycleForced(commercialCalendarList : ICommercialCalendar[]) : ICommercialCalendar;
        getMonthNumberRelativeToQuarter(offset : number, quarterName : string) : number;
        getLastQuarterName(currentCycle : ICommercialCalendar) : string;
        getCycleAfterNMinus1Cycle(commercialCalendarList : ICommercialCalendar[], currentCycle : ICommercialCalendar) : ICommercialCalendar;
    }
    export class CommercialCalendarsService implements ICommercialCalendarsService {

        private $http:ng.IHttpService;
        private $q:ng.IQService;
        private commonService:ICommonService;
        private base_url:string = '/api/commercialCalendar';



        public static $inject = [
            '$http',
            '$q',
            'CommonService'
        ];

        /**
         *
         * @param $http
         * @param $q
         * @param commonService
         */
        constructor($http:ng.IHttpService, $q:ng.IQService, commonService:ICommonService) {
            this.commonService = commonService;
        }

        /**
         * Fetch the commercial calendar list form the server
         *
         * @returns {ng.IPromise<any>}
         */
        public getCommercialCalendarList() : IPromise<any>{
            return this.commonService.httpGet(this.base_url, {});
        }

        /**
         *  get the month number from the quarterName
         *  example :
         *  1 et T1 -> 1
         *  2 et T1 -> 2
         *  1 et T2 -> 4
         *
         *
         * @param offset
         * @param quarterName
         * @returns {number}
         */
        public getMonthNumberRelativeToQuarter(offset : number, quarterName : string) : number{
            var quarterIndex = parseInt(quarterName.substring(1,2));
            return (quarterIndex - 1)* 3 + offset;
        }

        /**
         * Get the cycle matching the current date
         * @param commercialCalendarList
         * @returns {ICommercialCalendar}
         */
        public getCurrentCycle(commercialCalendarList : ICommercialCalendar[]) : ICommercialCalendar{
            var now = new Date();
            var currentCycle : ICommercialCalendar;
            var startDate : Date, endDate : Date;
            angular.forEach(commercialCalendarList, function (currentCommercialCalendar : ICommercialCalendar) {
                startDate = new Date(currentCommercialCalendar.startDatePeriode);
                endDate = new Date(currentCommercialCalendar.endDatePeriode);
                if(startDate < now  && endDate > now){
                    currentCycle = currentCommercialCalendar;
                }
                /**
                 * ONLY TEST
                 */
                /*if(currentCommercialCalendar.periodeName === "C11-2016"){
                    currentCycle = currentCommercialCalendar;
                }*/
            });
            return currentCycle;
        }

        /**
         * get the quarter's number corresponding to current month
         * [1, 4, 7, 10] => 1
         * [2, 5, 8, 11] => 2
         * [3, 6, 9, 12] => 3
         *
         * @param commercialCalendar
         * @returns {number}
         */
        public getMonthNumberInQuarter(commercialCalendar : ICommercialCalendar) : number{
            var monthNumber = parseInt(commercialCalendar.periodeName.substring(1, 3));
            var caseNumber =  monthNumber%3;
            return caseNumber ? caseNumber : 3;
        }

        /**
         * Get the cycle preceding the cycle matching the current date
         *
         * @param commercialCalendarList
         * @param currentCycle
         * @returns {ICommercialCalendar}
         */
        public getNMinus1Cycle(commercialCalendarList : ICommercialCalendar[], currentCycle : ICommercialCalendar) : ICommercialCalendar{


            var CycleNameNMinus1 = currentCycle.periodeName.substring(0, 3) + "-"+ this.getLastYearName(currentCycle);
            var res : ICommercialCalendar;
            angular.forEach(commercialCalendarList, function (currentCommercialCalendar : ICommercialCalendar) {
                if(currentCommercialCalendar.periodeName === CycleNameNMinus1){
                    res = currentCommercialCalendar;
                }
            });
            return res;
        }
        /**
         * Get the cycle after the referentil one
         *
         * @param commercialCalendarList
         * @param currentCycle
         * @returns {ICommercialCalendar}
         */
        public getCycleAfterNMinus1Cycle(commercialCalendarList : ICommercialCalendar[], currentCycle : ICommercialCalendar) : ICommercialCalendar{


            function getMonthAfter(month){
                var month_number = parseInt(month);
                if(month_number != 12){
                    if(month_number +1 >=10){
                        var n = month_number +1;
                        return ""+n;
                    } else{
                        var n = month_number +1;
                        return "0"+n;
                    }
                } else{
                    return "01";
                }
            }

            var CycleAfterNMinus1Cycle : string;
            var CycleNameNMinus1 = currentCycle.periodeName.substring(0, 3) + "-"+ this.getLastYearName(currentCycle);
            if(getMonthAfter(currentCycle.periodeName.substring(1, 3)) === "01"){
                CycleAfterNMinus1Cycle = currentCycle.periodeName.substring(0, 1) +getMonthAfter(currentCycle.periodeName.substring(1, 3))+ "-"+ this.getCurrentYearName(currentCycle);
            } else{
                CycleAfterNMinus1Cycle = currentCycle.periodeName.substring(0, 1) +getMonthAfter(currentCycle.periodeName.substring(1, 3))+ "-"+ this.getLastYearName(currentCycle);
            }

            var res : ICommercialCalendar;
            angular.forEach(commercialCalendarList, function (currentCommercialCalendar : ICommercialCalendar) {
                if(currentCommercialCalendar.periodeName === CycleAfterNMinus1Cycle){
                    res = currentCommercialCalendar;
                }
            });
            return res;
        }

        /**
         *Get the 3 first letters in cycle name
         *
         * @param cycle
         * @returns {string}
         */
        public getShortNameCycle(cycle : ICommercialCalendar) : string{
            if(cycle && cycle.periodeName){
                return cycle.periodeName.substring(0, 3)
            }
        }

        /**
         *Get the number in short cyclename
         * @param cycle
         * @returns {number}
         */
        public getCycleNumber(cycle : ICommercialCalendar) : number{
            if(cycle && cycle.periodeName){
                return parseInt(cycle.periodeName.substring(1, 3));
            }
        }

        /**
         * Get the yearname in current cycle
         *
         * @param currentCycle
         * @returns {string}
         */
        public getCurrentYearName(currentCycle : ICommercialCalendar) : string{
            return currentCycle.yearName;
        }

        /**
         * Get the year preceding the year in current cycle
         *
         * @param currentCycle
         * @returns {string}
         */
        public getLastYearName(currentCycle : ICommercialCalendar) : string{
            return (parseInt(currentCycle.yearName) - 1).toString();
        }

        /**
         * Get the name (ex: T1) of the incoming quarter
         *
         * @param currentCycle
         * @returns {string}
         */
        public getNextQuarterName(currentCycle : ICommercialCalendar) : string{
            var quarterIndex = parseInt(currentCycle.quarterName.substring(1,2));
            return "T" + (quarterIndex%4 + 1).toString();
        }

        public getLastQuarterName(currentCycle : ICommercialCalendar) : string {
            var quarterIndex = parseInt(currentCycle.quarterName.substring(1,2));
            switch (quarterIndex) {
                case 1 :
                    return "T4-"+this.getLastYearName(currentCycle);
                default:
                    return "T"+(quarterIndex-1).toString()+"-"+currentCycle.yearName;
            }
        }

        /**
         * Get the the cycle marked as active
         *
         * @param commercialCalendarList
         * @returns {ICommercialCalendar}
         */
        public getCurrentCycleForced(commercialCalendarList : ICommercialCalendar[]) : ICommercialCalendar{
            var currentCycleForced : ICommercialCalendar = null;
            angular.forEach(commercialCalendarList, function (currentCommercialCalendar : ICommercialCalendar) {
                if (currentCommercialCalendar.isActive === true) {
                    currentCycleForced = currentCommercialCalendar;
                }

            }.bind(this));
            if (currentCycleForced === null) {
                currentCycleForced = this.getCurrentCycle(commercialCalendarList);
            }
            return currentCycleForced;
        }

    }
}
appDashboard.service("CommercialCalendarsService", services.CommercialCalendarsService);


