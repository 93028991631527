/// <reference path="../appDashboard.ts" />
module controllers {

  import IActivitiesService = services.IActivitiesService;
  import ICommercialCalendarService = services.ICommercialCalendarsService;
  import IUsersService = services.IUsersService;
  import IUserPopulated = models.IUserPopulated;
  import ICommercialCalendar = models.ICommercialCalendar;
  import IDisplayService = services.IDisplayService;
  declare var jQuery: any;
  declare var Promise: any;

  export class DashboardObjectivesBlockController implements angular.IController {
    $onInit = () => { };

    private scope: any;
    private activitiesService: IActivitiesService;
    private commercialCalendarService: ICommercialCalendarService;
    private usersService: IUsersService;
    private displayService: IDisplayService;
    private $q: ng.IQService;

    public promiseUserPopulated: any;
    public promiseCommercialCalendar: any;
    public userPopulated: IUserPopulated;
    public subUserListLength: number;
    public showObjectivesBlock: boolean;
    public currentCycleForced: ICommercialCalendar;
    public turnoverCurrentQuarter: number;
    public activityWeekNumberCurrentQuarter: number;

    public limitNumber: number;
    public limitGainNumber: number;
    public isDestroy: boolean;
    public currentActivity: number;

    public static $inject = [
      '$scope',
      'ActivitiesService',
      'CommercialCalendarsService',
      'UsersService',
      'DisplayService',
      'limitNumber',
      'limitGainNumber',
      '$q',
    ];

    /**
     *
     * @param $scope
     * @param activitiesService
     * @param commercialCalendarService
     * @param usersService
     * @param limitNumber
     * @param limitGainNumber
     */
    constructor($scope: any, activitiesService: IActivitiesService,
                commercialCalendarService: ICommercialCalendarService,
                usersService: IUsersService, displayService: IDisplayService,
                limitNumber: number, limitGainNumber: number,
                $q: ng.IQService) {
      this.scope = $scope;
      this.activitiesService = activitiesService;
      this.commercialCalendarService = commercialCalendarService;
      this.usersService = usersService;
      this.displayService = displayService;
      this.limitNumber = limitNumber;
      this.limitGainNumber = limitGainNumber;
      this.$q = $q;
      this.userPopulated = null;
      this.isDestroy = false;

      this.$q.all(
          [$scope.promiseUserPopulated, $scope.promiseCommercialCalendar]).
          then(function(data: any) {
            this.init(data[0], data[1]);
          }.bind(this));
      $scope.$on('$destroy', function iVeBeenDismissed() {
        this.isDestroy = true;
      }.bind(this));

    }

    /**
     *
     * @param userPopulated
     * @param commercialCalendarList
     */
    private init(
        userPopulated: IUserPopulated,
        commercialCalendarList: ICommercialCalendar[]): void {
      this.userPopulated = userPopulated;
      this.usersService.getSubUserByMatricule(userPopulated.matricule,
          userPopulated.GSDRValue).then(
          function(subUserListObject) {
            var userTeamObject = this.usersService.filterTeamForSubUserList(
                subUserListObject.list, userPopulated.matricule);

            this.showObjectivesBlock = this.displayService.displayObjectivesBlock(
                userPopulated, userTeamObject);
          }.bind(this),
      );

      this.currentCycleForced = this.commercialCalendarService.getCurrentCycleForced(
          commercialCalendarList);
      if (this.currentCycleForced) {
        this.activitiesService.getByMatricule(userPopulated.matricule).then(
            function(activityList) {
              this.currentActivity = this.activitiesService.getCurrentActivity(activityList, this.currentCycleForced);
              this.turnoverCurrentQuarter = this.activitiesService.getSumTurnoverCurrentQuarterly(
                  activityList, this.currentCycleForced.quarterName);
              this.activityWeekNumberCurrentQuarter = this.activitiesService.getSumActivityWeekNumberCurrentQuarterly(
                  activityList, this.currentCycleForced.quarterName);
              setTimeout(function() {
                if (this.userPopulated.objective) {
                  this.load(
                      this.userPopulated.objective.nextDirectCommissionRateTurnoverRemainsToBeDone,
                      this.userPopulated.objective.nextLevelP2RNumberActivesRemainsToBeDone,
                      this.userPopulated.objective.nextDirectCommissionRateTurnoverCriteria,
                      this.userPopulated.objective.nextLevelP2RNumberActivesCriteria,
                      this.userPopulated.objective.quarterlyTurnoverRemainsToBeDone,
                      this.userPopulated.objective.ObjectiveNextLevelP2R,
                      this.userPopulated.objective.QuarterlyTurnover,
                  );
                }

              }.bind(this));
            }.bind(this),
        );
      }

    }

    /**
     *
     * @returns {number}
     */
    public getMonthNumberInQuarter(): number {
      if (this.currentCycleForced) {
        return this.commercialCalendarService.getMonthNumberInQuarter(
            this.currentCycleForced);
      }
    }

    /**
     * Get if the quarterly turnover and activity objectives were achieved
     * @returns {number}
     */
    public getObjectivesCase(): number {
      if (this.userPopulated && this.userPopulated.objective) {
        if (this.turnoverCurrentQuarter <
            this.userPopulated.objective.nextDirectCommissionRateTurnoverCriteria) {
          if (this.activityWeekNumberCurrentQuarter <
              this.userPopulated.objective.nextLevelP2RNumberActivesCriteria) {
            return 1;
          } else {
            return 3;
          }
        } else {
          if (this.activityWeekNumberCurrentQuarter <
              this.userPopulated.objective.nextLevelP2RNumberActivesCriteria) {
            return 2;
          } else {
            return 4;
          }
        }
      }
    }

    /**
     * Load the directives circle graphs
     * @param nextDirectCommissionRateTurnoverRemainsToBeDone
     * @param nextLevelP2RNumberActivesRemainsToBeDone
     * @param nextDirectCommissionRateTurnoverCriteria
     * @param nextLevelP2RNumberActivesCriteria
     */
    private load(
        nextDirectCommissionRateTurnoverRemainsToBeDone: number,
        nextLevelP2RNumberActivesRemainsToBeDone: number,
        nextDirectCommissionRateTurnoverCriteria: number,
        nextLevelP2RNumberActivesCriteria: number,
        quarterlyTurnoverRemainsToBeDone: number,
        ObjectiveNextLevelP2R: number): void {

      scrollfunction();

      function toCentimal(n: number): string {
        var integr: number = Math.floor(n);
        var decimal: number = n - integr;
        return  decimal !== 0 ? integr + ',' + decimal.toString().substring(2, 4) : integr.toString();

      }

      /**
       *
       * @param choice
       */
      function circleLaunch(choice: string): void {
        if (choice === '#courbe2') {
          var cerclecomplet2: number = nextDirectCommissionRateTurnoverCriteria;
          var valeurcerclefinal2: number = quarterlyTurnoverRemainsToBeDone;
          var degres2: number;
          if (cerclecomplet2 === 0) {
            degres2 = 1;
          } else {
            degres2 = valeurcerclefinal2 / cerclecomplet2;
          }

          jQuery('#courbe2').circleChart({
            size: 228,
            deg: 360 * degres2,
            startAngle: -90,
            counterclockwise: false,
            color: '#55409c',
            widthRatio: 0.2,
            backgroundColor: '#e6ecf3',
            background: false,
            lineCap: 'square',
            cAngle: 0.5,
            backgroundFix: false,
            rad: false,
            text: 0,
            textCenter: true,
            onDraw: function(el: any) {
              jQuery('.circleChart_text', el).
                  html('<span>RAF</span>'+
                      toCentimal(quarterlyTurnoverRemainsToBeDone) +
                      '€<br/><span>CA HT perso</span>');
            },
          });
        }
        ;

        if (choice === '#courbe3') {
          var cerclecomplet3: number = nextLevelP2RNumberActivesCriteria;// jQuery(".circleChart#0").attr('total');
          var valeurcerclefinal3: number = nextLevelP2RNumberActivesRemainsToBeDone; //jQuery(".circleChart#0").attr('restant');
          //var valeuraretirerducercle3 : number = cerclecomplet3 - valeurcerclefinal3;
          var degres3: number;
          if (cerclecomplet3 === 0) {
            degres3 = 1;
          } else {
            degres3 = valeurcerclefinal3 / cerclecomplet3;
          }

          jQuery('#courbe3').circleChart({
            size: 228,
            deg: 360 * degres3,
            startAngle: -90,
            counterclockwise: false,
            color: '#55409c',
            widthRatio: 0.2,
            backgroundColor: '#e6ecf3',
            background: false,
            lineCap: 'square',
            cAngle: 0.5,
            backgroundFix: false,
            rad: false,
            text: 0,
            textCenter: true,
            onDraw: function(el: any) {
              jQuery('.circleChart_text', el).
                  html(
                        Math.round(nextLevelP2RNumberActivesRemainsToBeDone) +
                      "<br/><span>RAF nbr d'active(s)<br\> pour devenir <br\>" + ObjectiveNextLevelP2R + "</span>");
            },
          });
        }
      }

      jQuery(window).scroll(function() {
        if (!this.isDestroy) {
          scrollfunction();
        }
      });

      var flag2: number = 0;
      var flag3: number = 0;

      function scrollfunction(): void {

        var hWindow: any = jQuery(window).height();
        var heightScroll: any = jQuery(window).scrollTop();
        if (jQuery('.circle').length > 0) {
          if (jQuery('#courbe2').offset()) {
            var heightElement2: any = (jQuery('#courbe2').offset().top) -
                hWindow;

          }
          if (jQuery('#courbe3').offset()) {
            var heightElement3: any = (jQuery('#courbe3').offset().top) -
                hWindow;
          }
          if (heightElement2 && heightScroll >= heightElement2 && flag2 == 0) {
            circleLaunch('#courbe2');
            flag2 = 1;
          }
          if (heightElement3 && heightScroll >= heightElement3 && flag3 == 0) {
            circleLaunch('#courbe3');
            flag3 = 1;
          }
        }
      }
    }
  }
}
appDashboard.controller('DashboardObjectivesBlockController',
    controllers.DashboardObjectivesBlockController);
