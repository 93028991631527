/// <reference path="../appDashboard.ts" />7
module services {
    'use strict';
    import IPromise = angular.IPromise;
    import ICommission = models.ICommission;
    import IMessage = models.IMessage;
    export interface IMessagesService {
        getMessagesPerMatricule(matricule: string): IPromise<any>
        setMessageToSeen(matricule: string): IPromise<any>
    }
    export class MessagesService implements IMessagesService {

        private $http: ng.IHttpService;
        private $q: ng.IQService;
        private commonService: ICommonService;

        public static $inject = [
            '$http',
            '$q',
            'CommonService'
        ];

        /**
         *
         * @param $http
         * @param $q
         * @param commonService
         */
        constructor($http: ng.IHttpService, $q: ng.IQService, commonService: ICommonService) {
            this.commonService = commonService;
        }


        /**
         * fetch message from server with matricule parameter
         * @param matricule
         * @returns {IPromise<any>}
         */
        public getMessagesPerMatricule(matricule: string): IPromise<any> {
            return this.commonService.httpGet('/api/messages/getMessagesPerMatricule/' + matricule, {});
        }

        /**
         * post messageToSeen to server
         *
         * @param matricule
         * @returns {IPromise<any>}
         */
        public setMessageToSeen(matricule: string): IPromise<any> {
            return this.commonService.httpPost('/api/messages/setMessageToSeen/', {matricule : matricule});
        }
    }
}
appDashboard.service("MessagesService", services.MessagesService);


