/// <reference path="../appDashboard.ts" />
import ICommission = models.ICommission;
import IExportsService = services.IExportsService;

let arrayCommission = function(ExportsService: IExportsService) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      list: '=',
      type: '@',
      cycle: '=',
      showGoTab: '=',
      matricule: '=',
    },
    templateUrl: '/templates/array-commission.html',

    link: function(scope: any) {

      scope.data = {
        currentPage: 0,
      };

      scope.pageSize = scope.showGoTab ? null : 5;

      scope.getNumber = function() {
        if (scope.list && scope.pageSize) {
          return new Array(Math.ceil(scope.list.length / scope.pageSize));
        }
      };

      scope.nextPage = function(): void {
        if (!(scope.data.currentPage >= scope.list.length / scope.pageSize -
                1)) {
          scope.data.currentPage = scope.data.currentPage + 1;
        }
      };

      scope.previousPage = function(): void {
        if (!(scope.data.currentPage == 0)) {
          scope.data.currentPage = scope.data.currentPage - 1;
        }
      };

      scope.exportExel = function(): any {
        ExportsService.exportCommission(
            (scope.list[0] as any).matricule, scope.type,
            scope.periode.periodeName);
      };

      scope.displayParrain = function() {
        return scope.type === 'indirectN2P2R' || scope.type ===
            'indirectN2Forced';
      };

      scope.goTabView = function(): void {

        let a = document.createElement('a');
        a.href = '#!/tab/' + scope.type + '/' + scope.matricule + '?filter=';
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
      };

      scope.setTitle = function() {
        let title = 'Liste des actives avec ';
        if (scope.type === 'indirectN1Forced') {
          title += 'commissions forcées N1';
        }
        if (scope.type === 'indirectN2Forced') {
          title += 'commissions forcées N2';
        }
        if (scope.type === 'indirectN1P2R') {
          title += 'commissions indirectes N1';
        }
        if (scope.type === 'indirectN2P2R') {
          title += 'commissions indirectes N2';
        }
        return title;
      };

      scope.title = scope.setTitle();
    },
  };
};

appDashboard.directive('arrayCommission', ['ExportsService', arrayCommission]);
