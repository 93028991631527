/// <reference path="../appDashboard.ts" />
module controllers {

  import IUsersService = services.IUsersService;
  import IUserPopulated = models.IUserPopulated;
  import ICommercialCalendarService = services.ICommercialCalendarsService;
  import ICommercialCalendar = models.ICommercialCalendar;
  import IUser = models.IUser;
  import IMessagesService = services.IMessagesService;
  import IMessage = models.IMessage;
  import UsersService = services.UsersService;
  import IDisplayService = services.IDisplayService;

  export class DashboardIndexController implements angular.IController {
    $onInit = () => { };

    private $scope: any;
    private $state: ng.ui.IStateService;
    private $window: any;
    private $locale: ng.ILocaleService;
    private usersService: IUsersService;
    private messagesService: IMessagesService;
    private commercialCalendarService: ICommercialCalendarService;
    private $q: ng.IQService;

    public userPopulated: IUserPopulated;
    public subUserList: IUserPopulated[];
    public currentPage: number;
    public pageSize: number;
    public limitNumber: number;
    public limitGainNumber: number;
    public forcedCycleShortName: string;
    public logoUrl: string;
    public logAsChild: boolean;
    public currentCycleForced: ICommercialCalendar;
    public nMinus1CycleForced: ICommercialCalendar;
    public currentCycleNumber: number;
    public promiseUserPopulated: any;
    public promiseCommercialCalendar: any;
    public isManager;
    public displaySellerDashboard : boolean = false;
    public displayManagerDashboard : boolean = false;
    public displayDrDashboard : boolean = false;
    public seeHeader: boolean;
    public version: string;
    public displayService: IDisplayService;
    public initLeaderListLength: number;
    public initLeaderListLengthForLeaderAndAA: number;

    public static $inject = [
      '$scope',
      '$state',
      '$locale',
      '$stateParams',
      'UsersService',
      'CommercialCalendarsService',
      'MessagesService',
      'limitNumber',
      'limitGainNumber',
      '$q',
      'seeHeader',
      '$window',
      'version',
      'DisplayService',

    ];

    /**
     *
     * @param $scope
     * @param $state
     * @param $locale
     * @param $stateParams
     * @param usersService
     * @param commercialCalendarService
     * @param messagesService
     * @param limitNumber
     * @param limitGainNumber
     */
    constructor($scope: any,
                $state: ng.ui.IStateService,
                $locale: ng.ILocaleService,
                $stateParams: any,
                usersService: IUsersService,
                commercialCalendarService: ICommercialCalendarService,
                messagesService: IMessagesService,
                limitNumber: number, limitGainNumber: number,
                $q: ng.IQService,
                seeHeader: boolean,
                $window: boolean,
                version: string,
                displayService: IDisplayService) {
      this.$scope = $scope;
      this.$state = $state;
      this.$window = $window;
      this.$locale = $locale;
      this.usersService = usersService;
      this.displayService = displayService;
      this.commercialCalendarService = commercialCalendarService;
      this.messagesService = messagesService;
      this.logoUrl = 'https://mystan.force.com/fr?lang=fr';
      this.limitNumber = limitNumber;
      this.limitGainNumber = limitGainNumber;
      this.$q = $q;
      this.seeHeader = seeHeader;
      this.version = version;

      var callbackPostGetUser = function(user) {
        this.isManager = this.usersService.isManager(user);
        this.displaySellerDashboard = !this.usersService.isManager(user);
        this.displayManagerDashboard = this.usersService.isManager(user);
        this.displayDrDashboard = this.usersService.isDr(user);
        return user;
      }.bind(this);

      if ($stateParams && $stateParams.matricule) {
        this.promiseUserPopulated = this.usersService.getUserPopulatedByMatricule(
            $stateParams.matricule).then(
            callbackPostGetUser, function(err) {
              console.error(err);
            },
        );

      } else {
        this.promiseUserPopulated = this.usersService.getMe().then(
            callbackPostGetUser, function(err) {
              console.error(err);
            },
        );
      }

      this.promiseCommercialCalendar = this.commercialCalendarService.getCommercialCalendarList();

      this.$q.all([this.promiseUserPopulated, this.promiseCommercialCalendar]).
          then(function(data: any) {
            this.init(data[0], data[1]);
          }.bind(this), function(err) {
            console.error(err);
          });

      if (window.location.href.indexOf('child') > -1) {
        this.logAsChild = true;
      }
      this.initVariable();
      $('.burger').click(function(e) {
        e.preventDefault();
        $(this).toggleClass('open');
        $('.menu-mobile').slideToggle();
      });

    }

    private initVariable() {
      this.$locale.NUMBER_FORMATS.GROUP_SEP = ' ';
      this.$locale.NUMBER_FORMATS.DECIMAL_SEP = ',';
      this.currentPage = 0;
      this.pageSize = 10;
    }


    public displayArrayLeaderBlock() {
      if (this.userPopulated) {
        if (this.displayService.displayArrayLeaderBlock) {
          if (this.userPopulated.profile == 'DR' ||
              this.userPopulated.profile == 'DD' ||
              this.userPopulated.profile == 'ZM' ||
              this.userPopulated.profile == 'LDS' ||
              this.userPopulated.profile == 'ADS') {
            return this.initLeaderListLength != 0;
          } else if (this.userPopulated.profile == 'AA' ||
              this.userPopulated.profile == 'Leader') {
            return this.initLeaderListLengthForLeaderAndAA != 0;

          }
        }
      } else {
        return false;
      }
    }

    /**
     *
     * @param userPopulated
     * @param commercialCalendarList
     */
    private init(
        userPopulated: IUserPopulated,
        commercialCalendarList: ICommercialCalendar[]) {
      this.userPopulated = userPopulated;
      // init
      if (commercialCalendarList.length !== 0) {

        // Return current cycle forced else return current cycle by date
        this.currentCycleForced = this.commercialCalendarService.getCurrentCycleForced(
            commercialCalendarList);
        if (!this.currentCycleForced) {
          console.error('Current Cycle Forced not defined');
        } else {
          this.forcedCycleShortName = this.commercialCalendarService.getShortNameCycle(
              this.currentCycleForced);
          this.currentCycleNumber = this.commercialCalendarService.getCycleNumber(
              this.currentCycleForced);
          this.nMinus1CycleForced = this.commercialCalendarService.getNMinus1Cycle(
              commercialCalendarList, this.currentCycleForced);

        }

        setTimeout(function() {
          this.load();
        }.bind(this));
      }

      this.usersService.getLeaderListByGSDRValue(userPopulated.GSDRValue, null,
          1, 10,
          0, null, null, undefined, "").then(
          function(data) {
            this.initLeaderListLength = data.count;
          }.bind(this),
      );
      this.usersService.getSubUserByMatricule(userPopulated.matricule,
          userPopulated.GSDRValue).then(function(subUserListObject) {
        var leaderList = subUserListObject.list.filter(function(item) {
          return (item.p2RLevel != '' || item.lastLevelLeader != '') && item.parentMatricule ==
              userPopulated.matricule;
        });
        this.initLeaderListLengthForLeaderAndAA = leaderList.length;
      }.bind(this));
    }

    // Code message - Refactoriser plus tard
    public getListBirthdayName(birthdayUserList: IUser[]) {
      var str = '';
      angular.forEach(birthdayUserList, function(current_user, key) {
        str += current_user.firstName + ' ' + current_user.lastName;
        if (key != birthdayUserList.length - 1) {
          str += ' et ';
        }
      });
      return str;
    }

    /**
     * manage where the user is back
     */
    public back() {
      this.usersService.backRoute().then(function(data) {
        if (data == '/admin') {
          this.$window.location.href = '/admin';
        } else if (data == '/support') {
          this.$window.location.href = '/support';
        } else {
          this.$state.go('index');
        }
      }.bind(this));
    }

    /**
     * If the user can see dashboard
     */
    public canSeeDashboard() {
      if (this.userPopulated) {
        //return this.userPopulated.profile != 'AA' && this.userPopulated.profile != 'ADS' &&this.userPopulated.profile != 'AS';
        return true;
      }
    }

    public canSeeObjectiveDashboard() {
        if(this.userPopulated.profile !== 'LDS'){
          return true;
        }
    }
    public scrollTo(id) {
      var getTop = function(id) {
        var offset = this.seeHeader ? 170 : 90;
        var top = $(id).offset().top - offset;
        return top > 0 ? top : 0;
      }.bind(this);
      $('html, body').animate({
        scrollTop: getTop(id),
      });
      $('#barre .content-barre').slideToggle();
    }

    public logOut() {
      this.usersService.logOut().then(function() {
        $('#popup_logout').fadeIn('300');
      });
    }

    // Function contains code message + Menu
    private load() {
      /**
       * Animation
       */
      $('#messages').click(function(e) {
        e.preventDefault();
        $('#popup_message').fadeIn('300');
      });
      $('#regularisation').click(function(e) {
        e.preventDefault();
        $('#popup_regularisation').fadeIn('300');
      });

      $('#deco').click(function(e) {
        e.preventDefault();
        $('#popup_texte').fadeIn('300');
      });
      $('#popup_message .icon-close').click(function(e) {
        e.preventDefault();
        $('#popup_message').fadeOut('300');
      });
      $('#popup_texte .icon-close').click(function(e) {
        e.preventDefault();
        $('#popup_texte').fadeOut('300');
      });
      $('#popup_regularisation .icon-close').click(function(e) {
        e.preventDefault();
        $('#popup_regularisation').fadeOut('300');
      });

      heightEcran();
      $('a[href*="#"]:not([href="#"])').click(function() {
        var $this: any = $(this);
        $('a[href*="#"]:not([href="#"])').removeClass('active');
        $this.addClass('active');
        if (location.pathname.replace(/^\//, '') ==
            this.pathname.replace(/^\//, '') &&
            location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) +
              ']');
          if (target.length) {
            $('html, body').animate({
              scrollTop: target.offset().top - $('#header').height() -
              $('.titre1').outerHeight(),
            }, 1000);
            return false;
          }
        }
      });
      $('.slider .cursor').each(function() {
        $(this).css('left', $(this).attr('perf') + '%');
      });

      $('.tableau-inactives-v2 .toggle-detail').click(function(e) {
        e.preventDefault();
        $(this).parent('tr').next('detail').toggleClass('active');
      });

      /*            $('.tabs-dashboard').responsiveTabs({
                      startCollapsed: 'accordion'
                  });*/

      $('.tabs-dashboard').responsiveTabs({});

      $('.divisions-zones-table').basictable({
        breakpoint: 1410,
      });
      $('#tab_primes').basictable({
        breakpoint: 768,
      });

      $('.tooltip').tooltip({
        position: {my: 'left+22 top-45', at: 'left top+15'},
      });

      $('#barre .menu-responsive').click(function(e) {
        e.preventDefault();
        $('#barre .content-barre').slideToggle();
      });
      $(window).resize(function() {
        heightEcran();
      });

      //todo: move to turnoverBlock ?
      function heightEcran() {
        $('#charts #container').css('min-height', '');
        if ($('#header').width() >= 1440) {
          var hWindow: any = $(window).height();
          var hHeader: any = $('#header').height();
          var hCharts: any = $('#charts #container').height();
          var paddingContent: any = 43;
          var hH1: any = $('h1.titre1').outerHeight();
          var hEntete: any = $('.entete').outerHeight();
          var hChiffres: any = $('#chiffres').outerHeight();
          var hWindowLeft: any = hWindow - hHeader - paddingContent - hH1 -
              hEntete - hChiffres;
          if (hWindowLeft > hCharts) {
            $('#charts #container').css('min-height', hWindowLeft);
          }
        }
      }
    }
  }
}

appDashboard.controller('DashboardIndexController',
    controllers.DashboardIndexController);
