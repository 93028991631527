module controllers {

  import IUserPopulated             = models.IUserPopulated;
  import IUsersService              = services.IUsersService;
  import IUser                      = models.IUser;
  import ICommercialCalendarService = services.ICommercialCalendarsService;
  import ICommercialCalendar        = models.ICommercialCalendar;
  import IPrimeService              = services.IPrimeService;
  import IPrime                     = models.IPrime;
  import IActivity                  = models.IActivity;
  import IActivitiesService         = services.IActivitiesService;

  export class DashboardManagerPrimeTurnoverController implements angular.IController {
    $onInit = () => { };

    private $scope: any;
    private $state: ng.ui.IStateService;
    private $q: ng.IQService;
    private usersService: IUsersService;
    private commercialCalendarService: ICommercialCalendarService;
    private primeService: IPrimeService;
    private currentYearName: string;
    private activitiesService: IActivitiesService;

    public userPopulated: IUserPopulated;
    public promiseUserPopulated: any;
    public promiseCommercialCalendar: any;
    public commercialCalendarList: ICommercialCalendar[];
    public currentCycle: ICommercialCalendar;
    public currentCycleNumber: number;
    public currentPrimeId1: IPrime;
    public currentPrimeId2: IPrime;
    public currentPrimeId3: IPrime;
    public turnoverProjection: any;
    public selectLevel: string;
    public turnoverArray: any;
    public objectifArray: any;
    public referenceArray: any;
    public turnoverSum: number;
    public objectifSum: number;
    public referenceSum: number;
    public simulationPrime1: number;
    public simulationPrime2: number;
    public simulationPrime3: number;
    public maxValueGraph: number;
    public minValueGraph: number;
    public simulationTotal: number;
    public sumAmountWinPrimes: number;
    public slidesValueLeftStyle: number[];
    public amountStepGraduate: any[];

    public static $inject = [
      '$scope',
      '$state',
      'UsersService',
      'CommercialCalendarsService',
      '$q',
      'PrimeService',
      'ActivitiesService',
    ];

    /**
     *
     * @param $scope
     * @param $state
     * @param usersService
     * @param commercialCalendarService
     * @param p2RService
     */
    constructor(
        $scope: any,
        $state: ng.ui.IStateService,
        usersService: IUsersService,
        commercialCalendarService: ICommercialCalendarService,
        $q: ng.IQService,
        primeService: IPrimeService,
        activitiesService: IActivitiesService) {

      this.$scope = $scope;
      this.$state = $state;
      this.usersService = usersService;
      this.commercialCalendarService = commercialCalendarService;
      this.$q = $q;
      this.primeService = primeService;
      this.activitiesService = activitiesService;

      this.initVariables();

      this.$q.all(
          [$scope.promiseUserPopulated, $scope.promiseCommercialCalendar]).
          then(function(data: any) {
            this.init(data[0], data[1]);
          }.bind(this));

    }

    public initVariables() {
      this.turnoverArray = [];
      this.turnoverSum = 0;
      this.referenceArray = [];
      this.referenceSum = 0;
      this.objectifArray = [];
      this.objectifSum = 0;
      this.simulationPrime1 = 0;
      this.simulationPrime2 = 0;
      this.simulationPrime3 = 0;
      this.turnoverProjection = 0;
      this.maxValueGraph = 0;
      this.minValueGraph = 0;
      this.simulationTotal = 0;
      this.sumAmountWinPrimes = 0;
      this.slidesValueLeftStyle = [
        7.14286,
        21.4286,
        35.7143,
        50,
        64.2857,
        78.5714,
        92.8571];
      this.amountStepGraduate = [];
    }

    private init(
        userPopulated: IUserPopulated,
        commercialCalendarList: ICommercialCalendar[]): void {
      this.userPopulated = userPopulated;
      this.commercialCalendarList = commercialCalendarList;
      this.currentCycle = this.commercialCalendarService.getCurrentCycleForced(
          this.commercialCalendarList);
      var nMinusCycle = this.commercialCalendarService.getNMinus1Cycle(
          this.commercialCalendarList, this.currentCycle);
      this.selectLevel = this.usersService.getLevelSelectOptions(
          this.userPopulated);

      if (this.currentCycle) {
        this.currentCycleNumber = this.commercialCalendarService.getCycleNumber(
            this.currentCycle);
        this.currentYearName = this.commercialCalendarService.getCurrentYearName(
            this.currentCycle);

        this.activitiesService.getByMatricule(this.userPopulated.matricule).
            then(function(activityList) {
              //fix
              var currentActivity = this.activitiesService.getCurrentActivity(
                  activityList, this.currentCycle);
              var selectLevel = this.usersService.getLevelSelectOptions(
                  this.userPopulated);
              this.turnoverSum = this.activitiesService.getTurnover(
                  currentActivity, selectLevel, null);
              var nMinus1Activity = this.activitiesService.getNMinus1Activity(
                  activityList, nMinusCycle);
              this.referenceSum = this.activitiesService.getTurnover(
                  nMinus1Activity, selectLevel, null);

              //Keys
              //this.turnoverArray = this.activitiesService.getTurnoverArray(activityList, this.currentYearName, this.selectLevel, null);
              //this.turnoverSum = Math.round(this.activitiesService.getTurnoverSum(this.turnoverArray));
              //this.objectifArray = this.activitiesService.getObjectifArray(activityList, this.currentYearName, this.selectLevel);
              this.objectifSum = Math.round(this.referenceSum * (1 +
                  (this.activitiesService.getObjectifNumber(currentActivity,
                      this.selectLevel)) / 100));
              /*this.referenceArray = this.activitiesService.getTurnoverArray(
                  activityList,
                  this.commercialCalendarService.getLastYearName(this.currentCycle),
                  this.selectLevel
              );*/
              //this.referenceSum = Math.round(this.activitiesService.getTurnoverSum(this.referenceArray));

              //JQUERY SLIDER
              this.turnoverProjection = this.turnoverSum;
              this.maxValueGraph = Math.max(this.turnoverSum, this.referenceSum,
                  this.objectifSum) +
                  (Math.max(this.turnoverSum, this.referenceSum,
                      this.objectifSum) * 0.2);
              this.minValueGraph = Math.min(this.turnoverSum, this.referenceSum,
                  this.objectifSum) -
                  (Math.min(this.turnoverSum, this.referenceSum,
                      this.objectifSum) * 0.2);
              setTimeout(function() {
                var handle = $('#slides #handle');
                $('#slides').slider({
                  animate: 'fast',
                  min: this.minValueGraph,
                  max: this.maxValueGraph,
                  value: this.turnoverProjection,
                  create: function() {
                    if (this.turnoverProjection >= 1000) {
                      handle.text(Math.round(this.turnoverProjection / 1000) +
                          'K€');
                    } else {
                      handle.text(Math.round(this.turnoverProjection) + '€');
                    }
                  }.bind(this),
                  slide: function(event, ui) {
                    if (ui.value >= 1000) {
                      handle.text(Math.round(ui.value / 1000) + 'K€');
                    } else {
                      handle.text(Math.round(ui.value) + '€');
                    }
                  }.bind(this),
                  change: function(event, ui) {
                    if (ui.value >= 1000) {
                      handle.text(Math.round(ui.value / 1000) + 'K€');
                    } else {
                      handle.text(Math.round(ui.value) + '€');
                    }
                    this.turnoverProjection = ui.value;
                    this.simulationPrimes();
                  }.bind(this),
                }).bind(this);
              }.bind(this));
              this.computeToDisplayValueCursor(this.maxValueGraph,
                  this.minValueGraph);
              this.simulationPrimes();
              this.amountStepGraduate = this.setGraduateValueGraph();

              //TAB PRIME 1, 2 and 3
              this.currentPrimeId1 = this.primeService.getCurrentPrimeById(
                  this.userPopulated.primeList, this.currentCycle, 1);
              this.currentPrimeId2 = this.primeService.getCurrentPrimeById(
                  this.userPopulated.primeList, this.currentCycle, 2);
              this.currentPrimeId3 = this.primeService.getCurrentPrimeById(
                  this.userPopulated.primeList, this.currentCycle, 3);
              this.sumAmountWinPrimes = this.primeService.getSumOfPrimes([
                this.currentPrimeId1,
                this.currentPrimeId2,
                this.currentPrimeId3]);
            }.bind(this)),
            function(err) {
              console.error(err);
            };
      }
    }

    public changeInputProjection(value: number) {
      $('#slides').slider('value', value);
    }

    public computeToDisplayValueCursor(
        maxValue: number, minValue: number): any {
      var turnover = document.getElementById('ca-date');
      var reference = document.getElementById('reference');
      var objectif = document.getElementById('objectif');

      turnover.style.width = 'calc(' +
          getValueInPercent(this.turnoverSum, maxValue, minValue) + '%)';
      reference.style.left = getValueInPercent(this.referenceSum, maxValue,
          minValue) + '%';
      objectif.style.left = getValueInPercent(this.objectifSum, maxValue,
          minValue) + '%';

      function getValueInPercent(elem, maxValue, minValue) {
        return ((elem - minValue) / (maxValue - minValue)) * 100;
      }
    }

    public simulationPrimes() {
      // prime 1 and 3
      if (this.turnoverProjection >= this.referenceSum) {
        this.simulationPrime1 = 150;
        this.simulationPrime3 = (this.turnoverProjection - this.objectifSum) *
        0.12 > 0 ? (this.turnoverProjection - this.objectifSum) * 0.12 : 0;
      }
      else {
        this.simulationPrime1 = 0;
        this.simulationPrime3 = 0;
      }
      // prime 2
      if (this.turnoverProjection >= this.referenceSum &&
          this.turnoverProjection < (this.referenceSum * 0.30)) {
        this.simulationPrime2 = 300;
      }
      else if (this.turnoverProjection >= (this.referenceSum * 0.30) &&
          this.turnoverProjection < (this.referenceSum * 0.60)) {
        this.simulationPrime2 = 300;
      }
      else {
        this.simulationPrime2 = 600;
      }
      //sum
      this.simulationTotal = this.simulationPrime1 + this.simulationPrime2 +
          this.simulationPrime3;
    }

    public setGraduateValueGraph(): any[] {
      var objectGraduate = [];
      angular.forEach(this.slidesValueLeftStyle, function(current) {
        var currentGraduate = {
          valueStep: null,
          valueLeft: current,
        };
        var value = parseInt(this.minValueGraph) + ((current / 100) *
            (parseInt(this.maxValueGraph) - parseInt(this.minValueGraph)));
        if (value >= 1000) {
          currentGraduate.valueStep = (value / 1000).toFixed(1) + 'K€';
          objectGraduate.push(currentGraduate);
        }
        else {
          currentGraduate.valueStep = Math.round(value) + '€';
          objectGraduate.push(currentGraduate);
        }
      }.bind(this));
      return objectGraduate;
    }
  }
}

appDashboard.controller('DashboardManagerPrimeTurnoverController',
    controllers.DashboardManagerPrimeTurnoverController);