/// <reference path="../appDashboard.ts" />7
module services {
  'use strict';

  export interface IP2RService {
    getUnderLevel(level: string): string;

    getAfterLevel(level: string): string;

    getP2RLevel(numberActive: number): string

    getMaxP2RLevel(P2RArray: string[]): string

    P2RToNumber(P2R: string): any

    NO_P2R: string
    LEADER_35: string
  }

  export class P2RService implements IP2RService {

    public NO_P2R = 'Aucun palier';
    public LEADER_35 = 'Leader 35';

    constructor() {
    }

    /**
     * Get the last leader level reached as a string
     *
     * @param level
     * @returns {string}
     */
    public getUnderLevel(level: string): string {
      switch (level) {
        case 'Leader 35':
          return 'Leader 28';
        case 'Leader 28':
          return 'Leader 21';
        case 'Leader 21':
          return 'Leader 14';
        case 'Leader 14':
          return 'Leader 7';
        case 'Leader 7':
          return 'Leader 2';
      }
    }

    /**
     * Get the next leader level to achieve as a string
     *
     * @param level
     * @returns {string}
     */
    public getAfterLevel(level: string): string {
      switch (level) {
        case 'Leader 2':
          return 'Leader 7';
        case 'Leader 7':
          return 'Leader 14';
        case 'Leader 14':
          return 'Leader 21';
        case 'Leader 21':
          return 'Leader 28';
        case 'Leader 28':
          return 'Leader 35';
      }
    }

    /**
     * get a string rendering P2R level
     *
     * @param numberActive
     * @returns {string}
     */
    public getP2RLevel(numberActive: number): string {

      if (numberActive < 2) {
        return this.NO_P2R;
      }
      else {
        return 'Leader ' + this.getP2RCeil(numberActive);
      }
    }

    /**
     * get max P2R
     *
     * @param P2RArray
     * @returns {string}
     */
    public getMaxP2RLevel(P2RArray: string[]) {
      let P2RArrayNumber = [];
      for (let i = 0; i < P2RArray.length; i++) {
        P2RArrayNumber.push(this.P2RToNumber(P2RArray[i]));
      }
      let res = Math.max.apply(null, P2RArrayNumber);
      if (res !== 0) {
        return 'Leader ' + res;
      }
      else {
        return this.NO_P2R;
      }

    }

    /**
     * parse P2R to a number
     *
     * @param P2R
     * @returns {number}
     * @constructor
     */
    public P2RToNumber(P2R: string): any {
      if (P2R) {
        return parseInt(P2R.replace(/^\D+/g, ''), 10) || 0;
      } else {
        return 0;
      }
    }

    /**
     * get the P2R to reach to get to another ceiling
     *
     * @param number
     * @returns {number}
     */
    private getP2RCeil(number: number): number {
      if (number === 0 || number === 1) {
        throw 'not possible, number must be upper than 1';
      } else {
        if (number < 7) {
          return 2;
        } else if (number < 14) {
          return 7;
        } else if (number < 21) {
          return 14;
        } else if (number < 28) {
          return 21;
        } else if (number < 35) {
          return 28;
        } else {
          return 35;
        }
      }
    }
  }
}
appDashboard.service('P2RService', services.P2RService);


