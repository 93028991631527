/// <reference path="../appDashboard.ts" />7
module models {
    'use strict';
    export interface ICommission{
        matricule : String,
        child : IUser,
        level : String,
        personalTurnover : number
        commissionRate : number
        commissionAmount : number
        commissionType : String
        regularisation?: number
        parent?: IUser
    }
}


