/// <reference path="../appDashboard.ts" />

let followUpNewBlock = function () {
    return {
        restrict : 'E',
        replace: false,
        scope: {
            promiseUserPopulated: '=',
            promiseCommercialCalendar: '=',
          limitNumber: '=',
        },
        controller : 'DashboardFollowUpNewBlockController as followUpNewBlockController',
        templateUrl : '/templates/follow-up-new-block.html',
    };
};

appDashboard.directive("followUpNewBlock", [followUpNewBlock]);
